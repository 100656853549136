.order-by-sell {
  height: 100%;
}

.date-field {
  position: relative;
  width: 6%;
  top: -10px;
}

.calendar-image {
  min-width: 35px;
  height: 30px;
  margin-bottom: 9px !important;
  position: absolute;
  left: 8px;
}

.last-item-calender {
  display: flex;
  position: relative;
}

#resale .last-item-calender.buy-sell-calendar {
  display: flex;
  justify-content: flex-end;
}

.calender-info {
  width: 100%;
}

.order-by-sell .order-details-transported-by > p {
  margin: 0;
}

.form-wrapper {
  overflow-y: scroll;
  height: 95%;
  overflow: auto;
}

.form-wrapper::-webkit-scrollbar-thumb {
  background: #a7e67694;
  border-radius: 10px;
}

.button-group .buy-sell {
  height: 7%;
}

.order-by-sell .title {
  font-size: 23px !important;
  margin: 0 !important;
}

.order-by-sell .info {
  line-height: 2.5;
}

.dropdown .dropdown-heading {
  border: none !important;
}

.order-group-list-recipient {
  display: flex;
  flex-direction: row;
  background: white;
  width: 100%;
  position: relative;
  left: 1px;
}

.order-group-list-recipient.buy-sell {
  left: 25px !important;
}

.recipientList {
  flex-direction: row;
}

/* sellings card */

.order-by-sell .underline {
  width: 95% !important;
  top: -12px;
}

.order-by-sell .order-details-transported-by {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 3%;
}

.order-by-sell .order-group-list {
  padding-right: 3%;
}

.order-by-sell .order-group-list.new {
  padding-right: 0% !important;
}

.order-by-sell .order-group-list .trd {
  font-size: 20px !important;
  font-weight: 200;
  color: #777272;
}

.button-group.buy-sell {
  margin: 15px 0 0 0;
}

.order-by-sell #delivery-button-group .btn-white {
  width: 44% !important;
  line-height: 0.3 !important;
}

.order-by-sell #delivery-button-group button:nth-child(2) {
  border: none;
  box-shadow: none;
  border-radius: 0 7px 7px 0;
  width: 50% !important;
}

.order-by-sell #delivery-button-group button:nth-child(1) {
  border-right: 2px solid #efefef !important;
  box-shadow: none;
  border: none;
  border-radius: 7px 0px 0px 7px;
  width: 50% !important;
}

.order-by-sell #delivery-button-group .btn-white {
  width: 44% !important;
  line-height: 0.3 !important;
  border: none;
}

.order-by-sell #delivery-button-group button {
  font-size: 17px;
  color: #b7b7b7;
}

.order-by-sell #delivery-button-group button.btn-active {
  color: #7bd812;
  font-weight: 100;
}

.order-by-sell #delivery-button-group {
  width: 38% !important;
  box-shadow: 0px -5px 14px 4px rgba(226, 222, 222, 0.17),
    1px 1px 8px 0px rgba(202, 202, 202, 0.14),
    1px 1px 6px 0px rgba(175, 175, 175, 0.25);
  border-radius: 7px;
  height: 30%;
}

.order-by-sell .order-group-calendar {
  padding-right: 7%;
}

.order-by-sell .order-group-calendar.regular-picker {
  padding: 0% !important;
}

.order-by-sell .card-title {
  color: #77d609;
}

.order-by-sell .transported-by-text {
  font-weight: 100;
  color: #b7b7b7;
  margin-right: 30px;
  font-size: 22px;
}

.order-by-sell .btn-bold {
  opacity: 1 !important;
  font-weight: bold;
}

.order-by-sell .react-datepicker__triangle {
  left: 245px;
}

.order-by-sell .react-datepicker-popper {
  top: 7px !important;
  left: -183 !important;
}

.order-by-sell .react-datepicker__input-container input {
  opacity: 0;
  width: 50px;
}

.react-datepicker__day--keyboard-selected {
  background: #868686;
  border-radius: 50%;
}

.order-by-sell .last-item.quality-program.buyings {
  width: 35%;
  line-height: 2.5;
}

.order-by-sell .multi-select .dropdown-content .option-multi-select {
  background: transparent !important;
  margin-left: 3% !important;
}

.order-by-sell .multi-select .dropdown-content .select-panel > .select-item {
  background: #cdefaf59 !important;
}

.order-by-sell
  .multi-select
  .dropdown-content
  .select-panel
  ul.select-list
  label.select-item:hover {
  background-color: #cdefaf59 !important;
}

.order-by-sell .date-field {
  width: 9%;
  top: 29px !important;
}

.order-by-sell .date-field.picker {
  width: 0%;
  right: 6%;
}

.order-by-sell .date-field.end {
  top: -16px !important;
  width: 17% !important;
}

.order-by-sell .last-item-calender.until .react-datepicker-popper {
  top: 28px !important;
}

*:hover::-webkit-scrollbar-thumb {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
}

.order-by-sell .last-item {
  margin: 0 !important;
}

.order-by-sell .select-language {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  color: #777272 !important;
}

.order-by-sell .option-multi-select {
  color: #777272 !important;
  font-size: 18px !important;
  margin-left: 4px;
}

.order-by-sell .last-item.quality-program.buyings {
  width: 100% !important;
}

.order-by-sell .multi-select .dropdown-heading-value {
  padding: 0 !important;
}

.order-by-sell .multi-select .select-item {
  background: white !important;
}

.confirm-sell-btn {
  display: flex;
  justify-content: center;
}

.order-by-sell .info {
  color: #6b6868 !important;
  font-weight: 200;
  font-size: 19px !important;
  margin-left: 5px;
}

.last-item-calender .order-by-sell .info.calender-info {
  font-size: 19px !important;
}

.dropdown-content .select-panel > div {
  display: none;
}

.dropdown-heading-value .option-multi-select {
  padding: 0;
}

.order-by-sell .info.calender-info {
  cursor: pointer;
  color: transparent;
  text-shadow: 0 0 0 #777272;
}

/* checkbox custom style  */

.order-group-list-recipient input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Styling checkbox */
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 2px solid #808080;
  margin-right: 10px;
}

.order-group-list-recipient input[type='checkbox']:checked {
  background-color: #808080;
  border-color: #808080;
}

.order-by-sell .last-item-calender input[type='text'] {
  font-family: 'Titillium-Regular' !important;
  /* font-size: 19px !important;
  text-indent: 5px; */
}

.order-by-sell.buy-sell-request .last-item-calender input[type='text'] {
  font-family: 'Titillium-Regular' !important;
  /* font-size: 19px !important;
  text-indent: 4px;
  position: absolute;
  top: 5px; */
  text-shadow: 0 0 0 #ffffff !important;
}

.order-by-sell .form-wrapper.sellings select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.button-group.buy-sell.confirm {
  display: flex;
  justify-content: center;
  width: 100% !important;
  align-items: flex-end;
}

.button-group.buy-sell.confirm .btn-submit {
  width: 55% !important;
}

.buy-sell-req.buy-sell.confirm .btn-submit {
  width: 55% !important;
  height: 37px !important;
  background-color: #7bd812 !important;
  border-color: #7bd812;
  border-radius: 5px !important;
  font-size: 18px !important;
  color: #fff !important;
}

.buy-sell-req.buy-sell.confirm .btn-submit.btn[disabled] {
  cursor: not-allowed !important;
  opacity: 0.65 !important;
  box-shadow: none !important;
}

.lastarFav {
  margin-left: 192px;
  margin-top: 14px;
  color: #7bd812;
}

.lastarUnFav {
  margin-left: 192px;
  margin-top: 14px;
}

#breed-and-race-custumised
  .css-140icpd-control
  .css-1hwfws3
  .css-dvua67-singleValue,
#breed-and-race-custumised .css-151xaom-placeholder,
#breed-and-race-custumised .css-20a7fl-indicatorContainer {
  margin-top: -20px !important;
  margin-left: -7px !important;
}

#breed-and-race-custumised .css-140icpd-control {
  margin-top: 10px !important;
}

#breed-and-race-custumised .underline {
  top: -12px !important;
}

#breed-and-race-custumised .css-ue83k8-singleValue {
  font-size: 21px !important;
  margin-left: -5px !important;
  margin-top: -4px !important;
}

#breed-and-race-custumised .css-en01pp-control {
  border-color: transparent transparent #fff;
}

/* .select-container-bsr {
  height: 88px;
} */

.date-picker-feild {
  position: relative;
  width: 6%;
  top: 8px;
}

/* new overided arrow icon of dropdown */

.dropdown .dropdown-heading-dropdown-arrow > span {
  border: solid #999999 !important;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 8px !important;
  height: 8px !important;
}

.dropdown .dropdown-heading-dropdown-arrow {
  padding-right: 2% !important;
}

.order-by-sell .buy-request-top-part {
  height: 92%;
}

.order-by-sell .buy-request-bottom-part {
  height: 8%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#resale .recipient-bottom-container {
  margin-right: 0%;
  margin-top: 8%;
  background: #f3f4f5;
  border-radius: 9px;
  padding: 3%;
}

.recipient-bottom-container .recipient-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5%;
}

.recipient-bottom-container .recipient-list .first-header-part {
  display: flex;
  align-items: center;
  width: 40%;
}

.recipient-bottom-container .recipient-list .recipient-title {
  font-size: 23px;
  font-weight: 600;
  color: #808080;
}

.recipient-bottom-container .recipient-list .recipient-favorite {
  padding: 0 0 4% 4%;
}

.recipient-bottom-container .recipient-list .recipient-favorite svg {
  fill: grey;
}

.recipient-bottom-container .recipient-list .recipient-search input {
  margin: 0 !important;
  height: 32px;
  background: #fff;
  border: 0 !important;
  border-radius: 0 5px 5px 0 !important;
  padding: 0 !important;
}

.recipient-bottom-container
  .recipient-list
  .recipient-search
  .input-group-addon {
  background: #fff;
  border: 0;
  color: #999;
  border-radius: 5px 0 0 5px;
}

.recipient-bottom-container .recipient-list .recipient-search .input-group {
  box-shadow: 0px 0px 9px 1.5px rgba(218, 218, 218, 0.64),
    0 1px 0px 0 rgba(255, 255, 255, 0.85);
  z-index: 0;
}

.recipient-bottom-container
  .recipient-list
  .recipient-search
  input::-webkit-input-placeholder {
  text-align: left;
  font-size: 20px;
  color: #b7b6b6;
}

.recipient-bottom-container
  .recipient-list
  .recipient-search
  input:-moz-placeholder {
  text-align: left;
  font-size: 20px;
  color: #b7b6b6;
}

.recipient-bottom-container .recipient-list .second-header-part {
  width: 40%;
}

.recipient-bottom-container .custom-users-list .recipientList {
  background: #f3f4f5;
}

.recipient-bottom-container .custom-users-list .order-group-list-recipient {
  background: #f3f4f5;
  width: 70%;
}

.recipient-bottom-container .order-group-list-recipient input[type='checkbox'] {
  background: #f3f4f5 !important;
}

.recipient-bottom-container
  .order-group-list-recipient
  input[type='checkbox']:checked {
  background: #808080 !important;
}

/* The container */

#checkbox1 .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

#checkbox1 .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

#checkbox1 .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 28px;
  margin-left: 20px;
  height: 18px;
  width: 18px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */

#checkbox1 .container input ~ .checkmark {
  background-color: transparent;
  border: 2px solid #77d609;
}

/* When the checkbox is checked, add a blue background */

#checkbox1 .container input:checked ~ .checkmark {
  background-color: #77d609;
}

.order-by-sell.resell .last-item {
  margin-left: auto !important;
  margin-right: 0px;
}

#resale .resale-top-part {
  height: 95%;
}

#resale .resale-bottom-part {
  height: 5%;
  display: flex;
  align-content: flex-end;
  justify-content: center;
}

.newscroll {
  overflow-y: scroll;
  height: 160px;
  overflow: auto;
}

.newscroll::-webkit-scrollbar-thumb {
  background: #a7e67694;
  border-radius: 10px;
}
