.tourInfo-title-b20 {
  width: 91%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.status-image-pointT {
  width: 100% !important;
}

/* prevent bar cursor from datepicker  */

.user-input-wrp.date-time .react-datepicker__input-container input {
  color: transparent !important;
  text-shadow: 0 0 0 #727272;
  border: 0;
}

.react-datepicker__day--keyboard-selected {
  background: #868686;
  border-radius: 50%;
}
