#admin .add-stock {
  height: 95%;
}

#admin .add-stock .stock-col {
  max-height: 100%;
}

#admin .add-stock .card-box {
  height: 100%;
  position: relative;
  margin: 0 !important;
}

#admin .add-stock .stock-panel {
  max-height: 100%;
}

#admin .add-stock .image {
  width: 25%;
}

#admin .add-stock .card-title {
  margin-top: 6px;
  height: 6%;
  margin-left: 3%;
}

.add-stock.left-side .card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add-stock .btn-send-stock {
  display: flex;
  justify-content: center;
}

.entire-form {
  height: 69%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#admin .add-stock .add-stock-card-box {
  height: 100%;
}

#admin .add-stock .passpord-photo-disabled::after {
  padding: 5px;
  content: url('../../../static/images/add-passpord-disabled.jpg');
}

#admin .add-stock .passpord-photo-avaible::after {
  padding: 5px;
  content: url('../../../static/images/add-passpord.png');
}

#admin .add-stock .passpord-photo-view::after {
  padding: 5px;
  content: url('../../../static/images/view-password.jpg');
}

#admin .add-stock .upload-file-button {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#admin .add-stock .title-add-stock {
  margin: 10%;
}


#admin .add-stock .text {
  width: 100%;
  height: 26px;
  object-fit: contain;
  font-family: 'Titillium-Regular';
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left !important;
  color: #ffffff;
}

#admin .add-stock .card-title {
  margin-top: 6px;
  height: 6%;
  margin-left: 3%;
}

#admin .add-stock .eartag {
  margin: 3%;
  font-size: 20px;
}

#admin .add-stock .label-type {
  width: 108px;
  height: 27px;
  font-size: 25px;
  margin: 5%;
  padding: 1.5%;
  color: #ffffff;
  opacity: 0.3;
  cursor: pointer;
  height: 50px !important;
}

#admin .add-stock .active {
  color: #ffffff;
  opacity: 1;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
}

#admin .add-stock .add-button {
  padding: 65px;
  cursor: pointer;
}

#admin .add-stock .eartag-input {
  height: 55px;
  width: 100% !important;
  text-align: center;
  color: rgba(119, 119, 119, 0.75) !important;
  font-size: 35.5px;
  background-color: white !important;
}

.add-stock .eartag-input::-webkit-input-placeholder {
  /* WebKit browsers */
  line-height: 1.2em !important;
}

.search-popu .modal-content {
  width: 100%;
}

@media (max-width: 1300px) {
  #admin .add-stock .eartag-input {
    font-size: 26px !important;
  }
}

#admin .add-stock .eartag-input::placeholder {
  color: rgba(119, 119, 119, 0.75);
  opacity: 0.75;
  font-size: 34.5px;
  padding: 2%;
}

#admin .add-stock .mother_ear_tag {
  text-transform: uppercase;
  margin-left: 4px !important;
}

#admin .add-stock .select-animal {
  margin-top: 4%;
  margin-bottom: 7%;
  font-size: 20px;
}

#admin .add-stock .list-group {
  /* margin-top: 2%;
  margin-bottom: 5%; */
  overflow-y: scroll;
  max-height: 100%;
  min-height: 490px;
  overflow-y: scroll;
  scrollbar-color: #7bd812 #ffffff;
}

@media screen and (min-height: 700px) and (max-height: 850px) {
  #admin .add-stock .list-group {
    min-height: 380px;
  }
}

@media screen and (max-height: 700px) {
  #admin .add-stock .list-group {
    min-height: 100px;
  }
}

#admin .add-stock .list-group-item {
  color: #bbbbbb;
  position: relative;
  display: block;
  padding: 10px 15px;
  width: 90%;
  margin-bottom: -1px;
  border: 1px solid #ddd !important;
  border-right: 1px solid white !important;
  border-left: 1px solid white !important;
}

#admin .add-stock .avaible {
  color: #7bd812;
}

#admin .add-stock .not-not-available {
  color: #d6d812;
}

#admin .add-stock .list-bottom {
  position: relative;
  display: block;
}

#admin .select-race {
  color: #7bd812 !important;
  background: #ffff !important;
  color: #666;
}

#admin .select-race:hover {
  /* color: #7bd812 !important; */
  outline: 0 !important;
  /* color: #666; */
}

#admin .add-stock .add-stock-card-box {
  background: linear-gradient(to bottom right, #7bd811, #67b115);
  margin: 0 !important;
  height: 100%;
}

@media screen and (max-width: 768px) {
  #admin .add-stock .list-group {
    height: 40%;
    margin-bottom: 10%;
  }
}

/* @media screen and (max-width: 1400px) and (min-width: 1200px) {
  #admin .add-stock .list-group {
    height: 100% !important;
  }
} */

/** add stock css **/

#body-add-stock .panel-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#admin .add-stock .space-top-bottom {
  margin-top: 0;
  margin-bottom: 15px;
}

.add-stock .add-stock-card-box .space-top-bottom {
  margin-top: 15px !important;
  margin-bottom: 23px !important;
}

.add-stock .mother-eartag .eartag-input {
  border-radius: 8px !important;
  height: 31px !important;
}

.add-stock .eartag-input {
  border-radius: 8px !important;
  height: 54px !important;
}

.add-stock .state-box {
  margin: 8px !important;
}

/** form css **/

#admin .add-stock .form-control {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px;
  width: 100%;
}

.add-stock .add-stock-card-box .form-control {
  margin: 2% auto;
}

#admin .add-stock .form-control[disabled] {
  background-color: white;
  color: rgba(112, 112, 112, 0.4);
  opacity: 1;
}

.icon-list-group svg {
  margin-top: 12% !important;
  font-size: 35px !important;
}

#admin .add-stock .user-input-wrp {
  position: relative;
}

#admin .add-stock .user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #777;
}

#admin .add-stock .user-input-wrp .inputText:invalid {
  box-shadow: none !important;
}

#admin .add-stock .num-passpord,
#admin .add-stock .before {
  color: rgba(119, 119, 119, 0.75) !important;
  font-size: 35.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.add-stock .navigate-before {
  color: #c5c3c3;
  position: absolute;
  top: 10px;
  left: 5px;
}

#admin .add-stock .user-input-wrp .inputText:focus {
  border-width: medium medium 2px;
}

.user-input-wrp .date-time {
  border: 0 !important;
  padding: 4px 0 !important;
  border-bottom: 2px solid #ccc !important;
  background-color: transparent !important;
  font: 20px 'Titillium-Regular', sans-serif !important;
  color: #333 !important;
  width: 100% !important;
  box-sizing: border-box !important;
  letter-spacing: 1px !important;
  text-align: left !important;
  margin-left: 12px;
}

#admin .add-stock .form-control:focus {
  border: 0 0 2px 2px !important;
  box-shadow: 0 2px 2px -2px #7bd812 !important;
}

#admin .add-stock .user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  transition: 0.2s ease all;
  top: 6px;
  left: 10px;
  opacity: 0.6;
  font-size: 24px;
  font-weight: 300;
  color: rgba(112, 112, 112, 0.4);
}

#admin
  .add-stock
  .user-input-wrp
  input:not([name='box']):focus
  ~ .floating-label {
  position: absolute;
  pointer-events: none;
  transition: 0.2s ease all;
  top: -50%;
  left: 10px;
  opacity: 0.6;
  font-size: 24px;
  font-weight: 300;
  color: rgba(112, 112, 112, 0.4);
}

#admin
  .add-stock
  .user-input-wrp
  input:not([value='']):not([name='box'])
  ~ .floating-label {
  position: absolute;
  pointer-events: none;
  transition: 0.2s ease all;
  top: -50%;
  left: 10px;
  opacity: 0.6;
  font-size: 24px;
  font-weight: 300;
  color: rgba(112, 112, 112, 0.4);
}

#admin .add-stock .user-input-wrp .floating-label-top {
  position: absolute;
  pointer-events: none;
  transition: 0.2s ease all;
  top: -30%;
  left: 10px;
  opacity: 0.8;
  font-size: 24px;
  font-weight: 300;
  color: rgba(112, 112, 112, 0.4);
}

#admin .add-stock .register-form-btn {
  width: 80%;
  height: 50px;
  background-color: #7bd812 !important;
  font-size: 18px;
  color: #fff !important;
}

#admin .add-stock .register-form-btn:hover {
  background-color: #b6b6b6 !important;
}

@media (min-width: 1366px) {
  #admin .add-stock .form-control {
    height: 58px;
    text-align: center;
    font-size: 24.5px;
    color: rgba(112, 112, 112, 0.75) !important;
  }
}

@media (min-height: 1366px) {
  #admin .add-stock .user-input-wrp .floating-label {
    font-size: 24px;
    font-weight: 300;
  }
}

/* datepicker for new stock  */

.new-stock-wrapper .react-datepicker-popper {
  top: 12px !important;
  left: -162px !important;
}

.react-datepicker__day--keyboard-selected {
  background: #868686;
  border-radius: 50%;
}

.new-stock-wrapper .user-input-wrp {
  display: flex !important;
  padding-top: 2%;
}

.new-stock-wrapper .react-datepicker__input-container input {
  opacity: 1 !important;
  font-size: 26px !important;
}

.add-stock .form-control.box-input {
  width: 100%;
}

.css-1scyjmp-control .css-1wy0on6 {
  display: block !important;
}

#add-stock-box .css-1scyjmp-control .css-1hwfws3 .css-dvua67-singleValue,
#mother-box .css-1scyjmp-control .css-1hwfws3 .css-dvua67-singleValue {
  font-family: 'Titillium-Regular' !important;
  font-size: 25px;
  font-weight: 300;
  color: rgba(112, 112, 112, 0.75) !important;
}

.css-1g6gooi {
  margin: 9.5px;
  padding-bottom: 1px;
  padding-top: 2px;
  visibility: visible;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

.css-1scyjmp-control {
  min-height: 54px !important;
  border-width: 1px !important;
  border-color: transparent transparent #767676;
  margin-top: -4px;
}

#mother-box .css-1scyjmp-control:hover {
  border-color: transparent transparent #eee !important;
}

.mother-ear-tag-box {
  margin: 13px -57px 0 0;
}

.mother-ear-tag-number {
  margin: 10px 0px 0px 45px !important;
  padding: 0px !important;
  width: 83% !important;
}

.css-ue83k8-singleValue {
  margin-left: 2px !important;
  margin-right: 2px !important;
  max-width: calc(100% - 8px) !important;
  overflow: hidden !important;
  position: absolute !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  top: 42% !important;
  -webkit-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.css-en01pp-control {
  min-height: 50px !important;
  border-radius: 0 !important;
  border-width: 1px !important;
  background-color: #fff !important;
  border-color: transparent transparent #767676;
}

#add-stock-box .css-en01pp-control .css-1hwfws3 .css-ue83k8-singleValue,
#mother-box .css-en01pp-control .css-1hwfws3 .css-ue83k8-singleValue {
  font-family: 'Titillium-Regular' !important;
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  padding-left: 0px !important;
  color: rgba(112, 112, 112, 0.4) !important;
}

#add-stock-box .css-dvua67-singleValue,
#mother-box .css-dvua67-singleValue {
  display: flex;
  justify-content: center;
}

#add-stock-box .css-1wy0on6,
#mother-box .css-1wy0on6 {
  display: flex !important;
  align-items: center;
  padding: 0 5%;
}

#add-stock-box .css-20a7fl-indicatorContainer,
#mother-box .css-20a7fl-indicatorContainer {
  padding: 0;
}

#mother-box .css-1scyjmp-control:hover {
  border-color: transparent transparent #d8dcde;
  box-shadow: 0 2px 2px -2px #7bd812 !important;
}

.css-1scyjmp-control .css-1hwfws3 .css-dvua67-singleValue {
  color: hsl(0, 0%, 60%) !important;
}

#mother-box .css-1scyjmp-control {
  border-radius: 0px !important;
}

.css-20a7fl-indicatorContainer {
  color: hsl(0, 0%, 60%) !important;
}

.css-20a7fl-indicatorContainer:hover {
  color: #7bd812 !important;
}

.new-stock-wrapper .react-datepicker__input-container {
  margin-top: 9%;
  font-family: 'Titillium-Regular';
  font-weight: 300;
  color: hsl(0, 0%, 60%) !important;
}

.border-picker {
  border-bottom: 1px solid rgba(112, 112, 112, 0.12) !important;
}

.send-stock-top-part,
.add-stock-top-part {
  height: 94%;
}

.send-stock-top-part.list-group .list-group-item .not-avaible {
  font-weight: 600;
}

#send-stock.send-stock-top-part.list-group .list-group-item {
  cursor: pointer;
  border: 0 !important;
  background: #f0f0f0;
  color: #909090;
  border-radius: 7px;
  width: 100% !important;
}

.send-stock-bottom-part,
.add-stock-bottom-part {
  width: 100%;
  height: 6%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.panel-default {
  border-color: #ddd;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.card-box.add-stock-left-box .card-body {
  padding: 0;
}

.eartag-selection .bottom-inputs.eartag-input {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.eartag-selection .bottom-inputs.eartag-input .css-1pcexqc-container {
  height: 37px;
}

.eartag-selection .bottom-inputs .left-select {
  width: 30%;
  font-size: 18px;
}

.card-box.add-stock-left-box .panel-body {
  height: 100%;
}

/* .eartag-selection.mother-eartag .bottom-inputs {
  height: 30px !important;
} */

/* .eartag-selection .bottom-inputs .left-select #regular-input .bar:before {
  background: #c6c6c6 !important;
  height: 1px !important;
} */

/* .eartag-selection .bottom-inputs .left-select #regular-input svg path {
  fill: #b7b7b7;
} */

.eartag-selection .bottom-inputs .right-input {
  width: 70%;
}

.eartag-selection .bottom-inputs .agrabiz-input {
  margin: 0 !important;
}

.eartag-selection .top-label {
  display: flex;
  color: #c6c6c6;
  font-size: 14px;
}

/* .eartag-selection .top-label.eartag-mother {
  position: absolute;
} */

@media (max-width: 1400px) {
  #stock-item-form {
    margin: 0 20px !important;
  }
}

#stock-item-form {
  margin: 0 15px;
}

#stock-item-form.stock-item-view #regular-input {
  margin: 11px 0 17px 0 !important;
}

#stock-item-form.stock-item-view .eartag-input {
  height: 50px;
}

.box-number .eartag-selection #regular-input {
  margin: 0 !important;
}

.eartag-selection.mother-eartag
  .bottom-inputs
  .right-input
  #regular-input.agrabiz-input
  input {
  height: 30px;
}

.view-eartag-top-part {
  display: flex;
  width: 100%;
  margin-top: 3%;
}

.view-eartag-top-part .v-eartag-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 25%;
}

.view-eartag-top-part .v-eartag-left img {
  width: 70%;
}

.view-eartag-top-part .v-eartag-right {
  width: 25%;
  display: flex;
  align-items: center;
}

.view-eartag-top-part .v-eartag-left .v-eartag-number {
  font-size: 30px;
  color: #a5a2a2;
}

/* calendar styling  */

.new-stock-wrapper .last-item-calender.buy-sell-calendar {
  display: flex;
  justify-content: flex-end;
  padding-right: 0 !important;
}

.new-stock-wrapper .last-item-calender input[type='text'] {
  font-family: 'Titillium-Regular' !important;
}

.new-stock-wrapper .last-item-calender input[type='text'] {
  font-family: 'Titillium-Regular' !important;
  text-shadow: 0 0 0 #ffffff !important;
}

.new-stock-wrapper
  .last-item-calender
  .react-datepicker__input-container
  input {
  display: none;
}

.new-stock-wrapper .last-item-calender .date-field.picker {
  position: relative;
  width: 0%;
  top: 15px;
  right: 13%;
}

.new-stock-wrapper .last-item-calender.buy-sell-calendar #regular-input {
  margin: 11px 0 17px 0 !important;
}

.new-stock-wrapper .previous-owner #regular-input {
  margin: 7px 0 17px 0 !important;
}

.new-stock-wrapper .under-top-one {
  height: 45%;
}

.new-stock-wrapper .under-top-two {
  height: 66%;
}

/* add stock eartag number  */

.eartag-number-add-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eartag-number-input-select {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.eartag-number-input-select .e-n-select {
  width: 70px;
}

.eartag-number-input-select .css-1scyjmp-control {
  min-height: 0 !important;
  margin: 0;
  height: 50px !important;
}

.eartag-number-input-select .css-1wy0on6 {
  display: flex !important;
}

.eartag-number-input-select .css-20a7fl-indicatorContainer {
  padding: 8px 4px 10px;
}

.eartag-number-input-select .e-n-input {
  width: 60%;
}

.eartag-number-input-select .e-n-input input {
  margin: 0 0 0 5% !important;
  height: 50px !important;
  border-radius: 7px !important;
}
