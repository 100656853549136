.blocEartag {
  margin-top: 20px;
  /* height: 50px !important; */
}

.eartagS {
  margin-left: 20px;
  font-family: 'Titillium-Regular';
  font-size: 28px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7bd812;
  display: flex;
  flex-wrap: wrap;
}

.birth {
  margin-top: 4px;
  margin-left: 100px;
  font-family: 'Titillium-Regular';
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7em;
}

.raceS {
  margin-left: 20px;
  font-family: 'Titillium-Regular';
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  font-style: italic;
}

.deliveryNoteS {
  margin-left: 20px;
  font-family: 'Titillium-Regular';
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7em;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.mother {
  margin-top: 4px;
  margin-left: 90px;
  width: 31.333333% !important;
  font-family: 'Titillium-Regular';
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

.lineS {
  width: 80%;
}

.Flex-Report {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.Flex-Report-search {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.Flex-Report-search-res {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.Flex-Report-search-res-sou {
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.Flex-Report-search-res-sou-b1 {
  width: 50%;
}

.Flex-Report-search-res-sou-b2 {
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}

.Flex-Report-search-b1 {
  border-radius: 7px;
  width: 75px;
  height: 40px !important;
  margin-right: 10px;
}

.Flex-Report-search-b2 {
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 40px !important;
}

#admin .Flex-Report .input-group-addon svg {
  font-size: 180%;
  color: rgba(183, 183, 183, 0.7);
  margin: 0%;
}

#admin .Flex-Report .form-control {
  margin-top: 0px;
}

#admin .Flex-Report .input-group-addon,
#admin .Flex-Report .search {
  background-color: #f6f6f6 !important;
  border: none !important;
  height: 42px !important;
  width: 160px;
  margin-bottom: 0px !important;
  text-align: left !important;
  font-size: 17px;
}

#admin .Flex-Report .search {
  font-family: 'Titillium-Regular' !important;
  border-radius: 10px !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#admin .Flex-Report .input-group-addon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 10px;
  border-radius: 10px !important;
}

#admin .Flex-Report .input-group {
  width: 100%;
}

.Flex-Report-search-deli {
  width: 89%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
}

.Flex-Report-search-deli-text {
  width: 90%;
}

.Flex-Report-search-deli-doc {
  width: 90%;
  height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.Flex-Report-search-stan {
  width: 80%;
  height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.deliveryNoteSTxt {
  font-family: 'Titillium-Regular';
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  color: black !important;
}

.deliveryNoteSTxt-normal {
  font-family: 'Titillium-Regular';
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  color: #999 !important;
}

.style-border-di {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.style-border-div {
  width: 80%;
  margin: 0 !important;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.22),
    rgba(0, 0, 0, 0)
  );
}
.style-border-div2 {
  width: 100%;
  margin: 0 !important;
  border: 0;
  height: 5px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.22),
    rgba(0, 0, 0, 0)
  );
}

.text-noir-report {
  font-size: 16px !important;
  font-weight: 300 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 2 !important;
  letter-spacing: normal !important;
  font-family: 'Titillium-Regular' !important;
}

.text-noir-report-b1 {
  font-size: 18px !important;
  font-weight: 300 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 2 !important;
  letter-spacing: normal !important;
  margin-left: 20px;
}

.img-loopview {
  width: 22px;
  height: 22px;
}

.search::-webkit-input-placeholder {
  text-align: left;
  font-size: 17px;
  color: #b7b6b6;
}

.react-datepicker .react-datepicker__day--disabled {
  opacity: 0.4 !important;
}

.react-datepicker__day--keyboard-selected {
  background: #868686;
  border-radius: 50%;
}
