$breakpoint-ms-surface: 1500px;

#membership {
  padding-top: 2%;
  &.m-container {
    & .text-separation {
      padding-bottom: 1%;
      & .top-text {
        font-size: 27px;
      }
      & .bottom-text {
        font-size: 23px;
        color: #7d7b79;
      }
    }
  }
  & .membership-types {
    padding: 3% 1%;
  }
  & .membership-types-specifications {
    box-shadow: 0px 0px 9px 1.5px rgba(0, 0, 0, 0.06),
      0 1px 0px 0 rgba(0, 0, 0, 0.02);
    margin-bottom: 10%;
    border-radius: 7px;
    padding: 0 3%;
    & .tax {
      color: #b1b7b7;
      text-align: right;
    }
  }
  & .svg-icon-label {
    top: 100px;
    left: 110px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 0px 9px 1.5px rgba(0, 0, 0, 0.06),
      0 1px 0px 0 rgba(0, 0, 0, 0.02);
    padding: 11px 14px 8px 15px;
    border-radius: 50%;
    color: #c7c3c3;
  }
}

@media (max-width: $breakpoint-ms-surface) {
  #membership {
    & .top-text {
      font-size: 21px !important;
    }
    & .bottom-text {
      font-size: 17px !important;
    }
    & .svg-icon-label {
      left: 66px;
    }
  }
}
