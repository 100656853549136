#admin .view-stock .stocks-details {
  height: 100%;
}

#admin .view-stock .card-box {
  box-shadow: 0px 0px 9px 1.5px rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  padding : 20px 10px;
}

#admin .pdfStock {
  position: relative;
  min-height: 1px;
  padding-right: 12px;
}

#admin .view-stock .stock-panel {
  height: 100%;
  padding-top: 0;
  overflow-y: auto !important;
}

.stockM {
  margin-top: 9px;
}

#scrollarea-content {
  min-height: 101% !important;
}

.viewStock {
  margin-bottom: 15px !important;
  height: 98% !important;
  width: 100% !important;
  padding: 20px !important;
  box-shadow: 0px 0px 9px 1.5px rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 12px;
}

.viewStocktwo {
  height: 100%;
  margin-top: -8px;
  width: 48% !important;
  overflow-y: scroll;
}

#admin .view-stock {
  height: 100% !important;
}

hr.style-border {
  margin: 0 !important;
  border: 0 !important;
  height: 1px !important;
  background-image: linear-gradient( to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0));
}

#admin .view-stock .title-view-stock {
  margin-bottom: 5%;
}

#admin .view-stock .eartag {
  margin: 3%;
  font-size: 20px;
}

#admin .view-stock .form-btn {
  background-color: white !important;
  font-size: 17px;
  color: #7bd812 !important;
  border: #ddd;
  font-family: 'Titillium-Regular';
  text-align: left;
}

#admin #contentStock {
  overflow-y: scroll;
  scrollbar-color: #7bd812 #ffffff;
  overflow: hidden !important;

}

#admin .viewStock-menu .list-group {
  overflow-y: scroll;
}

#admin .view-stock .title {
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  color: #70d236;
  opacity: 1;
}

#admin .view-stock .titlee {
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  opacity: 1;
}

#admin .view-stock .borderBo {
  border-bottom: 2px solid rgba(123, 216, 18, 0.75) !important;
}

#admin .view-stock .title-grey {
  color: #ddd;
  font-size: 17px;
  font-weight: 600;
  text-align: left;
}

#admin .view-stock .title-greyTwo {
  color: #7bd812;
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  margin-top: 12px !important;
  width: 300px !important;
}

#admin .viewStock .title-greyTwo {
  color: #7bd812;
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  margin-top: 12px !important;
  width: 300px !important;
}

#admin .view-stock .TextPdf {
  width: 115px;
}

#admin .view-stock .TextNextBox {
  width: 190px;
}

#admin .view-stock .selected-stock-item {
  bottom: -33px;
  z-index: 10;
  width: 100%;
  border: none;
  background: white;
  min-height: 10%;
  max-height: 20%;
  margin-bottom: 20px;
  height: 34%;
}

#admin .view-stock .selected-stock-item .list-group-item {
  height: 30%;
}

#admin .view-stock .list-group-item {
  color: #6b6b6b;
  position: relative;
  display: block;
  padding: 1px 15px;
  margin-bottom: -1px;
  border: 1px solid white !important;
  cursor: pointer;
}

#admin .viewStock .list-group-item {
  color: #6b6b6b;
  position: relative;
  display: block;
  padding: 1px 15px;
  margin-bottom: -1px;
  border: 1px solid white !important;
  cursor: pointer;
}

#admin .view-stock .avaible {
  color: #7bd812;
}

#admin .view-stock .not-available {
  color: #ea8502;
}

.width-size {
  margin-left: -29px;
  width: 31% !important;
}

.width-sizeBlockOne {
  width: 10% !important;
}

.width-sizeBlockTwo {
  width: 14% !important;
}

.width-sizeBlockTwoRace {
  width: 14% !important;
}

.width-sizeBlockThree {
  width: 21% !important;
}

.width-sizebox {
  width: 12% !important;
}

.content-center {
  width: 100%;
  text-align: center !important;
  box-sizing: border-box;
}

@media screen and (min-width: 1300px) and (max-width: 1471px) {
  .content-center {
    font-size: 12px !important;
  }
}

#admin .view-stock .col-md-3 {
  width: 32% !important;
  margin-left: -28px;
}

#admin .view-stock .title span img {
  margin-left: 8px !important;
}

#admin .view-stock .col-md-1 {
  width: 14.333333% !important;
}

@media screen and (min-width: 1400px) {
  #admin .view-stock .col-md-1 {
    width: 13.333333% !important;
  }
}

#admin .view-stock .col-md-2 {
  width: 18.666667% !important;
  margin-left: -28px;
}

#admin .view-stock .list-bottom {
  position: relative;
  display: block;
}

@media screen and (max-width: 768px) {
  #admin .view-stock .list-group {
    height: 40%;
    margin-bottom: 10%;
    scrollbar-color: #7bd812 #ffffff;
  }
}

.icon-list-group svg {
  margin-top: 12% !important;
  font-size: 35px !important;
}

#admin .view-stock .input-group-addon svg {
  font-size: 180%;
  color: rgba(183, 183, 183, 0.7);
  opacity: 0.5;
}

#admin .view-stock .input-group-addon {
  position: absolute;
  top: 2px;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #f6f6f6 !important;
  border: none !important;
}

/** Well Css **/

#admin .view-stock .well-content {
  height: 30px;
}

#admin .view-stock .well {
  width: 80px;
  height: 30px;
  padding: 0%;
  margin-bottom: 0px !important;
  border-radius: 5px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
}

#admin .view-stock .well svg {
  font-size: 13px;
  margin-left: 7px;
}

/** CSS Checkbox **/

#admin .view-stock .round {
  position: relative;
  border-radius: 10%;
}

#admin .view-stock .round label {
  background-color: #fff;
  border: 2px solid #7bd812;
  text-align: center;
  cursor: pointer;
  height: 13px;
  left: 0;
  position: absolute;
  top: 25%;
  width: 13px;
}

#admin .view-stock .round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

#admin .view-stock .round input[type='checkbox'] {
  visibility: hidden;
}

#admin .view-stock .round input[type='checkbox']:checked+label {
  background-color: #7bd812;
  border-color: #7bd812;
}

#admin .view-stock .round input[type='checkbox']:checked+label:after {
  opacity: 1;
}

/** CSS Checkbox **/

.main-check input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Styling checkbox */
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 2px solid #7bd812;
  margin-right: 10px;
}

.main-check input[type='checkbox']:checked {
  background-color: #7bd812;
  border-color: #7bd812;
}

.main-checkAll input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Styling checkbox */
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: 2px solid #7bd812;
}

.main-checkAll input[type='checkbox']:checked {
  background-color: #7bd812;
  border-color: #7bd812;
}

/** end block checkbox**/

/** Form Bloc**/

/** form css **/

#admin .view-stock .space-top-bottom {
  margin-top: 10px;
  margin-bottom: 12px;
}

#admin .view-stock .form-control[disabled] {
  background-color: white;
  color: rgba(119, 119, 119, 0.5) !important;
}

#admin .view-stock .form-controlTwo[disabled] {
  background-color: white !important;
  font-size: 26px !important;
  font-weight: 280 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: rgba(112, 112, 112, 0.75) !important;
}

.icon-list-group svg {
  margin-top: 12% !important;
  font-size: 35px !important;
}

#admin .view-stock .user-input-wrp {
  position: relative;
}

#admin .view-stock .user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #777;
}

#admin .view-stock .user-input-wrp .inputText:invalid {
  box-shadow: none !important;
}

#admin .view-stock .num-passpord, #admin .view-stock .before {
  color: rgba(119, 119, 119, 0.75) !important;
  font-size: 35.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

#admin .view-stock .user-input-wrp .inputText:focus {
  border-width: medium medium 2px;
}

#admin .view-stock .user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 12px;
  left: 10px;
  font-size: 27px;
  font-weight: 600;
  transition: 0.2s ease all;
  opacity: 0.8;
  color: rgba(123, 216, 18, 0.75);
}

#admin .view-stock .user-input-wrp .floating-label-topp {
  position: absolute !important;
  pointer-events: none !important;
  transition: 0.2s ease all !important;
  top: -50% !important;
  left: 10px !important;
  font-family: 'Titillium-Regular';
  font-size: 20px !important;
  font-weight: 300 !important;
  color: rgba(112, 112, 112, 0.4) !important;
  border-radius: 0px;
  padding-left: 0px;
  font-size: 20px !important;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.7;
  letter-spacing: normal;
  text-align: left;
  color: var(--brownish-grey-50);
  margin: 0 auto;
  text-align: left;
}

.react-datetime-picker__inputGroup {
  font-family: 'Titillium-Regular';
  font-size: 26px;
  font-weight: 300;
  padding: 0 55px;
  color: hsl(0, 0%, 60%) !important;
}

.date-Time-Pick {
  width: 100%;
  margin-left: -3px;
  font-size: 27px;
}

.floating-label-topTwo {
  text-align: center;
  object-fit: contain;
  opacity: 0.75;
  font-family: 'Titillium-Regular';
  font-size: 27px !important;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(112, 112, 112, 0.75);
}

#admin .view-stock .register-form-btn {
  width: 80% !important;
  height: 50px;
  background-color: #7bd812 !important;
  font-size: 18px;
  color: #fff !important;
  margin-top: 20%;
  text-align: center;
}

.btn-save {
  width: 100%;
  height: 100%;
  text-align: center;
}

#admin .view-stock .register-form-btn:hover {
  background-color: #b6b6b6 !important;
}

#admin .input-group {
  display: flex !important;
  align-items: center;
  background: #f6f6f6;
  border-radius: 7px;
}

#admin .input-group-prepend {
  /* padding: 0 0 0 20px; */
}

#admin .input-group-prepend svg {
  fill: #b7b7b7b3;
}

#admin .input-group .search-txt {
  padding: 5px 20px !important;
}

#admin .view-stock .search-content {
  margin-top: 0px !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  width: 100% !important;
}

#admin .view-stock .search-content-text {
  width: 70% !important;
}

#admin .view-stock .input-group {
  width: 100%;
}

#admin .view-stock .form-control {
  height: 40px;
  text-align: center;
  font-size: 17px;
  color: rgba(151, 248, 41, 0.75);
  font-weight: 600;
  background-color: #f6f6f6;
  border: none;
  border-radius: 10px;
  margin: 0;
}

#admin .view-stock .form-controlTwo {
  height: 40px !important;
  width: 90% !important;
  text-align: center !important;
  background-color: #ffffff !important;
  border: none !important;
  object-fit: contain !important;
  font-family: 'Titillium-Regular' !important;
  font-size: 23px !important;
  font-weight: 300 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: rgba(0, 0, 0) !important;
  border-radius: 0px !important;
  margin: 0 !important;
  margin-top: 10px !important;
}

.border-Bo {
  border-bottom: 1px solid #bdbdbd !important;
}

.border-marg {
  padding-top: 15px !important;
  margin-bottom: 2px !important;
}

.react-datetime-picker__wrapper {
  background-color: #ffffff !important;
  border: 0px solid;
  font-family: 'Titillium-Regular';
  font-size: 27px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(112, 112, 112, 0.75);
}

.box-Number {
  margin-left: 220px !important;
  width: 45% !important;
  font-family: 'Titillium-Regular';
  font-size: 27px !important;
  font-weight: 300 !important;
  border-radius: 0px !important;
  background-color: #ffffff !important;
  color: rgba(123, 216, 18, 0.75) !important;
}

#admin .view-stock .form-control.box-nbr {
  background: none;
}

#admin .view-stock .form-controlTwo.box-nbr {
  background: none;
}

#admin .view-stock .form-control.box-nbr:focus {
  border-bottom: 1px solid rgba(123, 216, 18, 0.75);
}

#admin .view-stock .form-controlTwo.box-nbr:focus {
  border-bottom: 1px solid rgba(123, 216, 18, 0.75);
}

@media screen and (min-width: 1401px) and (max-width: 1471px) {
  .width-size {
    margin-left: -10px !important;
    width: 34% !important;
  }
  .width-sizeBlockTwo {
    width: 18% !important;
  }
  .width-sizeBlockThree {
    margin-left: -30px;
  }
}

@media screen and (min-width: 1480px) {
  .width-size {
    margin-left: -22px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1260px) {
  .width-size {
    margin-left: 2px !important;
    width: 36% !important;
  }
  .width-sizeBlockTwo {
    width: 19% !important;
    margin-left: -12px;
  }
  .width-sizeBlockThree {
    margin-left: -12px;
  }
  .width-sizeBlockOne {
    margin-left: -12px;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1400px) {
  .width-size {
    margin-left: 2px !important;
    width: 36% !important;
  }
  .width-sizeBlockTwo {
    width: 17% !important;
  }
  .width-sizeBlockThree {
    margin-left: -40px;
  }
}

@media screen and (min-width: 1261px) and (max-width: 1300px) {
  .width-size {
    margin-left: -10px !important;
    width: 36% !important;
  }
  .width-sizeBlockTwo {
    width: 19% !important;
  }
  .width-sizeBlockThree {
    margin-left: -36px;
  }
}

.imageSort {
  margin-left: 6px;
  width: 8px;
  height: 6px;
}

.imageSorttwo {
  margin-left: 6px;
  width: 8px;
  height: 6px;
}

/** end form block**/

/** bloc upload **/

#admin .view-stock .passpord-photo-disabled::after {
  padding: 5px;
  content: url('../../../static/images/add-passpord-disabled.jpg');
}

#admin .view-stock .passpord-photo-avaible::after {
  padding: 5px;
  content: url('../../../static/images/add-passpord.png');
}

#admin .view-stock .passpord-photo-view::after {
  padding: 5px;
  content: url('../../../static/images/view-password.jpg');
}

#admin .view-stock .upload-file-button {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#admin .view-stock .passpord-photo {
  position: absolute;
  width: 112px;
  right: 11%;
}

/*end upload*/

/*Search*/

.search-popup {
  right: 24% !important;
  top: 6% !important;
  width: 100% !important;
}

.unset-overlay {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.search-popup .search {
  background-color: #f6f6f6 !important;
  font-size: 30px !important;
}

.search-txt {
  background-color: #f6f6f6 !important;
  font-size: 16px !important;
  color: #666 !important;
  font-weight: 300 !important;
  text-align: left !important;
  padding-left: 50px !important;
}

.search-popup .input-group-addon {
  background-color: #f6f6f6 !important;
  border: none !important;
}

.search-popup .input-group-addon {
  background-color: #f6f6f6 !important;
  border: none !important;
}

.search-popup .form-group {
  width: 350px;
}

.search-popup .modal-content {
  width: 380px;
}

.search-popup .form-controlTwo {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px;
}

.search-popup .modal-header {
  border: 0px;
  height: 0px;
}

.search-popup .modal-footer {
  border: 0px;
  height: 100%;
}

.numbe input[type='number'] {
  /*for absolutely positioning spinners*/
  text-align: center;
  font-family: 'Titillium-Regular';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(123, 216, 18, 0.75);
}

.borderless {
  border: none !important;
}

.numbe input[type='number']::-webkit-inner-spin-button, .numbe input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

.numbe input[type='number']::-webkit-outer-spin-button, .numbe input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  width: 25px;
  height: 100%;
}

.search-popup .form-Flexto {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
	justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 35px;
}

.search-popup .form-Flext {
  width: 90%;
  display: flex;
  flex-direction: row;
  margin-bottom: 33px;
}

.search-popup .form-Flexone {
  border-bottom: 2px solid rgba(123, 216, 18, 0.75) !important;
  align-items: center;
  align-content: center;
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 50px;
}

#generic-btn-modal .btn-filter {
  width: 100% !important;
  font-size: 17px !important;
  color: white !important;
  background-color: #7bd812 !important;
  border: #ddd;
  font-family: 'Titillium-Regular';
}

.add-Number {
  width: 50% !important;
  height: 90%;
  font-size: 23px !important;
  padding: 1px !important;
  border-radius: 10px !important;
  box-shadow: 0px 21px 24px 0 rgba(0, 0, 0, 0.08) !important;
  opacity: 0.8 !important;
  background-color: #7bd812;
  line-height: 2.15 !important;
  border: #ddd !important;
  color: #ffffff !important;
  font-family: 'Titillium-Regular' !important;
}

.marginTwo {
  margin-left: 50px;
}

.Filter-Search-Resultsto {
  width: 50% !important;
  height: 100% !important;
  font-family: 'Titillium-Regular' !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #7bd812 !important;
  margin-right:15px;
  align-content: center;

}

.Clear-Alll {
  width: 50% !important;
  height: 100% !important;
  opacity: 0.61 !important;
  font-family: 'Titillium-Regular' !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: rgba(118, 118, 118, 0.61) !important;
}

.stockSell {
  height: 100%;
  position: relative;
}

.form-FlexTaille {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.form-FlexTail {
  width: 100%;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.new-margin {
  margin-left: 66px;
}

#tab-Event #tab-Event-pane-1 .stockSell {
  height: 90%;
  position: relative;
}

#admin .view-stock .list-group {
  height: 40% !important;
  scrollbar-color: #7bd812 #ffffff !important;
  margin-bottom: auto;
}

#admin .viewStock .list-group {
  height: 80% !important;
  scrollbar-color: #7bd812 #ffffff !important;
}

#admin .viewStock .list-groupp {
  height: 60% !important;
  scrollbar-color: #7bd812 #ffffff !important;
}

@media screen and (min-height: 200px) and (max-height: 850px) {
  #admin .viewStock .list-group {
    height: 40% !important;
    scrollbar-color: #7bd812 #ffffff !important;
  }
  #admin .viewStock .list-groupp {
    height: 15% !important;
    scrollbar-color: #7bd812 #ffffff !important;
  }
}

#admin .stockSell .list-group {
  height: 67vh !important;
  scrollbar-color: #7bd812 #ffffff !important;
}

#admin .stockSellB .list-group {
  height: 63vh !important;
  scrollbar-color: #7bd812 #ffffff !important;
}

.list-taggel-margin {
  margin-top: 100px !important;
}

#admin .stockSell .list-groupp {
  height: 40% !important;
  scrollbar-color: #7bd812 #ffffff !important;
  overflow: hidden !important;

}

#admin .stockSellB .list-groupp {
  height: 40% !important;
  scrollbar-color: #7bd812 #ffffff !important;
}

#admin .viewStock .list-group::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
  background: #7bd812 !important;

}

#admin .view-stock .list-group::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
  background: #7bd812 !important;

}

#admin .view-stock .with-passport {
  color: #000000;
}

#admin .view-stock .btn-defaul {
  position: absolute;
  bottom: 0;
}

#admin .view-stock .viewStock .selected-stock-item {
  height: 15%;
}

.selected-stock-item-b1 {
  height: 45%;
}

.selected-stock-item-v {
  overflow-y: scroll;
  overflow: auto;
  height: 100%;
  scrollbar-color: #7bd812 #ffffff !important;
}

#admin .selected-stock-item-v::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
  background: #7bd812 !important;

}

.Eartag-selected {
  cursor: pointer;
  height: 17px !important;
  object-fit: contain !important;
  font-family: 'Titillium-Regular' !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #70d236 !important;
}

.selected-flex {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

.selected-flexOne {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.selected-flexOne-slider {
  width: 60%;
  border-top: 1px solid #6b6b6b;
}

#admin .form-flex-stock {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
}

#admin .form-flex-stock-h1 {
  width: 100%;
}

#admin .form-flex-stock-h2 {
  margin-top: 30px;
}

#admin .form-flex-stock-h2-block {
  padding-left: 20px;
}

#admin .form-flex-stock-h2-texte {
  padding-left: 25px;
}

@media (max-width: 1359px) {
  .width-size {
    margin-left: -32px;
  }
  #admin .view-stock .title-greyTwo {
    margin-top: 10px !important;
  }
  #admin .view-stock .TextNextBox {
    width: 100%;
  }
  .num-passpord {
    padding-top: 30px;
    font-size: 30px !important;
  }
  #admin .view-stock .form-controlTwo[disabled] {
    font-size: 21px !important;
  }
  .react-datetime-picker__inputGroup {
    font-family: 'Titillium-Regular';
    font-size: 21px;
    padding: 0 55px;
    color: hsl(0, 0%, 60%) !important;
  }
}

#admin .stock-show-image {
  width: 220px;
}

@media screen and (max-width: 1300px) {
  #admin .stock-show-image {
    width: 220px;
  }
}

#admin .margin-text-stock {
  width: 100%;
}

#admin .greenStock {
  color: #7bd812;
  font-size: 23px !important;
}

#admin .view-stock .navigate-before-stock {
  color: #c5c3c3 !important;
  top: 13px;
  left: 33px;
}

#admin .view-stock .navigate-before-stock>div svg {
  font-size: 33px;
  opacity: 0.6;
  color: #c5c3c3 !important;
}

.sliderArea {
  margin-top: 20px !important;
  width: 273px;
  height: 40px;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-left: 25px;
}

.Race-Class {
  margin-left: 20px;
  font-family: 'Titillium-Regular';
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7bd812;
  display: flex;
  flex-wrap: wrap;
}

.checkboxFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.checkboxFlex-Block {
  margin-top: 10px;
  margin-left: 49px;
  text-align: left;
  height: 100%;
  width: 100%;
}

.labelFilter {
  margin-left: 10px;
  margin-top: 3px !important;
  width: 40px;
  height: 100%;
  font-family: 'Titillium-Regular';
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #767676;
}

.block-flex-race {
  width: 100% !important;
  height: 100% !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
}

.bloc-ch-div {
  margin-left: 0px !important;
}

.bloc-ch {
  width: 40px;
  margin-left: -30px !important;
}

.bloc-chLabel {
  margin-left: -0px !important;
  align-content: flex-start !important;
}

.Angle {
  font-family: 'Titillium-Regular' !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #767676 !important;
}

/* rc-slider css */

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #7bd812;
}

.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #7bd812;
  background-color: #7bd812;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.rc-slider-handle:focus {
  border-color: #7bd812;
  box-shadow: 0 0 0 5px #7bd812;
  outline: none;
}

.rc-slider-handle-click-focused:focus {
  border-color: #7bd812;
  box-shadow: unset;
}

.rc-slider-handle:hover {
  border-color: #7bd812;
}

.rc-slider-handle:active {
  border-color: #7bd812;
  box-shadow: 0 0 5px #7bd812;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rc-slider-dot-active {
  border-color: #7bd812;
}

.rc-slider-disabled {
  background-color: #e9e9e9;
}

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}

.rc-slider-disabled .rc-slider-handle, .rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}

.rc-slider-disabled .rc-slider-mark-text, .rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}

.rc-slider-tooltip-zoom-down-enter, .rc-slider-tooltip-zoom-down-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active, .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter, .rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

/* view and edit eartag informations in sellings */

@media (min-width: 768px) {
  .view-edit-eartag-informations .modal-dialog modal-content {
    width: 800px !important;
  }
  .view-edit-eartag-informations .modal-content {
    width: 800px !important;
  }
}

#admin .view-stock .loader-stocks {
  display: flex;
  justify-content: center;
}

#admin .view-stock .loader-st {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #7bd811;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#contentStock .list-group .list-group-item .row {
  display: flex;
  align-items: center;
}

#admin .orders .view-stock .confirm-sell-btn .btn-default {
  width: 55% !important;
  height: 37px;
  background-color: #7bd812 !important;
  border-color: #7bd812;
  border-radius: 5px;
  font-size: 18px;
  color: #fff !important;
}

#admin .orders .view-stock .confirm-sell-btn .btn-default.btn[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
  box-shadow: none;
}

/* .card-list-stock-custom {
  padding: 20px 65px !important;
} */

#view-race-breed .css-14ojxsb-control {
  background-color: white !important;
  border-color: transparent !important;
  margin-left: 25% !important;
}

#view-race-breed .css-1hwfws3 {
  padding: 1px 8px !important;
}

#view-race-breed .css-ue83k8-singleValue {
  font-size: 25px !important;
  color: hsl(0, 0%, 60%);
}

#date_picker_birth_incomming .react-datepicker__input-container input {
  width: 140px !important;
  cursor: pointer !important;
  margin-left: 33px !important;
  padding-bottom: 9px;
  height: 47px;
}

.view-date {
  margin-top: 30px;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.view-date-one {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.view-date .calendar-imagee {
  min-width: 35px;
  height: 30px;
}

.button-txt {
  min-width: 80% !important;
  position: absolute;
  bottom: -70%;
}

.view-Stock-line {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  width: 100% !important;
}

.view-Stock-line-pri {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  width: 98% !important;
}

.view-Stock-line-b1 {
  width: 30px !important;
}

.view-Stock-line-b2 {
  width: 35% !important;
}

.view-Stock-line-b3 {
  width: 20% !important;
}

.view-Stock-line-b4 {
  width: 15% !important;
}

.view-Stock-line-b5 {
  width: 20% !important;
}

.view-Stock-line-b6 {
  width: 15% !important;
}

.view-stock .top-part-view-stock {
  height: 100% !important;
}

.view-stock .bottom-part-view-stock {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.custom-btn {
  position: absolute;
  width: 100%;
  top: 93.6%;
}

#view-stock-dropdown.bottom-part-view-stock #toggle-dropup.toggle-menu-btn {
  width: 45%;
}

@media (max-width: 1300px) {
  .view-stock .not-sell-offer .top-part-view-stock {
    font-size: 13px;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  #view-stock-dropdown.bottom-part-view-stock #toggle-dropup.toggle-menu-btn {
    width: 85%;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  #view-stock-dropdown.bottom-part-view-stock #toggle-dropup.toggle-menu-btn {
    width: 70%;
  }
}

.dropdown-item-svg-icon {
  width: 30px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.view-stock .not-sell-offer .top-part-view-stock {
  height: 94% !important;
}

.view-stock .not-sell-offer .bottom-part-view-stock {
  height: 4%;
  align-items: center;
  margin-top: 20px;
}

.view-stock .sell-offer-box {
  height: 100%;
}

.view-stock .not-sell-offer.view-stock-in-sell-offer {
  height: 98% !important;
}

.view-stock .view-stock-in-sell-offer {
  height: 90%;
}

.view-stock .top-view-stock-detail {
  height: 92%;
}

.view-stock .view-stock-panel-body {
  height: 100%;
}

.view-stock.view-stock-to-be-loaded .view-stock-panel-body {
  height: 98%;
}

.view-stock .bottom-view-stock-detail {
  height: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* eartag-select-container */

.add-stock-card-box .eartag-select-container {
  display: flex;
  align-items: baseline;
  width: 63%;
}

.add-stock-card-box .eartag-select-container .eartag-select-left {
  width: 20%;
  margin-right: 3%;
}

@media (max-width: 991px) {
  .add-stock-card-box .eartag-select-container .eartag-select-left {
    width: 35% !important;
  }
}

.view-stock-wrapper {
  height: 100%;
}

.add-stock-card-box .eartag-select-container .eartag-select-right {
  width: 80%;
}

.view-stock-wrapper .last-item-calender .date-field.picker {
  position: relative;
  width: 0%;
  top: 15px;
  right: 13%;
}

.view-stock-wrapper .last-item-calender .date-field.picker input:disabled {
  background: transparent;
}

.view-stock-wrapper .last-item-calender.buy-sell-calendar {
  display: flex;
  justify-content: flex-end;
  padding-right: 0 !important;
}

.view-stock-wrapper .last-item-calender.buy-sell-calendar #regular-input.agrabiz-picker input {
  padding: 10px 10px 10px 5px !important;
}

.react-datepicker__input-container input {
  border: transparent;
}

.react-datepicker__day--keyboard-selected {
  background: #868686;
  border-radius: 50%;
}

.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.fade.in {
  opacity: 1;
}

.fade {
  transition: all 0.5s ease;
}

.Number-Popup-modal .modal-dialog {
  min-width: unset !important;
}

.search-popup .modal-dialog {
  overflow-y: unset !important;
  overflow-x: unset !important;
}



.view-stock ::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
  background: #7bd812 !important;

}


.modal-pdfstock {
  height: 100%;
  height: 700px;
  min-width: 800px;
}


.deathBlock-sev{
  width : 90%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: space-around;
  margin-top: 30px;
}

.view-date-deathb1{
width: 100%
}

.view-date-deathb2 {
  /* margin-top: 30px; */
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.view-date-deathb3 {
  margin-top: -54px;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.view-date-deathb2 .calendar-imagee {
  min-width: 35px;
  height: 30px;
}

.button-txtDeath {
  min-width: 80% !important;
  position: absolute;
  bottom: -40%;
}
.selectedwidth{
  width: 100%;
}