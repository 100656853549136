@import '../../css/global.scss';

#regular-input.filled-multiselect {
  & .css-2b097c-container {
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c6;
  }

  &:hover {
    & .bar:before {
      width: 100%;
    }
    &  label {
      top: -14px;
      font-size: 14px;
      color: $green;
    }
  }

  & .css-1wa3eu0-placeholder {
    display: none !important;
  }

  & .css-1xhx9zg-control {
    min-height: 42px !important;
    border: none;
    & .css-13jmfyo-multiValue {
      & & ~ label {
        font-size: 16px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: $trans-time ease all;
      }
    }
  }

  & .css-bkyy73-menu {
    border-radius: 0;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.12);
  }
  & .css-1hwfws3 {
    padding: 0 2px;
  }

  & .css-13jmfyo-multiValue {
    height: 25px;
    align-items: center;
    color: $green;
    background: white;
    box-shadow: 3px 1px 14px 4px rgba(243, 243, 243, 0.97),
      0px 2px 2px 0px rgba(154, 154, 154, 0.22),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    & .css-1p11jy3 {
      color: hsl(200, 2%, 57%);
    }
    & .css-1dy6un6:hover {
      background-color: #77d718;
      color: #ffffff;
    }
    & .css-1dy6un6 {
      padding: 0;
      margin: 4px 4px 5px 4px;
    }
  }

  //   if item selected

  &.selected {
    &  .css-2b097c-container, .css-14jk2my-container {
      outline: none;
    }
    & .css-1h2jy5y-control{
      background-color:  transparent;
    }
    &  label {
      top: -14px;
      font-size: 14px;
      color: $muted-color;
    }
  }

  &.group {
    position: relative;
  }
  label {
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: $trans-time ease all;
    color: $muted-color;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
    &:before {
      content: '';
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      // background: $green;
      transition: $trans-time ease all;
      left: 0%;
    }
  }
}

#regular-input {
  margin: 30px 0 0 0;
}
