.draggable-item1 {
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2px;
  margin-top: 2px;
}

.draggable-item-horizontal1 {
  height: 300px;
  padding: 10px;
  line-height: 100px;
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 4px;
}

.dragging1 {
  background-color: yellow;
}

.card-scene1 {
  padding: 0px;
}
.card-wrapper1 {
  padding: 0px;
}
.card-container1 {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 7px;
  background-color: #f3f3f3;
}

.card1 {
  background-color: white;
  padding: 10px;
}

.card-column-header1 {
  display: flex;
  justify-content: space-between;
  margin: 0px 5px 0px 5px;
  padding: 5px 20px 5px 20px;
  font-size: 20px;
  align-items: center;
  max-height: 120px;
  margin-bottom: 10px;
  background-color: white;
}

.card-column-headerOne {
  display: flex;
  justify-content: space-between;
  margin: 0px 5px 0px 5px;
  padding: 5px 20px 5px 20px;
  align-items: center;
  max-height: 55px;
  background-color: white;
  margin-bottom: 10px;
}

.smooth-dnd-container1 {
  padding-top: 10px;
}

.column-drag-handle1 {
  cursor: move;
  padding: 5px;
}

.order-wrapper1,
.card-action1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.order-icon1,
.order-action1,
.card-icon1,
.card-details1,
.card-action1 {
  flex: 1;
}
.order-details1 {
  flex: 2;
}

.card-column-header1 .edit-action1 {
  margin-left: 20px !important;
  margin-left: auto;
  font-size: 30px;
  border: 0px;
  background: transparent;
}

.card-column-header1 .delete-action1 {
  color: #9f9f9f;
  margin-left: auto;
  font-size: 30px;
}

.opacity-ghost1 {
  transition: all 0.18s ease;
  opacity: 0.8;
  cursor: ns-resize;
  background-color: #7bd812;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
  cursor: ns-resize;
}

.opacity-ghost-drop1 {
  opacity: 1;
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0);
}

.drop-preview1 {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px;
}

.card-column-header.loader-section1 .card-details1 {
  flex: none;
}

@media (max-width: 1450px) {
  .card-column-header.loader-section1 {
    padding: 20px 5px 0;
  }
  .card-column-header.loader-section1 .truck-capacity1 {
    font-size: 15px !important;
  }
  .card-column-header.loader-section1 .truck-full-capacity1 {
    font-size: 15px;
  }
}

.list-tour1 {
  height: 80% !important;
}

.card-list1 {
  background: url('../../../static/images/backgroundTruckField.svg');
  background-size: contain;
  height: 76%;
  overflow-y: scroll;
  overflow: auto;
  max-height: 600px;
  overflow-x: hidden;
}

.card-list1 > div {
  height: 20%;
}

.card-list1 div .row {
  height: 100%;
}

.list-tour-animal1 {
  height: auto !important;
}

.list-truck1 {
  height: 80% !important;
}

.list-truck-wrapper1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}

.list-truck-wrapper1 .tours-image1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.truck-management1 {
  height: 35% !important;
}

.list-tour1 .list-group-item1 {
  height: 111px;
  width: 100% !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.list-tour1 .list-group-item1 > .row {
  height: 100% !important;
}

.tour-text-groupe1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.truck-text-groupe1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.tours-top-container1 {
  display: flex;
  justify-content: center;
  padding-bottom: 2%;
}

.tours-top-container1 .tour-image.menu1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tours-top-container1 .tours-image1 > img {
  width: 75px;
}

.tours-top-container1 .tours-image1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tour-name1 {
  font-size: 20px;
  font-weight: 600;
  color: #7bd812;
  font-family: 'Titillium-Regular';
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
}

.tour-item1 {
  font-family: 'Titillium-Regular';
  color: #888888;
  float: left;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
  font-size: 15px;
}

.tour-item-two1 {
  font-family: 'Titillium-Regular';
  float: left;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.7em;
  color: #868686;
}

.bar-icon1 {
  height: 100%;
  position: relative;
}

.icon-truck1 {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  margin: 0;
  display: block;
  content: ' ';
  background-size: 28px 28px;
  height: 28px;
  width: 28px;
}

.menu-icon1 {
  background-image: url('../../../static/images/admin/icons_final/menu.svg');
}

.edite-icon1 {
  background-image: url('../../../static/images/admin/icons_final/editIcon.svg');
}

.tour-information-block1 {
  height: 49%;
  margin-bottom: 15px;
}

.truck-information-block1 {
  height: 49%;
}

.tour-management-info1 {
  display: flex;
}

.first-block1 {
  height: 10% !important;
  display: flex;
  font-size: 30px;
  color: #868686;
  font-family: 'Titillium-Regular';
}

.truck-management1 {
  display: flex;
  font-size: 30px;
  color: #868686;
  font-family: 'Titillium-Regular';
}

.close-block-icon1:before {
  display: block;
  content: ' ';
  background-image: url('../../../static/images/admin/icons_final/arrow-back.svg');
  background-size: 28px 28px;
  width: 16px;
  height: 28px;
}

.tour-management-info1 {
  height: 8%;
  align-items: center;
}

.info-not-assigned1 {
  font-size: 23px;
  color: #707070;
  font-family: 'Titillium-Regular';
  padding-right: 10px;
}

.info-number1 {
  font-size: 23px;
  font-weight: 600;
  color: #7bd812;
}

.truck-capacity-groupe1 {
  position: relative;
  height: 100%;
}

.truck-capacity-block1 {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.truck-capacity1 {
  font-size: 65px;
  font-weight: normal;
  color: #7bd812;
}

.truck-full-capacity1 {
  font-family: 'Titillium-Regular';
  font-size: 31.5px;
  font-weight: 100;
  color: #707070;
}

.truc-title-block1 {
  height: 10%;
  display: flex;
  justify-content: space-between;
}

.select-truck1 {
  height: 34px;
  color: #7bd812;
}

.button-block-field1 {
  position: absolute;
  width: 100%;
  bottom: 14px;
  display: flex;
  justify-content: center;
  left: 0;
}

.truck-complete1 {
  border-radius: 10px !important;
  box-shadow: 0px 21px 24px 0 rgba(0, 0, 0, 0.08) !important;
  opacity: 0.5;
  background-color: #7bd812 !important;
  line-height: 2.15 !important;
  color: #ffffff !important;
  font-family: 'Titillium-Regular' !important;
  width: 45% !important;
}

.truck-completed1 {
  opacity: 1 !important;
}

@media screen and (max-width: 1182px) {
  .truc-title-block1 {
    height: 30%;
    display: block;
    justify-content: none;
  }
  .list-tour1 {
    height: 55% !important;
  }
}

.custom-popup-tour {
  min-width: 800px;
  min-height: 400px;
}

.modal-pdfr {
  height: 700px !important;
  width: 700px;
}

.modal-pdfrP {
  height: 800px !important;

  width: 700px;
}

.tourInfo-title-pfT {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}
.tourInfo-title-btn-for {
  width: 99%;
}

.tourInfo-title-btn-for textarea {
  margin-top: 10px !important;
  padding: 2%;
  box-shadow: 0px -5px 14px 4px rgba(226, 222, 222, 0.17),
    1px 1px 8px 0px rgba(202, 202, 202, 0.14),
    1px 1px 6px 0px rgba(175, 175, 175, 0.25) !important;
  border-radius: 7px !important;
  border: none !important;
  color: #333 !important;
  font-size: 16px !important;
  background-color: #ffffff;
  height: 80px;
}

.tourInfo-title-btn-for textarea::-webkit-resizer {
  display: none !important;
}

.tourInfo-title-btn-for {
  justify-content: center;

  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}
.form-input {
  border-radius: 0px;
  padding-left: 0px;
  opacity: 0.9;
  font-family: 'Titillium-Regular' !important;
  font-size: 23px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: rgba(112, 112, 112, 0.75);
}

.sizetextbtn {
  width: 100%;
  font-size: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
  .sizetextbtn {
    font-size: 13px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sizetextbtn {
    font-size: 11px;
  }
}
#admin .view-producer-b1-new {
  width: 34%;
  margin-right: 20px;
}

.newfocuesertags {
  height: 68%;
  overflow-y: scroll;
  overflow: auto;
}

@media only screen and (min-height: 1024px) and (max-height: 1200px) {
  .newfocuesertags {
    height: 65%;
    overflow-y: scroll;
    overflow: auto;
  }
}

@media only screen and (min-height: 901px) and (max-height: 1023px) {
  .newfocuesertags {
    height: 64%;
    overflow-y: scroll;
    overflow: auto;
  }
}

@media only screen and (min-height: 800px) and (max-height: 900px) {
  .newfocuesertags {
    height: 57%;
    overflow-y: scroll;
    overflow: auto;
  }
}

@media only screen and (min-height: 640px) and (max-height: 799px) {
  .newfocuesertags {
    height: 50%;
    overflow-y: scroll;
    overflow: auto;
  }
}

.newfocuesertags::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
}
