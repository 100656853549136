.notice-container {
  color: #b1b7b7;
  & .notice-first-parag {
    padding-top: 85px;
  }
  & .notice-second-parag {
    padding-top: 11%;
    padding-bottom: 5%;
  }
  & .notice-second-paragTwo {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  & .notice-table {
    & .n-t-header {
      display: flex;
      justify-content: space-between;
      & .h-left,
      .h-right {
        display: flex;
        flex-direction: column;
      }

      & .h-right {
        direction: rtl;
      }
    }
    & .n-t-separator {
      width: 100%;
      border-bottom: 1px dashed #b1b7b7;
      margin: 4% 0;
    }
    & .n-t-content {
      display: flex;
      justify-content: space-between;
    }
  }
}
