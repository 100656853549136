$breakpoint-ms-surface: 1500px;
$breakpoint-ms-surface: 1500px;

.membership-specifications {
  padding: 2% 0 5% 0%;
  color: #b1b7b7;
  & .m-item {
    padding: 1% 0;
  }
  & #m-section {
    & .field-specification {
      display: flex;
      align-items: center;
      & img {
        padding-right: 4%;
      }
    }
  }
}
