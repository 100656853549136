.form-user-settings .form-input-placeholder {
  left: 0 !important;
  right: unset !important;
  margin: 0px 0px 5px -13px;
}

.form-user-settings {
  height: 64%;
}

.form-user-settings.add-user .add-user-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10% 0;
}

.form-user-settings .form-input:focus~.form-input-placeholder, .form-user-settings .form-input:valid~.form-input-placeholder {
  left: 0 !important;
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
  color: #7bcd10 !important;
  font-size: 18px;
}

.valid-next {
  opacity: 1 !important;
  color: white !important;
}

.form-user-settings .form-input {
  border-radius: 0px;
  padding-left: 0px;
  opacity: 0.9;
  font-family: 'Titillium-Regular' !important;
  font-size: 23px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: rgba(112, 112, 112, 0.75);
}

.save-button-form {
  width: 50% !important;
  height: 90%;
  border-radius: 10px !important;
  box-shadow: 0px 21px 24px 0 rgba(0, 0, 0, 0.08) !important;
  background-color: #7bd812 !important;
  line-height: 2.15 !important;
  color: #ffffff !important;
  font-family: 'Titillium-Regular' !important;
  font-size: 20px !important;
  margin-left: 50px;
}

.modal-header {
  padding: 9px 15px;
  border-bottom: none !important;
}

.modal-title, .modal-body {
  margin: 15px 20px 0px 20px;
}

.Modal-add-user-title {
  display: flex;
  justify-content: center;
  height: 100%;
}

.Modal-add-user-title>h5 {
  margin: 0px 50px -10px 50px;
  font-size: 30px !important;
  text-align: center !important;
  color: #707070 !important;
  font-size: 30px !important;
}

.button-margin {
  margin: 15px -70px 15px 5px;
}

.form-user-settings .space-top-bottom {
  height: 10%;
  margin-top: 20px;
  margin-bottom: 45px;
}

.form-input {
  border: 0;
  padding: 4px 0;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
  font: 18px 'Titillium-Regular', sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  line-height: 1.4;
}

.form-input-role {
  position: absolute;
  left: 0 !important;
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
  color: #7bcd10 !important;
  font-size: 18px;
  right: unset !important;
  margin: -27px 0px 5px -13px;
  padding: 7px 0 0 18px;
  font-weight: bold;
}

::selection {
  background-color: transparent !important;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-input-placeholder {
  position: absolute;
  bottom: 52%;
  right: 50%;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.2;
}

.form-input~.focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #7bcd10;
  transition: 0.4s;
}

.form-input:focus~.focus-border {
  width: 100%;
  transition: 0.4s;
}

.form-input:valid~.focus-border {
  width: 100%;
  transition: 0.4s;
}

.form-input:focus~.form-input-placeholder, .form-input:valid~.form-input-placeholder {
  position: absolute;
  right: 50%;
  font-size: 23px;
  -webkit-transform: translate3d(0, -30%, 0);
  transform: translate3d(0, -30%, 0);
  opacity: 1;
  color: #7bcd10;
}

.submit-field {
  position: absolute;
  bottom: 0;
  width: 94%;
  height: 10%;
  text-align: center;
}

/*form input role*/

.form-input-role-box .css-dvua67-singleValue {
  font-family: 'Titillium-Regular' !important;
  font-size: 23px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  padding-left: -6px !important;
  color: rgba(112, 112, 112, 0.75) !important;
  margin-left: -6px;
}

.css-wkm2uu-control .css-1wy0on6 {
  display: block !important;
}

.css-bgvzuu-indicatorSeparator {
  display: none !important;
}

/*container*/

.contact-popup-block-b {
  position: relative;
  height: 100%;
  width: 95%;
  padding-right: 0;
}

.list-contact-popup {
  margin-top: 0% !important;
  overflow-y: scroll !important;
  height: 510px !important;
}

.form-contact-popup {
  margin-top: 0% !important;
  overflow-y: scroll !important;
  height: 715px !important;
}

.add-contact-text {
  position: absolute !important;
  right: 75px !important;
}

.form-contact-popup #regular-input {
  border: none;
}

.search-company .confirm-contact-add {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -8%;
}

.form-input-role-box {
  margin: 30px 0 17px 0;
}