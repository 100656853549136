.form-to-be-loaded .form-input-placeholder {
  opacity: 1.5 !important;
  left: 0 !important;
}

.form-to-be-loaded {
  height: 64%;
  padding-top: 10%;
}

.row-to-be-loaded {
  height: 90%;
}

.form-to-be-loaded .form-input-placeholder {
  left: 0 !important;
  right: unset !important;
}

.form-to-be-loaded .form-input:focus ~ .form-input-placeholder,
.form-to-be-loaded .form-input:valid ~ .form-input-placeholder {
  left: 0 !important;
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
  color: #7bcd10 !important;
}

.valid-next {
  opacity: 1 !important;
  color: white !important;
}

.form-to-be-loaded .form-input {
  border-radius: 0px;
  padding-left: 0px;
  opacity: 0.9;
  font-family: 'Titillium-Regular' !important;
  font-size: 23px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: rgba(112, 112, 112, 0.75);
}


.to-be-loaded-form {
  padding-top: 6%;
}