#register-page {
  background: white;
  min-height: 100%;
  width: 100% !important;
}
#register-page .header {
  margin-bottom: 20px;
}

#register-page .nav-step {
  text-align: center;
  margin-top: 2%;
}

#register-content {
  padding-top: 10%;
  position: relative;
}

#register-footer {
  left: 0;
  bottom: 5%;
  width: 100%;
  color: rgba(183, 183, 183, 0.7);
}

#register-content .form-control {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px;
  color: black !important;
}

#register-content .form-control:focus {
  border: 0 0 2px 2px !important;
  box-shadow: 0 2px 2px -2px #7bd812 !important;
  color: #7bd812;
}

#register-content input:focus::-webkit-input-placeholder {
  color: #7bd812 !important;
}

#register-content .input-group-addon {
  background-color: white !important;
  border: none !important;
}

#register-content .heading,
#register-content h3,
#register-content h4,
#register-content p,
#register-content button {
  font-family: 'Titillium-Regular' !important;
}

#register-content .form-control::placeholder {
  font-size: 18px;
  color: rgba(183, 183, 183, 0.7);
}

#register-content .input-group-addon svg,
#register-content .glyphicon-eye-open,
#register-content .glyphicon-eye-close {
  font-size: 180%;
  color: rgba(183, 183, 183, 0.7);
}
#register-content .input-group:focus-within .input-group-addon svg {
  border: 0 0 0 0 !important;
  color: #7bd812;
}

#register-content p,
#titleStep3 {
  color: rgba(183, 183, 183, 0.7) !important ;
  margin-bottom: 30px;
}

/* page 1 */
#register-content .register-form-title {
  margin-top: 0%;
  margin-bottom: 25%;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
}

#register-content .firstText span {
  color: #626262 !important;
}

#register-content .firstTextClair span {
  color: #c3c3c3 !important ;
}

#register-content .container-register-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#register-content .margin-btn-top {
  margin-top: 16%;
  margin-bottom: 7%;
}
#register-content .invalid-feedback {
  font-size: 11px;
  color: red;
}
#register-content .register-form-btn {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #7bd812 !important;
  font-size: 18px;
  color: #fff !important;
}

#register-content .register-form-btn:hover {
  background-color: #b6b6b6 !important;
}

/* page 2 */

#register-content .btn-group {
  width: 100%;
}

#register-content .btn-card {
  width: 14%;
  margin: 1%;
  height: 150px;
  border: transparent;
  -webkit-appearance: initial;
  background-size: 30% !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  opacity: 0.5;
}

#register-content .btn-card.active {
  background: transparent;
  opacity: 1.5;
  background-size: 90% !important;

  border: 4px solid #7bd812 !important;
}
#register-content .ic_p1off {
  background-image: url('../../static/images/icon-users/mdpi/ic_p1off.png') !important;
}
#register-content .ic_p2off {
  background-image: url('../../static/images/icon-users/mdpi/ic_p2off.png') !important;
}
#register-content .ic_p3off {
  background-image: url('../../static/images/icon-users/mdpi/ic_p3off.png') !important;
}
#register-content .ic_p4off {
  background-image: url('../../static/images/icon-users/mdpi/ic_p4off.png') !important;
}
#register-content .ic_p5off {
  background-image: url('../../static/images/icon-users/mdpi/ic_p5off.png') !important;
}
#register-content .ic_p6off {
  background-image: url('../../static/images/icon-users/mdpi/ic_p6off.png') !important;
}
#register-content .toolbarUser {
  margin-top: 5%;
}

/* page 3 */
#register-content .titleStep3 {
  font-size: 25px;
  color: #b6b6b6;
}

.buisness-text {
  margin: auto !important;
}

@media screen and (max-width: 1024px) {
  #register-content .btn-card.active {
    background-size: 90% !important;
  }
}

@media screen and (min-width: 1024px) {
  #register-footer {
    position: fixed;
    margin: 0% 0% 0% 10%;
  }
}

#register-content .user-input-wrp {
  position: relative;
}
#register-content .user-input-wrp .inputText {
  width: 100%;

  outline: none;
  border: none;
  border-bottom: 1px solid #777;
}
#register-content .user-input-wrp .inputText:invalid {
  box-shadow: none !important;
}
#register-content .user-input-wrp .inputText:focus {
  border-width: medium medium 2px;
}
#register-content .user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  color: #b6b6b6;
  top: 6px;
  left: 10px;
  transition: 0.2s ease all;
}
#register-content .user-input-wrp input:focus ~ .floating-label,
#register-content .user-input-wrp input:not(:focus):valid ~ .floating-label {
  top: 0px;
  left: 10px;
  color: #7bd812;
  font-size: 10px;
  opacity: 1;
}

@media (max-width: 991px) {
  #register-content .titleStep3 {
    font-size: 22px !important;
    color: #b6b6b6;
  }
}

@media (min-width: 992px) {
  #register-content > .row {
    display: flex;
    justify-content: center;
  }
}
