#admin {
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Titillium-Regular';
  font-size: 16px;
  line-height: 1.7;
  letter-spacing: 0.01em;
  font-weight: 300;
  color: #333;
  display: flex;
  flex-flow: column;
  height: 100vh;
}

#admin button {
  background-color: transparent;
  border-style: none;
}

.list-group-item:hover {
  background-color: #f5f5f5;
}

/* modal style */

.modal-backdrop.fade {
  opacity: 0.5 !important;
}

.modal.fade .modal-dialog {
  transform: translate(0, 0) !important;
  -webkit-transform: translate(0, 0);
}

/* end modal style  */

#admin .form-btn {
  width: 100%;
  height: 42px;
  background-color: #7bd812 !important;
  border-color: #7bd812;
  border-radius: 5px;
  font-size: 18px;
  color: #fff !important;
}

#admin .form-btn:hover,
#admin .form-btn:focus {
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
}

.ear_tag_list {
  text-align: center;
  font-size: 24.5px;
  color: rgba(112, 112, 112, 0.8);
}

#admin .form-label {
  font-family: 'Titillium-Regular' !important;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-top: 3%;
  color: #2d3342;
}

#admin .white-color {
  color: #ffffff !important;
}

#admin .Collapsible__trigger {
  width: 100%;
}

#admin .Select {
  width: 100% !important;
}

#admin .select-language {
  background: #ffff !important;
  font-size: 1em;
  font-weight: 700;
  padding: 5px 0px 5px 13px;
  margin: 3px 34px 0px 70px;
}

#admin .order-by-sell .select-language {
  font-weight: 200 !important;
  font-size: 19px;
  height: 33px;
  font-size: 21px;
  color: hsl(0, 0%, 60%) !important;
}

#admin .order-by-sell .form-wrapper.sellings .select-language {
  height: 40px !important;
}

#admin .button {
  display: inline-block;
  color: #7bd812 !important;
  background: #ffff !important;
  border: 2px solid #e7e7e7;
  font-size: 1em;
  font-weight: 700;
  line-height: 3.125;
  border-radius: 25px;
  padding: 0 40px;
  text-align: center;
  transition: all 0.5s ease;
}

#admin .Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

#admin .Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}

#admin label {
  /* min-width: 180px;
  width: 180px; */
  font-weight: bold;
  font-size: 25px;
}

#admin .p-b-0 label {
  min-width: 180px;
  width: 180px;
  font-weight: bold;
  font-size: 15px;
}

#admin .input-form {
  border: none !important;
  border-bottom: 2px solid #dddddd !important;
  border-radius: 0 !important;
}

#admin p,
#admin label {
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Titillium-Regular';
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

#admin .h1,
#admin .h2,
#admin.h3,
#admin .h4,
#admin .h5,
#admin .h6,
#admin h1,
#admin h2,
#admin h3,
#admin h4,
#admin h5,
#admin h6 {
  /* font-family: Quicksand, sans-serif; */
  font-family: 'Titillium-Regular';
  line-height: 1.25;
  font-weight: #666;
  margin-bottom: 28px;
  color: 666;
  margin: 0;
}

#admin svg {
  font-size: 25px;
}

/* ----------------------------------------------------------------------------
                                  
                        this is the style concerning the sideBar 

  ----------------------------------------------------------------------------                      */

#admin .sidebar {
  position: fixed;
  display: block;
  z-index: 1;
  color: #fff;
  background-size: cover;
  border-right: 0.5px solid #ddd;
  width: 295px !important;
  background-image: url(../../static/images/admin/side.png);
}

#admin .sidebar.top-bar .nav-bottom {
  position: absolute;
  bottom: 0;
  margin-bottom: 10%;
}

@media screen and (min-width: 700px) and (max-width: 1300px) {
  #admin .sidebar.top-bar .nav-bottom {
    margin-top: auto !important;
    position: static;
    bottom: 0;
    margin-bottom: auto;
    overflow: auto;
  }
}

@media screen and (min-height: 761px) and (max-height: 820px) {
  #admin .sidebar.top-bar .nav-bottom {
    margin-top: auto !important;
    position: static;
    height: 70%;
    bottom: 0;
    margin-bottom: auto;
    overflow: auto;
  }
}

@media screen and (min-height: 200px) and (max-height: 760px) {
  #admin .sidebar.top-bar .nav-bottom {
    margin-top: auto !important;
    position: static;
    height: 60%;
    bottom: 0;
    margin-bottom: auto;
    overflow: auto;
  }
}

#admin .sidebar.top-bar .nav-bottom::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
}

#admin .sidebar.top-bar .nav-top a p span {
  direction: rtl;
}

#admin .sidebar p {
  font-family: 'Titillium-Regular';
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: #767676 !important;
}

#admin .sidebar .sidebar-wrapper {
  position: relative;
  height: calc(100vh - 100px);
  width: 297px;
  z-index: 4;
}

#admin .sidebar .sidebar-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background: #f0f0f1;
  background-size: cover;
  background-position: center center;
}

#admin .sidebar .logo {
  width: 260px;
  height: 65px;
  margin-left: 50px;
  position: relative;
  z-index: 4;
}

#admin .sidebar .logo img {
  cursor: pointer;
}

#admin .sidebar p::before {
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px;
  width: 60px;
}

#admin .sidebar .addStock p::before {
  content: url(../images/admin/drawable-mdpi/icon_main_addstock.png);
}

#admin .sidebar .viewStock-menu p::before {
  content: url(../images/admin/drawable-mdpi/icon_main_viewstock.png);
}

#admin .sidebar .myBuying p::before {
  content: url(../images/admin/drawable-mdpi/icon_main_buystock.png);
}

#admin .sidebar .buyingsOverview p::before {
  content: url(../images/admin/drawable-mdpi/overview-icon.png);
}

#admin .sidebar .slaughterReport p::before {
  content: url(../images/admin/drawable-mdpi/paper2.png);
}

#admin .sidebar .mySelings p::before {
  content: url(../images/admin/drawable-mdpi/icon_main_sellstock.png);
}

#admin .sidebar .drug p::before {
  content: url(../images/admin/drawable-mdpi/icon_main_antibiotics.png);
}

#admin .sidebar .search p::before {
  content: url(../images/admin/drawable-mdpi/icon_main_reports.png);
}

#admin .sidebar .live p::before {
  content: url(../images/admin/drawable-mdpi/icon_businesstype_transporter.png);
}

#admin .sidebar .manageTours p::before {
  content: url(../images/admin/drawable-mdpi/icon_businesstype_transporter.png);
}

#admin .sidebar .tourPlanningProducer p::before {
  content: url(../images/admin/drawable-mdpi/icon_main_cattleplanning.png);
}
#admin .sidebar .my-tours p::before {
  content: url(../images/admin/drawable-mdpi/icon_main_mytours.png);
}

#admin .sidebar .offer-tours p::before {
  content: url(../images/admin/drawable-mdpi/icon_main_offertours.png);
}

#admin .sidebar .search-tours p::before {
  content: url(../images/admin/drawable-mdpi/icon_main_searchtours.png);
}

#admin .sidebar .logo p {
  float: left;
  font-size: 20px;
  margin: 10px 10px;
  color: #ffffff;
  line-height: 20px;
  font-family: 'Titillium-Regular';
}

#admin .sidebar .logo a.logo-mini {
  float: left;
  text-align: center;
  width: 30px;
  margin-right: 15px;
}

#admin .sidebar .logo .logo-mini .logo-img img {
  max-width: initial;
  width: 140px;
  margin-left: 30px;
  display: block;
  margin-top: 25px;
}

#admin .sidebar .logo a.logo-normal {
  display: block;
}

#admin .sidebar .logo .logo-img {
  all: initial;
}

#admin .sidebar .logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden;
}

#admin .sidebar .logo-tim img {
  width: 60px;
  height: 60px;
}

#admin .navbar-fixed-top {
  background-repeat-x: repeat;
  background-image: url(../../static/images/admin/side.png);
}

#admin .navbar-fixed-top .companyname {
  height: 30px;
  font-size: 25.5px;
  line-height: 0.46;
  text-align: left;
  color: #767676;
  margin-right: -40px;
  margin-top: 42px;
  padding-left: 10px;
}

#admin .navbar-fixed-top .navbar-collapse {
  float: right;
  margin-right: 35px;
}

#admin .sidebar .nav-top {
  display: block;
  margin-top: 12%;
  margin-left: 30%;
}

#admin .nav-top li {
  background: initial;
  background: transparent;
  margin-bottom: 5px;
}

#admin .sidebar .nav-top li > a {
  vertical-align: middle;
  height: 100%;
}

#admin .sidebar .nav-top li.active > a p {
  color: #7bd812 !important;
}

#admin .sidebar .nav-top li {
  margin-left: 15px;
  height: 40px;
}

@media screen and (min-width: 700px) and (max-width: 1300px) {
  #admin .sidebar .nav-top li {
    margin-left: 15px;
    height: auto;
  }
}

@media screen and (min-height: 200px) and (max-height: 820px) {
  #admin .sidebar .nav-top li {
    margin-left: 15px;
    height: auto;
  }
}

#admin .sidebar .nav {
  width: 98%;
  display: block;
}

#admin .nav li {
  background: initial;
  background: transparent;
  margin-left: 0px;
}

#admin .sidebar .nav li {
  margin-left: 15%;
  height: 60px;
  margin-bottom: 2%;
}

#admin .sidebar .nav li.active > a {
  color: #ffffff;
  background: #fff;
}

#admin .sidebar .nav li.active > a p {
  color: #7bd812 !important;
}

#admin .sidebar .nav li.active {
  margin-left: 20px;
}

#admin .sidebar .nav li > a {
  padding: 3px;
  margin: 0px;
  vertical-align: middle;
  height: 100%;
  padding: 3px;
  margin: 0px;
  vertical-align: middle;
  background: white;
  border-radius: 20px 0px 0px 20px;
  height: 100%;
}

#admin .sidebar .nav li:hover > a,
#admin .sidebar .nav li.open > a,
#admin .sidebar .nav li > a:active,
#admin .sidebar .nav li.open > a:focus,
#admin .sidebar .nav li.open > a:hover {
  color: #ffffff;
  background: #fff;
  border-radius: 20px 0px 0px 20px;
  border-left: 5px solid #7bd812;
}

#admin .sidebar .nav li i {
  visibility: hidden;
}

#admin .sidebar .nav p {
  font-family: 'Titillium-Regular';
  font-size: 15px;
  margin: 4%;
  border-style: solid;
  letter-spacing: 0.02em;
  font-weight: 300;
}

#admin .sidebar .nav .caret {
  top: 24px;
  position: absolute;
  right: 15px;
}

#admin .sidebar .nav i {
  font-size: 28px;
  float: left;
  margin-right: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}

#admin .sidebar .nav i.glyphicon {
  display: inline block;
  top: 3px;
  /* width: 20px; */
  margin-right: 20px;
  font-size: 25px;
}

.downNav {
  margin-left: 103px;
  display: block;
}

.downNav p {
  color: white;
}

.downNav li {
  list-style: none;
}

/*                      ***************   Navbar   *************************** */

#admin .btn-success {
  background: #7bd812;
}

#admin .navbar {
  right: -7px;
  top: 0;
  height: 11%;
  width: 100%;
  margin-left: 297px;
  font-size: 16px;
  border-radius: 0;
}

.input-group-prepend {
  display: flex;
  align-items: center;
}

#admin .navbar .navbar-brand {
  color: #fff;
  font-weight: 400;
  font-size: 20px;
}

#admin .container-fluid {
  background-color: white;
}

#admin .navbar .navbar-nav > li > a {
  padding: 7px 15px;
  position: relative;
}

#admin .navbar .navbar-nav > li > a.btn {
  margin: 15px 3px;
  padding: 8px 16px;
}

#admin .navbar .navbar-nav > li > a.btn-round {
  margin: 16px 3px;
}

#admin .navbar .navbar-nav > li > a [class^='fa'] {
  font-size: 19px;
  position: relative;
  line-height: 16px;
  top: 1px;
}

#admin .navbar .navbar-right {
  float: right !important;
  margin-right: 40px;
}

#admin .navbar .navbar-nav .notification {
  position: absolute;
  background-color: #fb404b;
  text-align: center;
  border-radius: 10px;
  min-width: 18px;
  padding: 0 5px;
  height: 18px;
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
  line-height: 18px;
  top: 0px;
  left: 7px;
}

#admin .navbar .btn {
  margin: 15px 3px;
  font-size: 14px;
}

#admin .navbar .btn-simple {
  font-size: 16px;
}

#admin .navbar.fixed {
  right: 0;
  left: auto;
  border-radius: 0;
}

#admin .userimg {
  margin-left: 3px;
  margin-top: 7px;
  vertical-align: middle;
  border-radius: 10px;
  width: 60px;
}

#admin .icon-nav {
  width: 20%;
  line-height: 1;
}

#admin .m-b-30 {
  margin-bottom: 15px;
}

#admin .badge {
  margin-top: -10px;
  margin-left: -5px;
  color: #fff !important;
  background-color: orangered !important;
  border-radius: 100% !important;
  display: inline-block !important;
}

@media screen and (min-width: 991px) and (max-width: 1280px) {
  #admin .messages .message-col {
    width: 80% !important;
  }
}

/*                  ========================================================================

                                                    main Card 

                    ========================================================================*/

#admin #card-feedback {
  background: url(../images/admin/feedbackbg.jpg);
  height: 170px;
  width: 100%;
  background-size: cover;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#admin .card-box {
  padding: 20px 30px;
  -webkit-box-shadow: 0px 0px 7px 1.5px rgba(0, 0, 0, 0.06),
    0 1px 0px 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0px 9px 1.5px rgba(0, 0, 0, 0.06),
    0 1px 0px 0 rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  background-color: #ffffff;
}

#admin .btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

#admin .panel-group:last-child {
  margin-bottom: 0;
}

#admin .panel-title {
  margin-top: 0;
}

#admin .panel-title > a {
  display: block;
  padding: 15px;
}

#admin .panel-default {
  border-color: transparent;
}

#admin .panelHeader {
  width: 100%;
  height: 54px;
  border-radius: 20px;
  background-color: #f6f6f6;
  box-shadow: 0 0 6.6px 1.4px rgba(97, 97, 97, 0.15);
}

#admin .Collapsible {
  margin-top: 10px;
}

#admin .panelHeaderTitle {
  margin-top: 10px;
  margin-left: 10px;
  font-family: 'Titillium-Regular';
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7bd812;
}

.font-secondary,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: Quicksand, sans-serif; */
  font-family: 'Titillium-Regular';
}

.showHide {
  position: absolute;
  color: #d51c1c !important;
  right: 0;
  font-weight: bold;
  vertical-align: middle !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 0px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -moz-osx-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 -6px 0 0 #fff, inset 0 6px 0 0 #fff;
  -moz-box-shadow: inset 0 -6px 0 0 #fff, inset 0 6px 0 0 #fff;
  -o-box-shadow: inset 0 -6px 0 0 #fff, inset 0 6px 0 0 #fff;
  -moz-osx-box-shadow: inset 0 -6px 0 0 #fff, inset 0 6px 0 0 #fff;
  -ms-box-shadow: inset 0 -6px 0 0 #fff, inset 0 6px 0 0 #fff;
  box-shadow: inset 0 -6px 0 0 #fff, inset 0 6px 0 0 #fff;
  background-color: rgba(209, 209, 209, 0.97);
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background: #fff;
}

#admin .main-panel {
  margin-left: 320px;
  background: #fff;
  position: relative;
  float: right;
  height: 90%;
  padding-top: 10px;
}

#admin .main-panel > .content {
  padding: 0px 15px;
  height: 100%;
}

#admin .main-panel > .footer {
  border-top: 1px solid #e7e7e7;
}

#admin .main-panel .navbar {
  margin-bottom: 0;
}

#admin .sidebar,
#admin .main-panel {
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
}

.nav-open #admin .sidebar {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate3d(0px, 0, 0);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

/* testing nav button */

#admin .navbar-header .navbar-toggle {
  border-color: black !important;
}

@media screen and (max-width: 991px) {
  #admin .navbar {
    background: linear-gradient(white, white, white) !important;
  }
  #admin .sidebar {
    left: -300px !important;
    right: auto !important;
  }
  #admin .companyname {
    display: none;
  }
  #admin .navbar-nav {
    margin: 1px 0;
    display: flex;
  }
  #admin .messages .card-box {
    min-height: 800px;
    margin-top: 20%;
  }
  #admin .container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    margin-top: 10%;
  }
  #admin .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 991px) {
  #admin .main-panel {
    /* width: 0%; */
    width: calc(100% - 310px) !important;
  }
  #admin .admin-overlay {
    opacity: 0.4;
    pointer-events: none;
  }
}

/* end test  */

.nav-open #admin #main-panel {
  right: -250px;
  opacity: 1 !important;
}

#admin .navbar-toggle .icon-bar {
  margin-bottom: 3px;
  display: block;
  position: relative;
  background: #72d835;
  width: 20px;
  height: 3px;
  border-radius: 1px;
}

#bodyClick {
  position: fixed;
  opacity: 0;
  top: 0;
  left: auto;
  right: 250px;
  content: '';
  z-index: 9999;
  overflow-x: hidden;
}

#admin .popup {
  position: fixed;
  width: 100%;
  height: 100%;
  margin-top: 200px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

#admin .popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

#admin .pricing-section {
  position: relative;
  background-size: cover;
  margin-bottom: 12px;
  margin-top: -40px;
  margin-left: -44px;
}

#admin .pb-80 {
  padding-bottom: 80px;
}

#admin .imagebg {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.4s;
  background-repeat: no-repeat;
}

#admin .imagebg img {
  display: none;
}

#admin .card-box img {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  background: 0 0;
}

:focus,
a:focus,
#admin .card-box img {
  outline: 0;
}

#admin .card-box img {
  vertical-align: middle;
}

#admin .card-box img {
  border: 0;
}

#admin .card-box .gradiant-light {
  opacity: 0.25;
}

#admin .gradiant-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.45;
}

#admin .card-box #pricing .container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

#admin .text-center {
  text-align: center !important;
}

#admin .text-right {
  text-align: right !important;
}

#admin .text-left {
  margin-top: 0px;
  text-align: left !important;
}

#admin .section-head {
  padding: 0px 0 47px;
}

#admin .orange {
  color: #ea8502;
}

#admin .green {
  color: #7bd812;
  font-size: 20px !important;
  margin: 0;
}

#admin .heading-light {
  color: #737781;
}

#admin .card-box #pricing .fadeIn {
  visibility: visible;
  animation-duration: 1s;
  animation-name: fadeIn;
}

#admin .card-box #pricing h2 {
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 28px;
  color: #666;
}

#admin .card-box #pricing p {
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.7em;
  color: #999;
  margin: 3px;
  padding: 3px;
}

#admin .pricing-box {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  /* margin: 18px 5px 30px -18px; */
  /* margin: 0px 0px 30px 0px; */
  margin-left: -22px;
  border-radius: 8px;
  background: #fff;
}

#admin .pricing-box {
  -webkit-box-shadow: 5px 10px 18px #888888 !important;
  box-shadow: 5px 10px 18px #888888 !important;
}

.pricing-box:hover {
  transform: translateY(-8px);
}

#admin .pricing-top {
  position: relative;
  border-radius: 8px 8px 0 0;
  padding: 19px 0;
  height: 160px;
}

#admin .pricing-badge {
  color: #fff;
  font-size: 0.9em;
  display: inline-block;
  margin-bottom: 15px;
  font-weight: 400;
  text-transform: uppercase;
}

#admin .pricing-top h5 {
  font-family: MullerBold;
  font-size: 30px;
  line-height: 1.25;
  font-weight: 700;
  color: #666;
  margin-bottom: 12px;
}

#admin .pricing-bottom {
  background: transparent;
  border-radius: 0 0 8px 8px;
  padding: 2px 40px 5px 36px;
}

#admin .pricing-bottom ul {
  list-style: none;
  margin-bottom: 5px;
  padding-left: initial;
}

#admin .pricing-bottom ul li {
  font-weight: 600;
  line-height: 2.7;
  position: relative;
  margin-top: 8px;
}

#admin .pricing-bottom ul li span {
  font-weight: 600;
  line-height: 2.7;
  position: relative;
  margin-top: 8px;
  font-size: 10.5px;
}

#admin .pricing-bottom ul li .ti {
  margin-right: 10px;
  font-size: 1em;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 2.5;
}

.pricing-bottom .ti-check {
  color: #7bd812;
}

.pricing-bottom .ti-close {
  color: #de0000;
}

#admin .card-box #pricing .button {
  font-family: MullerBold;
  color: #7bd812 !important;
  background: #ffff !important;
  border: 2px solid #e7e7e7;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 3.125;
  border-radius: 25px;
  padding: 0 40px;
  color: #fff;
  text-align: center;
  background: #e0486e;
  transition: all 0.5s ease;
}

/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_- input group    -_-_-_-_-_-_-_-_-_-_-_-_-_*/

#admin-setting .form-control {
  object-fit: contain;
  border-radius: 0px !important;
  border-top: transparent !important;
  border-right: transparent !important;
  border-left: transparent !important;
  border-radius: 8px;
  box-shadow: none;
}

#admin-setting form input:focus {
  border-color: #7bd812 !important;
  color: #7bd812;
}

#admin-setting .redo {
  float: right;
  cursor: pointer;
}

#admin .password-management .form-control {
  height: 0px;
}

#admin .form-container input[type='checkbox'] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  /* Styling checkbox */
  width: 19px !important;
  height: 19px !important;
  background-color: #fff !important;
  border: 2px solid #7bd812 !important;
  margin-right: 10px !important;
}

#admin .form-container input[type='checkbox']:checked {
  background-color: #7bd812 !important;
  border-color: #7bd812 !important;
}

#admin .form-container input[type='checkbox'] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  /* Styling checkbox */
  width: 19px !important;
  height: 19px !important;
  background-color: #fff !important;
  border: 2px solid #7bd812 !important;
}

#admin .form-container input[type='checkbox']:checked {
  background-color: #7bd812 !important;
  border-color: #7bd812 !important;
}

#admin .form-control {
  height: 0%;
  padding: 8px 15px;
  margin-top: 2%;
  margin-bottom: 1%;
  color: #666;
  border: 1px solid #eee;
  border-radius: 8px;
  transition: all 0.5s ease;
  font-size: 16px;
}

#admin .input-group[class*='col-'] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}

#admin .input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

#admin .input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

#admin .input-group-btn,
#admin .input-group .form-control {
  display: table-cell;
}

#admin .input-group-btn > .btn {
  z-index: 3;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  -moz-user-select: none;
  -ms-user-select: none;
  position: relative;
}

#admin .btn-file {
  position: relative;
  overflow: hidden;
  height: 50px;
}

#admin .btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

/*-_-_-_-_-_-_-_-_-_-_-_-_-_-_- checkBox    -_-_-_-_-_-_-_-_-_-_-_-_-_*/

#admin .checkbox {
  padding-left: 20px;
}

#admin .checkbox label {
  margin-top: 24px;
  display: inline-block;
  padding-left: 5px;
  position: relative;
  min-width: 342px;
  /* width: 180px; */
  font-weight: bold;
  font-size: 14px;
}

#admin .checkbox label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #cccccc;
  content: '';
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
}

#admin .checkbox label::after {
  color: #555555;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: 0;
  width: 16px;
}

#admin .checkbox input[type='checkbox'] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}

#admin .checkbox input[type='checkbox']:disabled + label {
  opacity: 0.65;
}

#admin .checkbox input[type='checkbox']:focus + label::before {
  outline-offset: -2px;
  outline: none;
}

#admin .checkbox input[type='checkbox']:checked + label::after {
  content: '\f00c';
  font-family: 'FontAwesome';
}

#admin .checkbox input[type='checkbox']:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

#admin .checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

#admin .checkbox.checkbox-inline {
  margin-top: 0;
}

#admin .checkbox.checkbox-single label {
  height: 17px;
}

#admin .checkbox-custom input[type='checkbox']:checked + label::before {
  background-color: #71b6f9;
  border-color: #71b6f9;
}

#admin .checkbox-custom input[type='checkbox']:checked + label::after {
  color: #ffffff;
}

#admin .checkbox-primary input[type='checkbox']:checked + label::before {
  background-color: #188ae2;
  border-color: #188ae2;
}

#admin .checkbox-primary input[type='checkbox']:checked + label::after {
  color: #ffffff;
}

#admin .checkbox-danger input[type='checkbox']:checked + label::before {
  background-color: #ff5b5b;
  border-color: #ff5b5b;
}

#admin .checkbox-danger input[type='checkbox']:checked + label::after {
  color: #ffffff;
}

#admin .checkbox-info input[type='checkbox']:checked + label::before {
  background-color: #35b8e0;
  border-color: #35b8e0;
}

#admin .checkbox-info input[type='checkbox']:checked + label::after {
  color: #ffffff;
}

#admin .checkbox-warning input[type='checkbox']:checked + label::before {
  background-color: #f9c851;
  border-color: #f9c851;
}

#admin .checkbox-warning input[type='checkbox']:checked + label::after {
  color: #ffffff;
}

#admin .checkbox-success input[type='checkbox']:checked + label::before {
  background-color: #10c469;
  border-color: #10c469;
}

#admin .checkbox-success input[type='checkbox']:checked + label::after {
  color: #ffffff;
}

#admin .checkbox-purple input[type='checkbox']:checked + label::before {
  background-color: #5b69bc;
  border-color: #5b69bc;
}

#admin .checkbox-purple input[type='checkbox']:checked + label::after {
  color: #ffffff;
}

#admin .checkbox-pink input[type='checkbox']:checked + label::before {
  background-color: #ff8acc;
  border-color: #ff8acc;
}

#admin .checkbox-pink input[type='checkbox']:checked + label::after {
  color: #ffffff;
}

#admin .checkbox-inverse input[type='checkbox']:checked + label::before {
  background-color: #3b3e47;
  border-color: #3b3e47;
}

#admin .checkbox-inverse input[type='checkbox']:checked + label::after {
  color: #ffffff;
}

#admin .invalid-feedback {
  font-size: 11px;
  color: red;
}

/*               ========================================================================        

                                  POPUP Display

                 ========================================================================*/

#admin #myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#admin #myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */

#admin .modal {
  padding-left: 150px;
  padding-right: 150px;
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  /* Sit on top */
  /* z-index: 9999; */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  /* top: 0;
   */
  margin-top: 5px;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9);
  /* Black w/ opacity */
}

/* Modal Content (image) */

#admin .modal-content {
  margin: auto;
  display: block;
  width: 100%;
  max-width: 1000px;
  border-radius: 40px;
}

/* Caption of Modal Image */

#admin #caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */

#admin .modal-content,
#admin #caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */

#admin .close {
  /* z-index: 9998; */
  position: fixed;
  top: 10px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  opacity: 1;
}

#admin .close:hover,
#admin .close:focus,
#admin svg {
  cursor: pointer;
}

/*               ========================================================================        

                                   Changes for small display  

                 ========================================================================*/

@media (max-width: 991px) {
  #admin .main-panel {
    width: 100%;
  }
  #admin .sidebar .logo {
    width: 260px;
    height: 65px;
    margin-left: 20%;
    margin-top: 60%;
    position: relative;
    z-index: 4;
  }
  #admin body {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /* position: relative; */
  }
  #admin .main-panel {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    left: 0;
  }
  #admin .navbar .container {
    left: 0;
    width: 100%;
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    position: relative;
  }
  #admin .modal {
    padding-left: initial;
    padding-right: initial;
    height: 150%;
    /* z-index: 214748364; */
    z-index: 2;
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    /*  Sit on top */
    /*padding-top: 110px; Location of the box */
    /* margin-top: -110px; */
    /* top: 0; */
    top: 15px;
    /* margin-top: 5px; */
  }
  #admin .close {
    z-index: 3;
    position: fixed;
    top: 80px;
    right: 10px;
  }
  #admin .navbar {
    margin-left: initial;
    left: 0;
    width: 100%;
    position: fixed;
    min-height: 75px;
    color: #fff;
    font-size: 16px;
    z-index: initial;
    z-index: 1;
    border-radius: 1px;
  }
  #admin .sidebar {
    /* position: fixed; */
    display: block;
    top: 0;
    height: 100%;
    width: 260px;
    right: -10px;
    left: auto;
    /* z-index: 1032; */
    visibility: visible;
    background-size: initial;
    background-image: initial;
    background-size: cover;
    /* background-color: #999; */
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding: 0;
    /* background: #d51c1c; */
    -webkit-transform: translate3d(250px, 0, 0);
    -moz-transform: translate3d(250px, 0, 0);
    -o-transform: translate3d(250px, 0, 0);
    -ms-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  #admin .sidebar > ul {
    position: relative;
    /* z-index: 4; */
    overflow-y: scroll;
    height: calc(100vh - 61px);
    width: 100%;
  }
  #admin .sidebar::before {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    display: block;
    content: '';
    z-index: 1;
  }
  .nav-open #admin .sidebar {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .nav-open #admin .navbar {
    right: 250px;
  }
  .nav-open #admin #main-panel {
    left: -250px;
  }
  #admin .navbar-nav {
    margin: 1px 0;
  }
  #admin .navbar-nav > li {
    float: none;
    position: relative;
    display: block;
  }
  #admin .sidebar .nav li {
    text-align: left;
    /* float: left; */
    background: transparent;
  }
  #admin .sidebar .nav li.active {
    color: #fff;
  }
  #admin .sidebar .nav p {
    font-size: 10px;
  }
  #admin .sidebar .nav .nav-link {
    /* float: left; */
  }
  /* #admin .sidebar .nav li.active > a, */
  #admin .sidebar .nav li:hover > a,
  #admin .sidebar .nav li.open > a,
  #admin .sidebar .nav li.open > a:focus,
  #admin .sidebar .nav li.open > a:hover {
    /*background: transparent;
    /* background: rgba(255, 255, 255, 0.13); */
    /* color: #333; */
    /* opacity: 0.3; */
  }
  #admin .sidebar .nav li.active i,
  #admin .sidebar .nav li.active p {
    background: transparent;
    visibility: visible;
    /* color: #fff; */
  }
  #admin .navbar-header .navbar-toggle {
    background: white;
    border: 2px solid;
    border-color: #72d835 !important;
    margin: 10px 15px 10px 0;
    width: 40px;
    height: 40px;
  }
  #admin.navbar-collapse.collapse {
    height: 100% !important;
  }
  #admin .navbar-collapse.collapse.in {
    display: block;
  }
  #admin .navbar-header .collapse,
  #admin .navbar-toggle {
    display: block !important;
  }
  #admin .navbar-header {
    float: none;
  }
  #admin .navbar-collapse [class^='pe-7s-'] {
    float: left;
    font-size: 20px;
    margin-right: 10px;
  }
  #admin .navbar-header {
    float: none;
    height: 50px;
  }
  #admin #card-feedback {
    background: url(../images/admin/az.png);
    height: 100px;
    width: 100%;
    background-size: cover;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

@media (min-height: 1073px) {
  #admin .sidebar .sidebar-wrapper {
    width: 298px;
  }
}

/*************************          Changes for small display      ***********************/

#admin .hometext {
  font-family: 'Titillium-Regular';
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

/* override modal position */

.modal.in .modal-dialog {
  display: flex;
  justify-content: center;
}

/* Input focus  */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Override selection  */

::selection {
  background: #7acd17 !important;
  color: white;
}

::-moz-selection {
  background: #7acd17 !important;
  color: white;
}

.navigate-before {
  color: #c5c3c3 !important;
}

/* style applied to all modal dialog  */

.modal-dialog {
  margin: 30px auto;
  padding-bottom: 20px;
  position: relative;
  display: table;
  overflow-y: auto;
  overflow-x: auto;
  width: auto;
  min-width: 600px;
}

.modal-header .close {
  position: absolute;
  top: 17px;
  right: 20px;
  font-size: 30px;
}

@media screen and (max-width: 1024px) {
  .modal-dialog {
    min-width: unset;
  }
}

.modal-body > embed {
  min-height: 800px !important;
}
