.dargcard-time {
  color: #79d70f;
  font-weight: bold;
  margin-right: 20px;
  font-size: 16px;
  width: 8%;
}
@media only screen and (min-width: 992px) and (max-width: 1600px) {
  .dargcard-time {
    font-size: 0.85vw;
  }
}

.dargcard-Name {
  color: #79d70f;
  margin-right: 10px;
  font-size: 16px;
  width: 25%;
}
@media only screen and (min-width: 992px) and (max-width: 1600px) {
  .dargcard-Name {
    font-size: 0.9vw;
  }
}

.dargcard-partner {
  color: #767676;
  margin-right: 10px;
  font-size: 15px;
  width: 28%;
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
  .dargcard-partner {
    font-size: 0.9vw;
  }
}

.dargcard-partnerTwo {
  color: #767676;
  margin-right: 10px;
  font-size: 13px;
  width: 29%;
  text-align: center;
  margin-top: -11px;
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
  .dargcard-partnerTwo {
    font-size: 0.9vw;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 5000px) {
  .dargcard-partnerTwo {
    width: 25%;
  }
}

