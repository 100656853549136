#chat_container .inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%; border-right:1px solid #c4c4c4;
}
#chat_container .inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
#chat_container .top_spac{ margin: 20px 0 0;}


#chat_container .recent_heading {float: left; width:40%;}
#chat_container .srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
#chat_container .headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}

#chat_container .recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
#chat_container .srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
#chat_container .srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
#chat_container .srch_bar .input-group-addon { margin: 0 0 0 -27px;}

#chat_container.chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
#chat_container .chat_ib h5 span{ font-size:13px; float:right;}
#chat_container .chat_ib p{ font-size:14px; color:#989898; margin:auto}
#chat_container .chat_img {
  float: left;
  width: 11%;
}
#chat_container .chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}
#chat_container .chat_title {
  font-size: 16px !important;
  color: black !important;
  margin: auto;
}
#chat_container .chat_date {
  font-size: 9px;
  float: right;
}

#chat_container .chat_people{ overflow:hidden; clear:both; cursor: pointer;}
#chat_container .chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
#chat_container .inbox_chat { height: 550px; overflow-y: scroll;}

#chat_container .active_chat{ background:#ebebeb;}

#chat_container .incoming_msg_img {
  display: inline-block;
  width: 6%;
}
#chat_container .received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
  -moz-hyphens:auto;
  -ms-hyphens:auto;
  -webkit-hyphens:auto;
  hyphens:auto;
  word-wrap:break-word;
 }
 #chat_container .received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;

}
#chat_container .time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
#chat_container .received_withd_msg {
   width: 57%;
}
#chat_container .mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
}

#chat_container .sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0; color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}
#chat_container .outgoing_msg{  
  -moz-hyphens:auto;
  -ms-hyphens:auto;
  -webkit-hyphens:auto;
  hyphens:auto;
  word-wrap:break-word;
   overflow:hidden;
   margin:26px 25px 26px;}
#chat_container .sent_msg {
  float: right;
  width: 46%;
}
#chat_container .input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

#chat_container .type_msg {border-top: 1px solid #c4c4c4;position: relative;}
#chat_container .msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}
#chat_container .messaging { padding: 0 0 50px 0;}
#chat_container .msg_history {
  height: 516px;
  overflow-y: auto;
}