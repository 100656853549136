.to-be-loaded .button-block-field {
  position: absolute;
  width: 100%;
  bottom: 14px;
  display: flex;
  justify-content: center;
}

.form-to-be-loaded .row-to-be-loaded > div {
  height: 22%;
}

.tobeloade-title-block {
  height: 10%;
  display: flex;
  justify-content: space-between;
}

.add-eartag-block-field {
  height: 5% !important;
  bottom: 30px !important;
  width: 100%;
  text-align: right;
  padding-right: 37px;
}

.add-eartag-block-field-docs {
  height: 5% !important;
  bottom: 30px !important;
  width: 100%;
  text-align: right;
}

.flex-icons {
  display: flex;
}

.btn-signature {
  width: 50%;
}

.add-eartag-block-field.add-eartag .btn-normal {
  width: 35%;
  float: right;
}

.tourInf-title-span-texttwo {
  object-fit: contain;
  font-family: 'Titillium-Regular' !important;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  /* line-height: 2.29; */
  letter-spacing: normal;
  text-align: left;
  color: #c4c4c4 !important;
}

@media screen and (max-width: 1566px) {
  .truck-item {
    width: 220px !important;
  }
  .truck-item .person-title-container h4 {
    margin-top: 0 !important;
  }
  #trucks-list-container #to-be-loaded-trucks.to-be-loaded-panel .list-group {
    width: 100% !important;
  }
}

.truck-item .truck-name.tourInf-title-span-texttwo {
  font-size: 19px !important;
  line-height: 1.6 !important;
  font-weight: 500 !important;
  color: #ababab !important;
}

.truck-item .info-item {
  color: #a6a29f !important;
}

.tourInf-title-span-textthree {
  object-fit: contain;
  font-family: 'Titillium-Regular' !important;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: left;
  color: #eddf01 !important;
}

.tourInf-title-span-text {
  object-fit: contain;
  font-family: 'Titillium-Regular' !important;
  font-size: 18px;
  /* font-weight: bold; */
  font-style: normal;
  font-stretch: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: left;
  color: #7bd812 !important;
}

.add-eartag-button {
  width: 25% !important;
  font-size: 20px !important;
  opacity: 0.8 !important;
  color: white !important;
  padding: 0 !important;
}

.eartag-output-format {
  font-size: 18px !important;
  font-family: 'Titillium-Regular' !important;
  color: #888888 !important;
  letter-spacing: 6px !important;
  min-width: 46% !important;
  max-width: 70% !important;
  font-weight: 300 !important;
  opacity: 0.8 !important;
}

.icon-eartag:before {
  display: block;
  content: ' ';
  background-size: 25px 25px;
  width: 33px;
  height: 33px;
  background-image: url('../../../../static/images/admin/greenSearch.svg');
  cursor: pointer;
  background-repeat: no-repeat;
}

.delete-eartag:before {
  display: block;
  content: ' ';
  background-size: 20px 20px;
  width: 33px;
  height: 33px;
  background-image: url('../../../../static/images/admin/cross-out.svg');
  background-repeat: no-repeat;
}

.list-eartag-block {
  overflow-y: scroll;
  text-align: left;
  margin-top: 40px;
  max-height: 300px;
}

/* * EARTAG FORM BLOCK *
/** EARTAG BLOCK CSS **/

#admin .to-be-loaded .space-top-bottom {
  margin-top: 5px;
  margin-bottom: 5px;
}

#admin .to-be-loaded .form-control[disabled] {
  background-color: white;
  color: rgba(119, 119, 119, 0.5) !important;
}

#admin .to-be-loaded .form-controlTwo[disabled] {
  background-color: white !important;
  font-size: 26px !important;
  font-weight: 280 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: rgba(112, 112, 112, 0.75) !important;
}

#admin .to-be-loaded .user-input-wrp {
  position: relative;
}

#admin .to-be-loaded .user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #777;
}

#admin .to-be-loaded .user-input-wrp .inputText:invalid {
  box-shadow: none !important;
}

#admin .to-be-loaded .num-passpord,
#admin .to-be-loaded .before {
  color: #c5c3c3 !important;
  font-size: 35.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

#admin .to-be-loaded .user-input-wrp .inputText:focus {
  border-width: medium medium 2px;
}

#admin .to-be-loaded .user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 6px;
  left: 10px;
  font-size: 27px;
  font-weight: 600;
  transition: 0.2s ease all;
  opacity: 0.8;
  color: rgba(123, 216, 18, 0.75);
}

#admin .to-be-loaded .user-input-wrp .floating-label-topp {
  position: absolute !important;
  pointer-events: none !important;
  transition: 0.2s ease all !important;
  top: -50% !important;
  left: 10px !important;
  font-family: 'Titillium-Regular';
  font-size: 20px !important;
  font-weight: 300 !important;
  color: rgba(112, 112, 112, 0.4) !important;
  border-radius: 0px;
  padding-left: 0px;
  font-size: 20px !important;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.7;
  letter-spacing: normal;
  text-align: left;
  color: var(--brownish-grey-50);
  margin: 0 auto;
  text-align: left;
}

#admin .to-be-loaded .register-form-btn {
  width: 80% !important;
  height: 50px;
  background-color: #7bd812 !important;
  font-size: 18px;
  color: #fff !important;
  margin-top: 33px;
  text-align: center;
}

.btn-save {
  width: 100%;
  height: 100%;
  text-align: center;
}

#admin .to-be-loaded .register-form-btn:hover {
  background-color: #b6b6b6 !important;
}

#admin .to-be-loaded .search-content {
  margin-top: 15px;
}

#admin .to-be-loaded .search-content .input-group-addon svg {
  fill: #b7b7b7;
}

#admin .to-be-loaded .input-group {
  width: 100%;
}

#admin .to-be-loaded .form-control {
  height: 40px;
  text-align: center;
  font-size: 17px;
  color: rgba(151, 248, 41, 0.75);
  font-weight: 600;
  background-color: #f6f6f6;
  border: none;
  border-radius: 10px;
  margin: 0;
}

#admin .to-be-loaded .form-controlTwo {
  height: 40px !important;
  width: 90% !important;
  text-align: center !important;
  background-color: #ffffff !important;
  border: none !important;
  object-fit: contain !important;
  font-family: 'Titillium-Regular' !important;
  font-size: 23px !important;
  font-weight: 300 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: rgba(0, 0, 0) !important;
  border-radius: 0px !important;
  margin: 0 !important;
  margin-top: 10px !important;
}

#admin .to-be-loaded .form-control.box-nbr {
  background: none;
}

#admin .to-be-loaded .form-controlTwo.box-nbr {
  background: none;
}

#admin .to-be-loaded .form-control.box-nbr:focus {
  border-bottom: 1px solid rgba(123, 216, 18, 0.75);
}

#admin .to-be-loaded .form-controlTwo.box-nbr:focus {
  border-bottom: 1px solid rgba(123, 216, 18, 0.75);
}

#admin .to-be-loaded .passpord-photo-disabled::after {
  padding: 5px;
  content: url('../../../../static/images/add-passpord-disabled.jpg');
}

#admin .to-be-loaded .passpord-photo-avaible::after {
  padding: 5px;
  content: url('../../../../static/images/add-passpord.png');
}

#admin .to-be-loaded .passpord-photo-view::after {
  padding: 5px;
  content: url('../../../../static/images/view-password.jpg');
}

#admin .to-be-loaded .upload-file-button {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 109px;
  height: 114px;
}

#admin .to-be-loaded .passpord-photo {
  position: absolute;
  width: 112px;
  right: 5%;
  top: 14px;
}

#admin .to-be-loaded .list-group {
  /* height: 50% !important; */
}

#admin .to-be-loaded .with-passport {
  color: #000000;
}

#admin .to-be-loaded .btn-defaul {
  position: absolute;
  bottom: 0;
}

@media (max-width: 1300px) {
  #admin .to-be-loaded .title-greyTwo {
    margin-top: 10px !important;
  }
  #admin .to-be-loaded .TextNextBox {
    width: 100%;
  }
  #admin .to-be-loaded .form-controlTwo[disabled] {
    font-size: 21px !important;
  }
}

#admin .to-be-loaded .navigate-before-stock {
  color: #c5c3c3 !important;
  top: 13px;
  left: 33px;
}

#admin .to-be-loaded .navigate-before-stock > div svg {
  font-size: 33px;
  opacity: 0.6;
  color: #bbbbbb !important;
}

#admin .to-be-loaded .view-stock-button {
  height: 5% !important;
  position: absolute;
  bottom: 0;
  right: 32%;
  color: white !important;
}

.to-be-loaded-none {
  display: none;
}

.select-confirm {
  opacity: 0.8 !important;
}

.form-input {
  border: 0;
  padding: 4px 0;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
  font: 18px 'Titillium-Regular', sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  line-height: 1.4;
}

::selection {
  background-color: transparent !important;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-input-placeholder {
  position: absolute;
  bottom: 52%;
  right: 50%;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.2;
}

.form-input ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #7bcd10;
  transition: 0.4s;
}

.form-input:focus ~ .focus-border {
  width: 100%;
  transition: 0.4s;
}

.form-input:valid ~ .focus-border {
  width: 100%;
  transition: 0.4s;
}

.form-input:focus ~ .form-input-placeholder,
.form-input:valid ~ .form-input-placeholder {
  position: absolute;
  right: 50%;
  font-size: 23px;
  -webkit-transform: translate3d(0, -30%, 0);
  transform: translate3d(0, -30%, 0);
  opacity: 1;
  color: #7bcd10;
}

.submit-field {
  position: absolute;
  bottom: 0;
  width: 94%;
  height: 10%;
  text-align: center;
}

.to-be-loaded-container {
  height: 100%;
}

.to-be-loaded-container > div {
  height: 100%;
}

/* new fixed design for truck list  */

#to-be-loaded-truck-list.to-be-loaded-container
  .to-be-loaded-panel
  .list-group {
  height: 102px !important;
  overflow: hidden;
}

.to-be-loaded-panel .list-group.list-group-item {
  padding: 0 !important;
  height: 0 !important;
}

.to-be-loaded-panel .list-group.list-group-item > .row {
  padding: 2% 0 !important;
  height: 101px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.to-be-loaded-panel .list-group.list-group-item > .row.order-item-active {
  background: #effae3 !important;
}

.to-be-loaded-panel .list-group.list-group-item > .row .green {
  font-size: 16px !important;
}

.status-image {
  width: 50px;
  height: auto;
  object-fit: contain;
}

.info-item {
  font-size: 14px;
}

.modal-pdf {
  min-height: 855px !important;
}

.eartag-header-modal ~ .add-signature {
  height: 35%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100% !important;
}

.eartag-header-modal ~ .add-signature .text-submit {
  cursor: pointer;
}

.truck-group-container {
  display: flex;
  justify-content: center;
}

.truck-group-docs.to-be-loaded-docs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  padding-bottom: 2%;
}

.truck-group-list-eartag.to-be-loaded {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 43%;
  margin: 0 auto;
}

.truck-group-list-eartag.to-be-loaded .icon-group {
  display: flex;
}

.view-stock .view-stock-to-be-loaded {
  height: 95%;
}
