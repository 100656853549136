@import '../../css/global.scss';

#regular-input.grey {
  & .bar:before {
    display: none;
  }
  & svg path {
    fill: $input-grey !important;
  }
}

#regular-input.agrabiz-select {
  .css-2b097c-container {
    width: 100%;
    border: none;
    border-radius: 0;
  }

  &:hover {
    & .bar:before {
      width: 100%;
    }
    & label {
      top: -14px;
      font-size: 14px;
      color: $green;
    }
  }

  &.selected {
    & .css-2b097c-container {
      outline: none;
    }
    & label {
      top: -14px;
      font-size: 14px;
      color: $muted-color;
    }
  }

  & .css-140icpd-control {
    min-height: 42px !important;
    border-color: transparent transparent #fff;
  }

  & [id^='react-select-']:active {
    background-color: $green;
    color: white;
  }
  .css-bkyy73-menu {
    border-radius: 0;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.12);
  }
  .css-1hwfws3 {
    padding: 6px 2px;
  }
  .css-1hb7zxy-IndicatorsContainer svg path {
    fill: #999;
  }
}

#regular-input.agrabiz-picker {
  width: 100%;
  .group {
    margin: 0 !important;
  }
  input {
    padding: 10px 0px 5px 5px !important;
  }
}

#regular-input {
  margin: 25px 0 0 0;
}

#regular-input.agrabiz-input {
  .group {
    position: relative;
    // margin: 35px 0;
  }

  textarea {
    resize: none;
  }

  input.normal-input,
  textarea {
    background: none;
    color: $muted-color;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $muted-color;
    color: #646464;
    font-size: 16px;
    &:disabled {
      color: #b6b6b6 !important;
      border-bottom: 1px solid #eee;
    }
    &:hover {
      outline: none;
      color: hsl(0, 0%, 60%) !important;
    }
    // &:valid ~ label,
    &:hover ~ label {
      top: -14px;
      font-size: 14px;
      color: $green;
    }
    &:hover:not(:disabled) ~ .bar:before {
      width: 100%;
    }
    &:hover ~ .group-icon {
      color: $green;
    }
    &:not(:hover):not(:placeholder-shown) ~ label,
    &:disabled ~ label,
    &.disabled-select label {
      top: -14px;
      font-size: 14px;
      color: $muted-color;
    }
  }
  &.disabled-select label {
    top: -14px;
    font-size: 14px;
    color: $muted-color;
  }

  input[type='password'] {
    letter-spacing: 0.3em;
  }

  label {
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: $trans-time ease all;
    color: $muted-color;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
    &:before {
      content: '';
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: $green;
      transition: $trans-time ease all;
      left: 0%;
    }
  }

  .group-icon {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 19px;
    color: #999999;
  }

  .group-iconGreen {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 19px;
    color: $green;
  }

  & .css-151xaom-placeholder {
    display: none !important;
  }
}
