.time {
  float: left;
  color: #999;
}

.page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: white !important;
}

.page1 {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: white !important;
}

.chat {
  width: 100%;
}

/* Conversation */

.conversation {
  height: calc(100% - 12px);
  position: relative;
  z-index: 0;
}

.conversation ::-webkit-scrollbar {
  transition: all 0.5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  height: calc(100% - 68px);
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 19px;
}

.conversation .conversation-container:after {
  content: '';
  display: table;
  clear: both;
}

/* Messages */

.message {
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  z-index: -1;
}

.message:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
  display: inline-block;
}

.message:first-child {
  margin: 16px 0 8px;
}

.message.received {
  background: #f4f4f4;
  color: #999999;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.message.received .metadata {
  padding: 0 0 0 16px;
}

.message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.message.sent {
  background: #7bd812;
  color: #ffffff;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

/* Compose */

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 40px;
  width: 100%;
  z-index: 2;
  margin-bottom: 1.5%;
}

.conversation-compose div,
.conversation-compose input {
  background: #fff;
  height: 100%;
}

.conversation-compose .input-msg {
  background-color: #f4f4f4;
  border: 0;
  flex: 1 1 auto;
  font-size: 14px;
  margin: 0;
  outline: none;
  min-width: 50px;
  height: 36px;
  border-radius: 15px 0px 0px 15px;
  padding-left: 3%;
  color: black;
}

.conversation ::placeholder {
  color: #b3ada7;
}

.conversation-compose .photo {
  flex: 0 0 auto;
  border-radius: 0 30px 30px 0;
  text-align: center;
  width: auto;
  display: flex;
  padding-right: 6px;
  height: 36px;
  background-color: #f4f4f4;
}

.conversation-compose .photo img {
  display: block;
  color: #7d8488;
  font-size: 24px;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  margin-left: 10px;
  width: 25px;
  height: 25px;
}

.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none;
  margin-left: 0.5rem;
}

.conversation-compose .send .circle {
  position: relative;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation-compose .send .circle img {
  font-size: 24px;
  margin-left: 1px;
}

/* Small Screens */

@media (max-width: 768px) {
  .conversation {
    height: calc(100vh - 55px);
  }
  .conversation .conversation-container {
    height: calc(100vh - 120px);
  }
}
