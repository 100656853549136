.confirm-move-modal {
    & .modal-content {
      width: 100%;
    }
    & .cancel-btn {
      border: 1px solid #b9b8b8;
      color: #737373;
      border-radius: 4px;
      padding: 2px 10px;
    }
    & .confirm-btn {
      margin-left: 2%;
      padding: 2px 10px;
      color: white;
      background: #737373;
      border-radius: 4px;
    }
    & .modal-footer {
      border-top: none;
    }
  }
  