#admin .reports-abattoir .list-report {
  margin-top: 0%;
  margin-bottom: 5%;
  overflow-y: scroll;
}
#admin .reports-abattoir .list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  width: 90%;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  text-align: left;
  margin-bottom: 0px !important;
}

#admin .reports-abattoir .list-group-item {
  cursor: pointer;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  color: #8c8c8c;
}

#admin .reports-abattoir {
  height: 100%;
}

#admin .reports-abattoir .card-body .row {
  font-size: 16px;
}

#admin .reports-abattoir .card-body .list-report.list-group {
  height: 90%;
}

#admin .reports-abattoir .card-body {
  height: 100%;
}

#admin .reports-abattoir .card-body .dropzone {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#admin .reports-abattoir .card-box {
  height: 95%;
}

#admin .green-status {
  color: #7ad810;
  font-size: 14px;
}
