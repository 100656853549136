#admin .messages .card-box {
  height: 95%;
}

#admin .messages .list-group-item {
  position: relative;
  display: block;
  padding: 0;
  /* width: 100%; */
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  text-align: left;
  margin-bottom: 0px !important;
}

@media screen and (max-width: 1400px) {
  .Notifexample {
    font-size: 12px !important;
  }
}

.message-box-br {
  max-height: 400px !important;
  overflow-y: scroll !important;
}

.flexGen {
  padding: 12px 10px;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  width: 110% !important;
  height: 100% !important;
}

.messages .notif-messages-header {
  display: flex;
  justify-content: flex-end;
  padding: 4% 2%;
}

.messages .notif-messages-header .card-body {
  padding: 15px 0 !important;
}

#admin .imageSize {
  width: 50px !important;
  height: 50px !important;
}
.flexGen-block1 {
  width: 10%;
  height: 100%;
}

.flex-text {
  width: 10%;
  height: 100%;
}

.flexGen-block2 {
  margin-left: 30px;
  width: 58%;
  height: 100%;
}

.flexGen-block3 {
  width: 32%;
  height: 100%;
  padding-right: 6%;
}
#admin .messages hr.style-border {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.22),
    rgba(0, 0, 0, 0)
  );
}

#admin .messages .list-group-item {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  #admin .messages .list-group {
    height: 40%;
    margin-bottom: 10%;
  }
}

#admin .messages .text-grey {
  font-size: 20px;
  color: #626262;
}

#admin .messages .header-notifications {
  margin-top: 30px;
  margin-bottom: 42px;
}
#admin .messages .disabled {
  opacity: 0.5;
}

#admin .messages .date {
  font-size: 12px;
}

/* #admin .messages .sender {
  padding-left: 12%;
} */

.messages ::-webkit-scrollbar-thumb {
  background: #7bd812 !important;
}
/* height  */

.messages.col-md-6,
.messages.row {
  height: 100%;
}

.messages .card-body {
  height: 90%;
}

.messages .card-body .row {
  height: 100%;
}
.messages .card-body .messages-list {
  margin: 0 !important;
  max-height: 100% !important;
  height: 100%;
  padding-right: 0px !important;
}

.messages .message-col {
  height: 100%;
}

.messages .orange {
  cursor: pointer;
}

.tab-SEPA-Lastschriftsmandat {
  text-align: center;
  font-weight: bold;
  color: #767676;
  cursor: pointer;
}

.tab-SEPA-Lastschriftsmandat-pay {
  font-weight: bold;
  color: #767676;
  cursor: pointer;
}


.tab-SEPA-Las-green {
  text-align: center;
  font-weight: bold;
  font-family: 'Titillium-Regular' !important;
  font-size: 18px !important;
  color: #7bd812 !important;
}
.tab-SEPA-Las-green-pay {
  width: 100%;
  font-weight: bold;
  font-family: 'Titillium-Regular' !important;
  font-size: 18px !important;
  color: #7bd812 !important;
}
.SEPA-Lastschriftsmandat-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}
.SEPA-Lastschriftsmandat-flex-pay {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* justify-content: space-around; */
  /* align-items: center; */
  /* align-content: center; */
  width: 100%;
  margin-left: 100px;
}

.SEPA-Lastschriftsmandat-flex-pay-b2 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* justify-content: space-around; */
  /* align-items: center; */
  /* align-content: center; */
  width: 70%;
  margin-left: 100px;
  margin-top: 62px;
}

.SEPA-Lastschriftsmandat-flex-loaded {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 80%;
  height: 90px;
}
.SEPA-Lastschriftsmandat-flex-loaded-pay {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  /* align-content: center; */
  width: 63%;
  /* height: 90px; */
}
.SEPA-text {
  font-family: 'Titillium-Regular' !important;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #767676;
}

.SEPA-text-pay {
  margin-left: 20px;
  margin-right: 88px;
  margin-bottom: 14px;
}

.SEPA-Lastschriftsmandat-flex-b1 {
  width: 60%;
}

.SEPA-Lastschriftsmandat-flex-b2 {
  width: 10%;
  
}

.SEPA-contact {
  margin-top: 120px;
  width: 80%;
}