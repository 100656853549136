@import '../../../css/global.scss';

button {
  font-family: 'Titillium-Regular';
}

#toggle-dropup {
  &.dropdown-width {
    & .dropup {
      width: 100% !important;
      & .dropdown-toggle {
        background: #7ad810 !important;
      }
      & .iconiz-green {
        color: white !important;
      }
    }
  }
  &.green-color {
    & .btn-title {
      color: white !important;
    }
  }
  &.toggle-menu-btn {
    display: flex;
    justify-content: center;
    width: 100%;
    &.toggle-menu-btn-deutsch {
      & .dropup {
      }
    }
    & .dropup {
      width: 100% !important;
      display: flex;
      justify-content: center;
      & .dropdown-toggle {
        width: 100%;
        border: none;
        box-shadow: $button-shadow;
        font-size: 18px;
        background: white;
        opacity: 1;
      }

      & .dropdown-toggle:hover,
      & .dropdown-toggle:focus {
        color: #333;
        background-color: #d4d4d4;
      }

      & .dropdown-menu {
        -webkit-transition: height 2s;
        transition: height 2s;
        width: 100%;
        & .dropdown-item {
          border-bottom: 0.1rem $border-grey solid;
        }
        & .dropdown-item:last-child {
          border: none;
        }
        & a.dropdown-item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          & img {
            margin: 0 8% 0 3% !important;
          }
          & .icon-text {
            font-size: 16px;
            margin: 0 4% 0 0%;
            color: #7bcb16;
          }

          & .disabled .icon-text {
            color: grey !important;
          }

          & .selected-eartag-number {
            color: #7bd811;
            font-size: 17px;
            padding-right: 3%;
          }

          & .menu-item-text {
            font-size: 15px;
          }
          &:hover {
            background: #e1e1e1;
            color: #000;
          }
          &.disabled {
            cursor: not-allowed;
            & svg path {
              fill: #999;
            }
            & .icon-text {
              color: #999;
            }
          }
        }
      }
      & .dropdown-menu.show {
        & .dropdown-item {
          display: flex;
          padding: 6px 12px;
          color: #767676;
          font-size: 16px;
          & svg {
            width: 21px;
            height: 23px;
          }
        }
      }
    }
    & .btn-content {
      display: flex;
      justify-content: center;
      & .iconiz {
        margin-right: 5% !important;
        padding: 0;
      }
      & .svg-iconiz {
        margin-right: 5% !important;
      }
      & .btn-title {
        color: $dark-grey;
      }
    }
    & .caret {
      display: none;
    }
  }

  &.toggle-menu-btn {
    & .svg-iconiz {
      margin-right: 5% !important;
    }
  }

  &.toggle-menu-btn.select-number {
    & .dropdown-menu .dropdown-item:first-child {
      justify-content: flex-end;
      padding-right: 6%;

      &:hover {
        background-color: white;
      }
    }
  }
}
