.non-supported-screen {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  & .screen-container {
    width: 70%;
    padding: 3%;
    border: 1px dashed #908f8f;
    display: flex;
    flex-direction: column;
    align-items: center;
    & .agrabiz-logo {
      width: 45%;
      padding-bottom: 4%;
    }
    & .first-message {
      font-size: 25px;
      color: #7c7c7c;
      text-align: center;
      font-weight: 500;
      padding-bottom: 3%;
    }
    & .second-message {
      font-size: 25px;
      color: #7bd811;
      text-align: center;
      font-weight: 500;
    }
  }
  @media (max-width: 991px) {
    & .screen-container {
      width: 80%;
      & .agrabiz-logo {
        width: 50%;
      }
    }
  }
}
