/*!
Template :	AppsLand - App Landing Template
Author	 :	Softnio
Version	 :	1.2.0
Updated	 :	02.25.2018
*/
/*! 
 * IMPORTANT NOTE: DO NOT Edit this file. 
 * Best to write own code in css/theme.css file. 
 **/
:focus,
a:focus,
img {
  outline: 0
}

a:focus,
a:hover,
ins {
  text-decoration: none
}

.form-control,
a {
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease
}

.font-primary,
body,
p.lead {
  font-family: 'Open Sans', sans-serif
}

.box h4,
.box h5,
.button-uppercase,
.nav>li>a.button {
  text-transform: uppercase
}

a,
abbr,
acronym,
address,
applet,
b,
big,
blockquote,
body,
caption,
center,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
u,
ul,
var {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: 0 0
}

img {
  margin: 0;
  padding: 0;
  border: 0;
  background: 0 0;
  max-width: 100%
}

.form-control,
.white-bg {
  background: #fff
}

ol,
ul {
  list-style: none
}

blockquote,
q {
  quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none
}

del {
  text-decoration: line-through
}

.panel-title>a,
a {
  text-decoration: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

a {
  transition: all .5s ease
}

::-webkit-input-placeholder {
  opacity: 1
}

:-moz-placeholder {
  opacity: 1
}

::-moz-placeholder {
  opacity: 1
}

:-ms-input-placeholder {
  opacity: 1
}

@font-face {
  font-family: Quicksand;
  src: url(../fonts/Quicksand-Regular.eot);
  src: local('Quicksand Regular'), local('Quicksand-Regular') url(../fonts/Quicksand-Regular.eot?#iefix) format('embedded-opentype'), url(../fonts/Quicksand-Regular.woff2) format('woff2'), url(../fonts/Quicksand-Regular.woff) format('woff');
  font-weight: 400
}

@font-face {
  font-family: Quicksand;
  src: url(../fonts/Quicksand-Medium.eot);
  src: local('Quicksand Medium'), local('Quicksand-Medium'), url(../fonts/Quicksand-Medium.eot?#iefix) format('embedded-opentype'), url(../fonts/Quicksand-Medium.woff2) format('woff2'), url(../fonts/Quicksand-Medium.woff) format('woff');
  font-weight: 500
}

@font-face {
  font-family: Quicksand;
  src: url(../fonts/Quicksand-Light.eot);
  src: local('Quicksand Light'), local('Quicksand-Light'), url(../fonts/Quicksand-Light.eot?#iefix) format('embedded-opentype'), url(../fonts/Quicksand-Light.woff2) format('woff2'), url(../fonts/Quicksand-Light.woff) format('woff');
  font-weight: 300
}

@font-face {
  font-family: Quicksand;
  src: url(../fonts/Quicksand-Bold.eot);
  src: local('Quicksand Bold'), local('Quicksand-Bold'), url(../fonts/Quicksand-Bold.eot?#iefix) format('embedded-opentype'), url(../fonts/Quicksand-Bold.woff2) format('woff2'), url(../fonts/Quicksand-Bold.woff) format('woff');
  font-weight: 700
}

@font-face {
  font-family: 'Open Sans';
  src: url(../fonts/OpenSans-Light.eot);
  src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/OpenSans-Light.eot?#iefix) format('embedded-opentype'), url(../fonts/OpenSans-Light.woff2) format('woff2'), url(../fonts/OpenSans-Light.woff) format('woff');
  font-weight: 300
}

@font-face {
  font-family: 'Open Sans';
  src: url(../fonts/OpenSans-Regular.eot);
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(../fonts/OpenSans-Regular.eot?#iefix) format('embedded-opentype'), url(../fonts/OpenSans-Regular.woff2) format('woff2'), url(../fonts/OpenSans-Regular.woff) format('woff');
  font-weight: 400
}

body {
  font-size: 16px;
  line-height: 1.7;
  letter-spacing: .01em;
  font-weight: 300;
  color: #333
}

.font-secondary,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Quicksand, sans-serif
}

blockquote,
ol,
p,
table,
ul {
  margin-bottom: 18px
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 28px;
  color: #666
}

.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child,
blockquote:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
ol:last-child,
p:last-child,
table:last-child,
ul:last-child {
  margin-bottom: 0
}

.h1,
h1 {
  font-size: 3.125em;
  line-height: 1.35
}

.h2,
h2 {
  font-size: 2.5em
}

.h3,
h3 {
  font-size: 1.375em
}

.h4,
h4 {
  font-size: 1.125em
}

.h5,
h5 {
  font-size: 1.875em
}

.h6,
h6 {
  font-size: 1.265em
}

p.lead {
  font-size: 1.375em;
  line-height: 1.7
}

blockquote {
  font-size: .9375em;
  line-height: 1.5
}

@media only screen and (max-width:767px) {
  body {
    font-size: 14px
  }

  .h1,
  h1 {
    font-size: 2em
  }

  .h2,
  h2 {
    font-size: 1.7em
  }

  p {
    font-weight: 400
  }
}

.no-padding {
  padding: 0
}

.no-margin {
  margin: 0
}

.gutter-10 {
  padding-left: 10px;
  padding-right: 10px
}

.fix-gutter-10 {
  margin-left: -10px;
  margin-right: -10px
}

.pb-10 {
  padding-bottom: 10px
}

.pt-10 {
  padding-top: 10px
}

.pb-20 {
  padding-bottom: 20px
}

.pt-20 {
  padding-top: 20px
}

.pb-30 {
  padding-bottom: 30px
}

.pt-30 {
  padding-top: 30px
}

.pb-40 {
  padding-bottom: 40px
}

.pt-40 {
  padding-top: 40px
}

.pb-50 {
  padding-bottom: 50px
}

.pt-50 {
  padding-top: 50px
}

.pb-60 {
  padding-bottom: 60px
}

.pt-60 {
  padding-top: 60px
}

.pb-80 {
  padding-bottom: 80px
}

.pt-70 {
  padding-top: 70px
}

.pb-70 {
  padding-bottom: 70px
}

.pt-80 {
  padding-bottom: 80px
}

.pb-90 {
  padding-bottom: 90px
}

.pt-90 {
  padding-top: 90px
}

.pb-100 {
  padding-bottom: 100px
}

.pt-100 {
  padding-top: 100px
}

.pb-120 {
  padding-bottom: 120px
}

.pt-120 {
  padding-top: 120px
}

.ptb-120 {
  padding: 120px 0
}

.form-group {
  margin-bottom: 0px
}

.form-group::-webkit-scrollbar {
  width: 1em;
}

.form-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.form-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.form-control {
  height: 50px;
  padding: 13px 15px;
  color: #666;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: none;
  transition: all .5s ease
}

.button,
.owl-theme .owl-dots .owl-dot span:after {
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease
}

.form-control.error {
  border: 1px solid red
}

.txtarea.form-control {
  height: 85px
}

.form-control:focus {
  border-color: #cecece;
  box-shadow: none
}

label.error {
  display: none !important
}

.button,
.buttons li,
.download-buttons li {
  display: inline-block
}

.relative,
.section {
  position: relative
}

.button {
  font-size: 1em;
  font-weight: 700;
  line-height: 3.125;
  border: none;
  border-radius: 25px;
  padding: 0 40px;
  color: #fff;
  text-align: center;
  background: #e0486e;
  transition: all .5s ease
}

.button-border {
  line-height: 3;
  border: 1px solid #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.theme-pro .button-border {
  border: 1px solid #333
}

.theme-pro .button.button-border {
  color: #333
}

.button-transparent {
  background: 0 0
}

.button:focus,
.button:hover {
  color: #fff;
  background: #000
}

.button-border:focus,
.button-border:hover {
  border-color: #000
}

.buttons {
  margin: 0 -10px
}

.buttons li {
  margin: 0 10px 20px
}

.download-buttons {
  padding-top: 15px;
  padding-bottom: 20px;
  margin: 0 -5px
}

.download-buttons li {
  margin: 0 5px 10px
}

.download-buttons img {
  max-height: 50px;
  width: auto !important
}

.heading span {
  color: #e0486e
}

.heading-light {
  color: #fff
}

.theme-pro .heading-light {
  color: #333
}

.theme-pro .has-bg-image .heading-light {
  color: #fff
}

.section-head {
  padding: 109px 0 47px
}

.side-heading {
  margin-bottom: 50px
}

.side-heading a:hover {
  border-color: transparent
}

.theme-pro .has-bg-image>.gradiant-background,
.theme-pro .has-bg-video>.gradiant-background,
.theme-pro .section.gradiant-background {
  background: #ecf4fd
}

.gradiant-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: .45
}

.gradiant-light {
  opacity: .25
}

.header-section .gradiant-overlay {
  opacity: .65
}

.grey-background {
  background: #fbfbfb
}

.box {
  padding: 30px 0 24px;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 10px;
  -moz-box-shadow: 0 0 15px 0 rgba(2, 3, 3, .1);
  -webkit-box-shadow: 0 0 15px 0 rgba(2, 3, 3, .1);
  box-shadow: 0 0 15px 0 rgba(2, 3, 3, .1);
  cursor: pointer;
}

.box-small {
  padding: 20px 0 14px
}

.box h4 {
  padding-top: 15px
}

.box h5 {
  color: #333;
  padding-top: 12px
}

.box h5 span {
  display: block;
  font-size: .53em;
  letter-spacing: .02em;
  color: #666;
  padding-top: 4px
}

.box-icon {
  height: 70px;
  width: 70px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #35096b;
  background-image: -o-linear-gradient(157deg, #ed355b 0, #1f65db 100%);
  background-image: linear-gradient(293deg, #ed355b 0, #1f65db 100%)
}

.box-icon .fa,
.box-icon .ti {
  width: 66px;
  line-height: 66px;
  border-radius: 50%;
  margin-top: 2px;
  font-size: 2.25em;
  color: rgba(212, 68, 99, .9);
  background: #fff;
  display: inline-block
}

.box-icon-small .fa,
.box-icon-small .ti {
  font-size: 1.2em
}

.txt-entry {
  margin-bottom: 30px
}

.txt-entry h3 {
  margin-bottom: 22px
}

.txt-quote {
  border-top: 1px solid #ececec;
  margin-top: 30px
}

.txt-quote blockquote {
  padding: 30px 0 0;
  font-style: italic;
  font-weight: 500;
  opacity: .8
}

.txt-quote .quote-title {
  font-weight: 700
}

.video {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px
}

.video img {
  width: 100%
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: .8;
  border-radius: 8px
}

.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 80px;
  width: 80px;
  margin-top: -40px;
  margin-left: -40px;
  border: 1px solid rgba(0, 0, 0, .1);
  color: rgba(212, 68, 99, .9);
  background: rgba(246, 246, 246, .75);
  border-radius: 50%;
  text-align: center;
  font-size: 2.25em
}

.video-play:focus,
.video-play:hover {
  color: rgba(212, 68, 99, 1);
  background: #fff
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px;
  background: #d0d0d0;
  border-radius: 0;
  position: relative
}

.owl-theme .owl-dots .owl-dot span:after {
  position: absolute;
  border: 1px solid #ed355b;
  top: -2px;
  left: -2px;
  content: "";
  height: 14px;
  width: 14px;
  transition: all .5s ease;
  opacity: 0
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #ed355b
}

.owl-theme .owl-dots .owl-dot.active span:after,
.owl-theme .owl-dots .owl-dot:hover span:after {
  opacity: 1
}

.owl-theme .owl-dots {
  position: relative;
  top: 40px;
  z-index: 99
}

.owl-theme .owl-nav [class*=owl-] {
  background: #fff;
  color: #e0486e;
  margin: 5px 40px
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #fff;
  color: #e0486e
}

#status,
.imagebg {
  background-repeat: no-repeat
}

.owl-nav .owl-next,
.owl-nav .owl-prev {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  text-align: center;
  opacity: 1;
  font-size: 20px;
  z-index: 999;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease
}

.owl-nav .owl-next .fa,
.owl-nav .owl-prev .fa {
  font-size: 1.5em;
  line-height: 1.7
}

.owl-nav .owl-prev {
  left: 0
}

.owl-nav .owl-next {
  right: 0
}

.owl-nav .owl-prev:hover {
  left: -10px
}

.owl-nav .owl-next:hover {
  right: -10px
}

.slide-screen.owl-theme .owl-dots {
  top: 40px
}

.imagebg {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  z-index: 0;
  opacity: 0;
  transition: opacity .4s
}

.fixed-bg .imagebg {
  background-attachment: fixed
}

.imagebg img {
  display: none
}

.image-on-left .imagebg,
.image-on-right .imagebg {
  width: 50%
}

.image-on-left .imagebg {
  right: 50%
}

.image-on-right .imagebg {
  left: 50%
}

.image-slice .imagebg {
  width: 40%;
  left: 0
}

.image-slice .imagebg+.imagebg {
  width: 60%;
  left: 40%
}

.bg-image-loaded {
  opacity: 1
}

.animate-bottom,
.animate-left,
.animate-right,
.animate-scale-in,
.animate-scale-out,
.animate-top {
  position: relative;
  opacity: 0;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease
}

.delay1ms {
  -webkit-transition-delay: .1s;
  -moz-transition-delay: .1s;
  transition-delay: .1s
}

.delay2ms {
  -webkit-transition-delay: .2s;
  -moz-transition-delay: .2s;
  transition-delay: .2s
}

.delay3ms {
  -webkit-transition-delay: .3s;
  -moz-transition-delay: .3s;
  transition-delay: .3s
}

.delay4ms {
  -webkit-transition-delay: .4s;
  -moz-transition-delay: .4s;
  transition-delay: .4s
}

.delay5ms {
  -webkit-transition-delay: .5s;
  -moz-transition-delay: .5s;
  transition-delay: .5s
}

.delay6ms {
  -webkit-transition-delay: .6s;
  -moz-transition-delay: .6s;
  transition-delay: .6s
}

.delay7ms {
  -webkit-transition-delay: .7s;
  -moz-transition-delay: .7s;
  transition-delay: .7s
}

.delay8ms {
  -webkit-transition-delay: .8s;
  -moz-transition-delay: .8s;
  transition-delay: .8s
}

.delay9ms {
  -webkit-transition-delay: .9s;
  -moz-transition-delay: .9s;
  transition-delay: .9s
}

.delay10ms {
  -webkit-transition-delay: 1s;
  -moz-transition-delay: 1s;
  transition-delay: 1s
}

.delay11ms {
  -webkit-transition-delay: 1.1s;
  -moz-transition-delay: 1.1s;
  transition-delay: 1.1s
}

.delay12ms {
  -webkit-transition-delay: 1.2s;
  -moz-transition-delay: 1.2s;
  transition-delay: 1.2s
}

.delay13ms {
  -webkit-transition-delay: 1.3s;
  -moz-transition-delay: 1.3s;
  transition-delay: 1.3s
}

.delay14ms {
  -webkit-transition-delay: 1.4s;
  -moz-transition-delay: 1.4s;
  transition-delay: 1.4s
}

.delay15ms {
  -webkit-transition-delay: 1.5s;
  -moz-transition-delay: 1.5s;
  transition-delay: 1.5s
}

.animate-top {
  top: -30px
}

.animate-top.animate-long {
  top: -120px
}

.animate-bottom {
  bottom: -30px
}

.animate-bottom.animate-long {
  bottom: -120px
}

.animate-left {
  left: -30px
}

.animate-left.animate-long {
  left: -120px
}

.animate-right {
  right: -30px
}

.animate-right.animate-long {
  right: -120px
}

.animate-scale-out {
  transform: scale(.8)
}

.animate-scale-in {
  transform: scale(1.3)
}

.active .animate-bottom,
.active .animate-left,
.active .animate-right,
.active .animate-scale-in,
.active .animate-scale-out,
.active .animate-top {
  opacity: 1
}

.active .animate-top {
  top: 0
}

.active .animate-bottom {
  bottom: 0
}

.active .animate-left {
  left: 0
}

.active .animate-right {
  right: 0
}

.active .animate-scale-in,
.active .animate-scale-out {
  transform: scale(1)
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999999
}

#status {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url(../images/loading.gif);
  background-position: center;
  background-size: 38px auto;
  margin: -50px 0 0 -50px
}

.panel-group .panel {
  border-radius: 0;
  box-shadow: none;
  border: none
}

.panel-default>.panel-heading {
  padding: 0;
  border-radius: 0;
  color: #212121;
  background-color: transparent;
  border: 0
}

.panel-title>a {
  display: block;
  padding: 15px
}

.panel-default>.panel-heading+.panel-collapse>.card-body {
  border-top-color: transparent
}

.accordion .panel {
  background-color: transparent
}

.accordion .panel:last-child {
  border-bottom: 0 none
}

.accordion .panel+.panel {
  margin-top: 0
}

.accordion .panel-title {
  position: relative;
  font-size: 1em
}

.accordion .panel-title a {
  color: #333;
  padding: 11px 30px 11px 0
}

.accordion .panel-title a .plus-minus {
  opacity: .4
}

.accordion .panel-title a.collapsed {
  color: #666
}

.accordion .panel-title a.collapsed:hover {
  opacity: .7
}

.accordion .panel-title a.collapsed:hover .plus-minus {
  opacity: .4
}

.accordion .panel-title a.collapsed .plus-minus {
  opacity: .7
}

.accordion .panel-title a.collapsed .plus-minus span:before {
  transform: rotate(-180deg)
}

.accordion .panel-title a.collapsed .plus-minus span:after {
  transform: rotate(0)
}

.accordion .panel-title a:hover,
.accordion .panel-title a:hover .plus-minus {
  opacity: 1
}

.accordion .card-body {
  padding: 5px 0 20px
}

.accordion .plus-minus {
  opacity: .8;
  cursor: pointer;
  transition: opacity 350ms;
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  height: 18px;
  width: 18px;
  margin-top: -9px
}

.accordion .plus-minus span {
  position: relative;
  height: 100%;
  display: block
}

.accordion .plus-minus span:after,
.accordion .plus-minus span:before {
  content: "";
  position: absolute;
  background: #666;
  transition: transform .1s
}

.accordion .plus-minus span:before {
  height: 2px;
  width: 100%;
  left: 0;
  top: 50%;
  margin-top: -1px;
  transform: rotate(180deg)
}

.accordion .plus-minus span:after {
  height: 100%;
  width: 2px;
  top: 0;
  left: 50%;
  margin-left: -1px;
  transform: rotate(90deg)
}

.accordion .plus-minus:hover {
  opacity: .3
}

.accordion.filled .panel:last-child {
  border-bottom: 0
}

.accordion.filled .panel-title {
  background-color: #f5f5f5
}

.accordion.filled .panel-title a {
  background: #ececec;
  padding-left: 20px;
  color: #009750
}

.accordion.filled .panel-title a.collapsed {
  background-color: transparent;
  color: #747d88
}

.accordion.filled .panel-title a.collapsed:hover {
  opacity: .7
}

.accordion.filled .card-body {
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px
}

.accordion.filled .plus-minus {
  right: 20px
}

.panel-group:last-child {
  margin-bottom: 0
}

.text-slider .owl-nav .owl-next,
.text-slider .owl-nav .owl-prev {
  top: 130%
}

.overflowvisible {
  overflow: visible !important
}

.row-vm {
  display: flex;
  align-items: center
}

.row-vm.reverses>div:last-child {
  order: 1
}

.row-vm.reverses>div:first-child {
  order: 2
}

.primary-bg {
  background: #1F65DB
}

.secondary-bg {
  background: #ED355B
}

@media only screen and (max-width:991px) {
  .row-vm {
    display: block
  }

  .pb-90 {
    padding-bottom: 40px
  }

  .pt-90 {
    padding-top: 40px
  }

  .pb-80,
  .pt-80 {
    padding-bottom: 30px
  }

  .pb-70,
  .pt-70 {
    padding-bottom: 25px
  }

  .pt-60 {
    padding-top: 30px
  }

  .pb-60 {
    padding-bottom: 30px
  }

  .pb-100 {
    padding-bottom: 50px
  }

  .pt-100 {
    padding-top: 50px
  }

  .pb-120 {
    padding-bottom: 60px
  }

  .pt-120 {
    padding-top: 60px
  }

  .ptb-120 {
    padding: 60px 0
  }

  .ptm-30 {
    padding-top: 30px
  }

  .pbm-30 {
    padding-bottom: 30px
  }

  .ptm-20 {
    padding-top: 20px
  }

  .pbm-20 {
    padding-bottom: 20px
  }

  .ptm-10 {
    padding-top: 10px
  }

  .pbm-10 {
    padding-bottom: 10px
  }

  .ptm-0 {
    padding-top: 0
  }

  .pbm-0 {
    padding-bottom: 0
  }
}

@media only screen and (max-width:767px) {
  .form-m-bttm {
    margin-bottom: 10px
  }

  .section-head {
    padding: 60px 0 37px
  }

  .button {
    padding: 0 25px
  }

  .buttons {
    margin: 0 -5px
  }

  .buttons li {
    margin: 0 5px 20px
  }

  .pb-90 {
    padding-bottom: 40px
  }

  .pt-90 {
    padding-top: 40px
  }

  .pb-80,
  .pt-80 {
    padding-bottom: 30px
  }

  .pb-70,
  .pt-70 {
    padding-bottom: 25px
  }

  .pt-60 {
    padding-top: 30px
  }

  .pb-60 {
    padding-bottom: 30px
  }

  .pb-100 {
    padding-bottom: 50px
  }

  .pt-100 {
    padding-top: 50px
  }

  .pb-120 {
    padding-bottom: 60px
  }

  .pt-120 {
    padding-top: 60px
  }

  .ptb-120 {
    padding: 60px 0
  }

  .ptm-30 {
    padding-top: 30px
  }

  .pbm-30 {
    padding-bottom: 30px
  }

  .ptm-20 {
    padding-top: 20px
  }

  .pbm-20 {
    padding-bottom: 20px
  }

  .ptm-10 {
    padding-top: 10px
  }

  .pbm-10 {
    padding-bottom: 10px
  }

  .ptm-0 {
    padding-top: 0
  }

  .pbm-0 {
    padding-bottom: 0
  }

  .box {
    max-width: 320px;
    margin: 0 auto 30px
  }

  .tab-fix {
    max-width: 480px;
    margin: 0 auto
  }

  .owl-nav .owl-next .fa,
  .owl-nav .owl-prev .fa {
    line-height: 1.3
  }

  .owl-theme .owl-nav [class*=owl-] {
    margin: 5px 40px;
    height: 35px;
    width: 35px
  }

  .text-slider .owl-nav .owl-next,
  .text-slider .owl-nav .owl-prev {
    top: 125%
  }

  .download-buttons {
    padding-top: 0;
    margin-top: -10px
  }

  .download-buttons li {
    max-width: 125px
  }
}

@media only screen and (max-width:375px) {
  .button {
    min-width: 170px
  }
}

.navigation {
  padding: 29px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease
}

.affix {
  padding: 14px 0;
  background: #fff;
  z-index: 99999;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .2)
}

.navbar-default {
  background-color: transparent
}

.navbar {
  position: relative;
  min-height: 40px;
  margin-bottom: 0;
  border: none;
  border-radius: 0
}

.navbar-brand {
  padding: 0 15px;
  height: auto
}

.navbar-brand img {
  max-height: 50px
}

.navbar-default .navbar-toggle .icon-bar,
.navbar-default .navbar-toggle:hover .icon-bar {
  background: #fff
}

.navbar-default .navbar-toggle {
  border-color: #fff;
  transition: all .5s ease
}

.affix .navbar-default .navbar-toggle,
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: #e0486e;
  border-color: #e0486e
}

.nav>li>a {
  padding: 15px 0;
  margin: 0 15px
}

.nav>li>a.button {
  padding: 13px 25px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif
}

.nav>li>a.button:after {
  display: none
}

.navbar-nav>li>a {
  font-weight: 400;
  font-size: .9375em;
  position: relative
}

.is-transparent .navbar-default .navbar-nav>li>a {
  color: #fff
}

.affix .navbar-default .navbar-nav>li>a,
.navbar-default .in .navbar-nav>li>a {
  color: #333
}

/* .navbar-nav>li>a:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  content: "";
  background: #fff;
  transform: scale(0);
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease
} */

.affix .navbar-nav>li>a:after {
  bottom: 2px
}

.is-transparent .navbar-default .navbar-nav>li>a:after {
  background: #fff
}

/* .affix .navbar-default .navbar-nav>li>a:after,
.navbar-default .in .navbar-nav>li>a:after,
.navbar-default .navbar-nav>li>a:after {
  background: #333
} */

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
  color: #fff;
  background-color: transparent
}

.affix .navbar-default .navbar-nav>.active>a,
.affix .navbar-default .navbar-nav>.active>a:focus,
.affix .navbar-default .navbar-nav>.active>a:hover,
.affix .navbar-default .navbar-nav>li>a:focus,
.affix .navbar-default .navbar-nav>li>a:hover,
.navbar-default .in .navbar-nav>li>a:focus,
.navbar-default .in .navbar-nav>li>a:hover {
  color: #333
}

.navbar-default .navbar-nav>.active>a:after,
.navbar-default .navbar-nav>.active>a:focus:after,
.navbar-default .navbar-nav>.active>a:hover:after,
.navbar-default .navbar-nav>li>a:focus:after,
.navbar-default .navbar-nav>li>a:hover:after {
  transform: scale(1)
}

.navbar-default .navbar-nav>li>a.button,
.navbar-default .navbar-nav>li>a.button:focus {
  border: 2px solid #FA3821;
  background: #FA3821;
  color: #fff
}

.navbar-default .navbar-nav>li>a.button:hover {
  background: #fff;
  color: #FA3821;
  border: 2px solid #fff
}

.affix .navbar-default .navbar-nav>li>a.button {
  color: #fff
}

.affix .navbar-default .navbar-nav>li.active>a.button,
.affix .navbar-default .navbar-nav>li.active>a.button:focus,
.affix .navbar-default .navbar-nav>li>a.button:hover {
  color: #FA3821;
  border-color: #FA3821;
  background: #fff
}

.navbar-collapse {
  border: none;
  box-shadow: none
}

.is-transparent .navbar-brand .logo-light {
  display: block
}

.affix .navbar-brand .logo-color {
  display: block
}

.theme-pro .is-transparent .navbar-brand .logo-light {
  display: none
}

.theme-pro .is-transparent .navbar-brand .logo-color {
  display: block
}

.theme-pro .dropdown:after,
.theme-pro .navbar-default .navbar-nav>li.active>a,
.theme-pro .navbar-default .navbar-nav>li>a {
  color: #333
}

.theme-pro .navbar-default .navbar-nav>li.active>a:after,
.theme-pro .navbar-default .navbar-nav>li>a:after {
  background: #333
}

.dropdown {
  position: relative
}

.dropdown:after {
  position: absolute;
  content: "\f107";
  color: #fff;
  top: 14px;
  right: 0;
  font-size: .8em;
  font-family: FontAwesome
}

.affix .dropdown:after {
  color: #333
}

.dropdown-menu {
  top: 46px;
  left: 0
}

.navbar-nav>li>.dropdown-menu {
  border-radius: 5px
}

.navbar-right .demo-dropdown .dropdown-menu {
  left: calc(-500px + 38px)
}

/*! update 1.1 */
.navbar-right .dropdown-menu {
  left: -50px
}

.dropdown-menu {
  min-width: 200px;
  border: none;
  border-radius: 5px;
  text-align: center
}

.demo-dropdown {
  position: relative
}

.demo-dropdown .dropdown-menu {
  width: 1140px;
  border: none;
  border-radius: 5px;
  text-align: center
}

.demo-dropdown .dropdown-menu ul {
  padding: 5px 0 0;
  width: 20%;
  float: left
}

.demo-dropdown .dropdown-menu ul:nth-child(even) {
  background: #fafafa;
  border-radius: 0 5px 5px 0
}

.demo-dropdown .dropdown-menu li:first-child {
  padding: 10px 20px 8px;
  font-weight: 700;
  border-bottom: 1px solid #f1f1f1;
  position: relative
}

.demo-dropdown .dropdown-menu li:first-child>span {
  display: block;
  margin-top: 5px;
  position: relative
}

.hot-badge,
.new-badge {
  display: inline-block;
  font-size: 9px;
  color: #fff;
  text-transform: uppercase
}

.demo-dropdown .dropdown-menu li:first-child>span.new-badge {
  position: absolute;
  top: 20px;
  right: 10px
}

.new-badge {
  background: #73e080;
  padding: 0 10px;
  border: 2px solid #fff
}

.hot-badge {
  line-height: 15px;
  background: #ED9443;
  padding: 0 7px;
  font-weight: 400
}

.dropdown-menu li a {
  padding: 7px 15px;
  display: block;
  color: #777;
  font-size: 13px;
  line-height: 1.8
}

.dropdown-menu li:first-child a {
  border-radius: 5px 5px 0 0
}

.dropdown-menu li:last-child a {
  border-radius: 0 0 5px 5px
}

.demo-dropdown .dropdown-menu li:first-child a,
.demo-dropdown .dropdown-menu li:last-child a {
  border-radius: 0
}

.dropdown-menu li a:focus,
.dropdown-menu li a:hover,
.dropdown-menu li.active a,
.dropdown-menu li.active a:focus,
.dropdown-menu li.active a:hover {
  background: #e1e1e1;
  color: #000
}

.navbar-nav>li>.dropdown-menu {
  padding: 0
}

/* .nav-item:before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  top: 100%;
  left: 50%;
  z-index: 999;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #fff;
  opacity: 0
} */

.open .nav-item:before {
  opacity: 1;
  margin-top: -11px;
  -webkit-transition: all .5s ease .1s;
  -moz-transition: all .5s ease .1s;
  transition: all .5s ease .1s
}

@media only screen and (min-width:991px) and (max-width:1199px) {
  .navbar-right .demo-dropdown .dropdown-menu {
    left: calc(-300px + 38px)
  }

  .demo-dropdown .dropdown-menu {
    width: 940px
  }
}

@media only screen and (max-width:991px) {

  .affix,
  .navigation {
    padding: 15px 0
  }

  .dropdown-menu:after,
  .dropdown:after,
  .navbar-default .navbar-nav>.active>a:after,
  .navbar-default .navbar-nav>.active>a:focus:after,
  .navbar-default .navbar-nav>.active>a:hover:after,
  .navbar-default .navbar-nav>li>a:focus:after,
  .navbar-default .navbar-nav>li>a:hover:after {
    display: none
  }

  .navbar-collapse {
    background: #fff;
    max-height: 380px
  }

  .is-transparent .navbar-default .navbar-nav>li>a {
    color: #333
  }

  .nav li {
    background: #fafafa;
    text-align: center
  }

  .dropdown-menu li:nth-child(odd),
  .nav li:nth-child(even) {
    background: #fff
  }

  .nav>li>a {
    margin: 0
  }

  .nav li a {
    font-size: 14px
  }

  .navbar-default .navbar-nav>li>a:hover {
    color: #fff
  }

  .navbar-nav>li.open>.dropdown-menu,
  .navbar-nav>li>.dropdown-menu {
    padding: 0
  }

  .navbar-nav .open .dropdown-menu {
    border: 1px solid #f1f1f1
  }

  .affix .navbar-default .navbar-nav>.active>a,
  .affix .navbar-default .navbar-nav>.active>a:focus,
  .affix .navbar-default .navbar-nav>.active>a:hover,
  .affix .navbar-default .navbar-nav>li>a:focus,
  .navbar-default .in .navbar-nav>li>a:focus,
  .navbar-default .in .navbar-nav>li>a:hover,
  .navbar-default .navbar-nav>li>a:hover {
    color: #fff;
    background: #e0486e
  }

  .navbar-nav .open .dropdown-menu>li>a {
    padding: 10px 20px;
    line-height: 20px
  }

  .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
    color: #333;
    background: #f5f5f5
  }

  .navbar-right .dropdown-menu {
    left: 0
  }

  .dropdown-menu {
    top: 0;
    float: none;
    min-width: 100%;
    position: relative;
    box-shadow: none
  }

  .nav>li>a.button {
    max-width: 200px;
    margin: 25px auto
  }
}

@media only screen and (max-height:479px) {
  .navbar-collapse {
    max-height: 270px
  }
}

.header-section {
  position: relative;
  padding-top: 230px
}

.header-content {
  flex-grow: 1
}

.header-texts {
  padding: 74px 0 35px;
  position: relative;
  z-index: 6
}

.header-texts h1,
.header-texts h2 {
  color: #fff;
  display: inline-block;
  margin-bottom: 20px
}

.header-texts p {
  color: #fff;
  padding-bottom: 13px;
  font-weight: 300
}

.theme-pro .header-texts h1,
.theme-pro .header-texts h2,
.theme-pro .header-texts p {
  color: #333
}

.header-mockup {
  position: relative;
  /*top:30px*/
  ;
  z-index: 5
}

.mockup-screen {
  position: absolute;
  max-width: 305px;
  top: 0
}

.mockup-screen-one {
  position: relative;
  z-index: 20
}

.mockup-screen-two {
  z-index: 19;
  left: 105px
}

.mockup-screen-three {
  z-index: 18;
  left: 160px
}

.video-background {
  background: #000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
  overflow: hidden
}

.video-background iframe {
  position: absolute;
  top: -10%;
  left: -10%;
  width: 120%;
  height: 120%;
  pointer-events: none
}

.particles-container,
.video-foreground {
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0
}

.particles-container {
  z-index: 2
}

.flex-box-middle {
  display: flex;
  align-items: center
}

.half-header {
  height: auto
}

.half-header .header-content {
  padding: 125px 0 85px
}

.header-curbed {
  height: auto;
  padding-bottom: 50px
}

.header-curbed.pb-0 {
  padding-bottom: 0
}

.half-header.header-curbed {
  padding: 20px 0 120px;
  z-index: 2
}

.half-header-about {
  margin-top: -100px
}

.header-curbed:after {
  display: block;
  content: '';
  height: 100%;
  width: 100%;
  background: url(../images/carb.png) bottom no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1
}

.alt-header-mockup {
  display: inline-block;
  position: relative
}

.alt-header-mockup img {
  border: 10px solid #fff;
  border-radius: 30px;
  box-shadow: 7px 29px 20px rgba(0, 0, 0, .08);
  z-index: 5
}

.mockup-left {
  max-width: 170px;
  position: absolute;
  right: 100%;
  bottom: 0;
  margin-right: 20px
}

.mockup-middle {
  position: relative;
  max-width: 220px
}

.mockup-right {
  max-width: 195px;
  position: absolute;
  left: 100%;
  bottom: 0;
  margin-left: 20px
}

.header-slider {
  z-index: 99
}

.text-slider.header-texts {
  padding-bottom: 0
}

.download-buttons.pb-60 {
  padding-bottom: 60px
}

.header-texts .owl-theme .owl-nav [class*=owl-] {
  margin: 5px -70px
}

.header-laptop-mockup {
  background: url(../images/macbook.png) top center no-repeat;
  background-size: 100%;
  padding: 30px 120px 90px;
  position: relative;
  z-index: 9
}

.half-header .header-laptop-mockup {
  min-width: 720px;
  padding: 30px 95px 50px;
  margin-top: 20px
}

.header-flat {
  height: auto;
  overflow: visible;
  margin-bottom: 120px
}

.header-flat-s2:after,
.header-flat:after {
  height: 120px;
  width: 100%;
  content: ""
}

.header-flat:after {
  bottom: -120px;
  position: absolute;
  background: #fbfbfb
}

.header-flat-s2 {
  position: relative
}

.header-flat-s2:after {
  bottom: 0;
  position: absolute;
  background: #fff;
  z-index: 2
}

.header-flat .header-laptop-mockup {
  margin-bottom: -90px
}

.text-slider .buttons {
  margin-bottom: 30px
}

.half-header.header-software .header-content {
  padding: 125px 0 70px
}

@media only screen and (min-width:1025px) and (max-width:1366px) {
  .half-header .header-content h1 {
    font-size: 2.5em
  }
}

@media only screen and (min-width:992px) and (max-width:1024px) {
  .header-texts h1 {
    font-size: 2.5em
  }

  .header-section {
    height: 100vh
  }

  .half-header,
  .header-curbed,
  .header-flat {
    height: auto
  }
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .header-section {
    height: 100vh
  }

  .mockup-screen {
    max-width: 225px
  }

  .header-texts {
    padding-right: 160px
  }

  .header-mockup {
    position: absolute;
    width: 100%;
    top: 50%;
    right: -55%;
    transform: translate(0, -40%)
  }

  .has-carousel .header-texts {
    padding-left: 40px
  }

  .half-header,
  .header-curbed,
  .header-flat {
    height: auto
  }

  .header-curbed .header-texts,
  .header-curbed-circle .header-texts,
  .header-flat .header-texts {
    padding-right: 0
  }

  .header-text-slide {
    padding: 0 70px
  }

  .header-texts .owl-theme .owl-nav [class*=owl-] {
    margin: 5px 15px
  }

  .header-flat .header-laptop-mockup {
    margin-bottom: -60px
  }
}

@media only screen and (max-width:767px) {
  .header-section {
    height: 100vh;
    min-height: 300px
  }

  .mockup-screen {
    max-width: 205px
  }

  .header-mockup {
    position: absolute;
    width: 100%;
    top: 50%;
    right: -55%;
    transform: translate(0, -40%)
  }

  .has-carousel .header-texts {
    padding-left: 40px
  }

  .header-texts {
    padding: 100px 30px 20px;
    max-width: 360px;
    margin: 0 auto
  }

  .header-curbed .header-texts {
    padding: 55px 30px 20px;
    max-width: inherit
  }

  .header-texts h1,
  .header-texts h2 {
    font-size: 1.6em
  }

  .header-texts p {
    font-size: 11px
  }

  .half-header,
  .header-curbed,
  .header-flat {
    height: auto
  }

  .half-header .header-content {
    padding: 20px 0 85px
  }

  .alt-header-mockup img {
    border: 5px solid #fff;
    border-radius: 15px;
    box-shadow: 7px 10px 10px rgba(0, 0, 0, .08)
  }

  .mockup-middle {
    max-width: 110px
  }

  .mockup-left {
    max-width: 90px;
    margin-right: 10px
  }

  .mockup-right {
    max-width: 100px;
    margin-left: 10px
  }

  .download-buttons.pb-60 {
    padding-bottom: 20px
  }

  .header-software .header-texts h1,
  .header-software .header-texts h2 {
    font-size: 1.2em
  }

  .half-header .header-laptop-mockup,
  .header-laptop-mockup {
    padding: 16px 55px 32px;
    max-width: 440px;
    min-width: 440px;
    margin: 0 auto
  }

  .half-header.header-curbed {
    padding: 20px 0 0
  }

  .half-header.header-software .header-content {
    padding: 45px 0 25px
  }

  .header-curbed.header-software {
    padding-bottom: 35px
  }

  .half-header-about {
    margin-top: 0
  }

  .header-flat .header-laptop-mockup {
    margin-bottom: -33px
  }

  .header-flat {
    height: auto;
    overflow: visible;
    margin-bottom: 40px
  }

  .header-flat:after {
    height: 40px;
    bottom: -40px
  }
}

.features-section,
.team-member {
  overflow: hidden
}

@media only screen and (min-width:768px) and (max-width:1200px) {
  .header-laptop-mockup {
    padding: 25px 95px 60px;
    max-width: 720px;
    margin: 0 auto
  }

  .header-curbed.header-software {
    padding-bottom: 20px
  }
}

@media only screen and (max-width:479px) {

  .half-header .header-laptop-mockup,
  .header-laptop-mockup {
    padding: 14px 40px 25px;
    max-width: 280px;
    min-width: 280px;
    margin: 0 auto
  }

  .header-curbed.header-software {
    padding-bottom: 15px
  }

  .header-flat .header-laptop-mockup {
    margin-bottom: -25px
  }
}

.feature-boxes {
  margin-top: -80px;
  position: relative;
  z-index: 100
}

@media only screen and (max-width:1024px) {
  .feature-boxes {
    margin-top: 60px
  }

  .feature-boxes .box {
    margin-top: 30px
  }

  .half-header-box {
    margin-top: -110px
  }
}

.features-list {
  padding-top: 40px
}

.single-features {
  position: relative;
  padding-left: 83px;
  margin-bottom: 45px
}

.icon-right {
  padding-right: 83px;
  padding-left: 0
}

.single-features .fa,
.single-features .ti {
  width: 60px;
  border-radius: 6px;
  text-align: center;
  color: rgba(212, 68, 99, .9);
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block
}

.icon-right .fa,
.icon-right .ti {
  left: auto;
  right: 0
}

.single-features h4 {
  color: #fff;
  margin-bottom: 10px
}

.single-features p {
  color: #fff;
  font-size: .99em;
  line-height: 1.6em
}

.theme-pro .single-features h4,
.theme-pro .single-features p {
  color: #333
}

.fearures-mockup {
  margin-bottom: -40px;
  position: relative;
  top: 45px
}

.fearures-mockup img {
  border-radius: 15px 15px 0 0;
  max-width: 260px
}

.fearures-mockup.iphonex:after {
  position: absolute;
  content: url(../images/iphonex.png);
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-49%, -50%) scale(.6);
  transform: translate(-49%, -50%) scale(.6)
}

.fearures-mockup.iphone:after {
  position: absolute;
  content: url(../images/iphone.png);
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-49%, -48%) scale(.82);
  transform: translate(-49%, -48%) scale(.82)
}

.fearures-mockup-dubble {
  position: relative
}

.fearures-mockup-alt {
  left: 20px
}

.fearures-mockup-two {
  position: absolute;
  left: -40px;
  bottom: 0;
  top: auto;
  z-index: 1
}

.fearures-mockup-two.iphone {
  bottom: -50px
}

.fearures-mockup-dubble .fearures-mockup-two {
  margin-bottom: 0
}

.fearures-mockup-two img {
  border-radius: 15px 15px 0 0;
  max-width: 200px
}

.fearures-mockup-two.iphonex:after {
  -webkit-transform: translate(-49%, -50%) scale(.45);
  transform: translate(-49%, -50%) scale(.45)
}

.fearures-mockup-two.iphone:after {
  -webkit-transform: translate(-49.2%, -50%) scale(.6);
  transform: translate(-49.2%, -50%) scale(.6)
}

.fearures-software-mockup {
  min-width: 1100px;
  background: url(../images/macbook.png) top center no-repeat;
  background-size: 100%;
  padding: 40px 140px 90px;
  float: right;
  margin-bottom: -60px;
  position: relative;
  z-index: 1
}

.features-scrreen img {
  border: 1px solid #ececec;
  border-radius: 8px
}

.browser-screen {
  position: relative
}

.browser-expanded {
  min-width: 800px;
  float: right
}

.browser-screen img {
  -moz-box-shadow: 0 0 29px rgba(0, 0, 0, .3);
  -webkit-box-shadow: 0 0 29px rgba(0, 0, 0, .3);
  box-shadow: 0 0 29px rgba(0, 0, 0, .3)
}

.browser-screen:after {
  position: absolute;
  top: -30px;
  left: 0;
  height: 33px;
  width: 100%;
  content: "";
  background: url(../images/browser-bar.png) bottom center no-repeat;
  background-size: 100%
}

.iphone.owl-carousel .owl-item img,
.iphonex.owl-carousel .owl-item img {
  width: 192px;
  margin: 0 auto;
  position: relative
}

@media only screen and (min-width:992px) and (max-width:1199px) {
  .single-features p {
    color: #fff;
    font-size: .8em
  }

  .features-list {
    padding-top: 40px
  }

  .single-features {
    margin-bottom: 30px
  }

  .fearures-software-mockup {
    margin-right: -80px
  }
}

@media only screen and (max-width:991px) {
  .icon-right {
    padding-left: 0;
    padding-right: 0
  }

  .single-features {
    text-align: center;
    padding-left: 0
  }

  .single-features .ti {
    position: relative;
    margin-bottom: 20px
  }

  .features-section .pull-right {
    float: none !important
  }

  .features-list {
    max-width: 420px;
    margin: 0 auto;
    padding: 0
  }

  .fearures-mockup-dubble {
    margin: -120px auto -110px;
    left: 15px;
    max-width: 320px
  }

  .fearures-software-mockup {
    max-width: 720px;
    min-width: 720px;
    padding: 30px 95px 100px;
    float: none;
    margin: 0 auto -80px
  }

  .browser-expanded {
    min-width: 100%;
    float: none
  }
}

@media only screen and (max-width:767px) {
  .fearures-mockup-dubble {
    transform: scale(.8);
    margin-bottom: -110px;
    margin-top: -120px;
    left: 15px;
    max-width: 320px
  }

  .fearures-software-mockup {
    max-width: 420px;
    min-width: 420px;
    padding: 20px 60px 70px;
    float: none;
    margin: 0 auto -60px
  }

  .browser-expanded {
    min-width: 100%;
    float: none
  }
}

@media only screen and (max-width:479px) {
  .fearures-software-mockup {
    min-width: 300px;
    max-width: 300px;
    padding: 14px 40px 25px;
    float: none;
    margin: 0 auto -30px
  }
}

.slide-screen {
  position: relative
}

.slide-screen .owl-item {
  opacity: .6;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease
}

.slide-screen .owl-item.center {
  opacity: 1
}

.slide-screen.iphone::after {
  content: url(../images/iphone.png);
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(.6);
  transform: translate(-50%, -50%) scale(.6);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease
}

.slide-screen.iphonex::after {
  content: url(../images/iphonex.png);
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-49%, -51%) scale(.45);
  transform: translate(-49%, -51%) scale(.45);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease
}

.frame-fix.slide-screen:after {
  opacity: 0
}

.owl-carousel .owl-item.center img {
  border-radius: 5px
}

.iphone.owl-carousel .owl-item img {
  top: 40px
}

.iphone.owl-carousel .owl-item {
  position: relative
}

.iphone.owl-carousel .owl-item:after,
.software-screens .item:after {
  position: absolute;
  left: 0;
  width: 100%;
  content: ""
}

.iphone.owl-carousel .owl-item:after {
  height: 45px;
  background: #fff;
  bottom: 0
}

.software-screens {
  margin-bottom: -40px
}

.software-screens .item:after {
  top: -30px;
  height: 33px;
  background: url(../images/browser-bar.png) bottom center no-repeat;
  background-size: 100%
}

.software-screens .item {
  margin: 30px;
  position: relative;
  -moz-box-shadow: 0 0 29px rgba(0, 0, 0, .3);
  -webkit-box-shadow: 0 0 29px rgba(0, 0, 0, .3);
  box-shadow: 0 0 29px rgba(0, 0, 0, .3)
}

.software-screens .owl-nav .owl-next,
.software-screens .owl-nav .owl-prev {
  font-size: 24px;
  background: 0 0
}

.software-screens .owl-nav .owl-prev {
  left: -60px
}

.software-screens .owl-nav .owl-next {
  right: -60px
}

.software-screens .owl-nav .owl-prev:hover {
  left: -70px;
  background: 0 0
}

.software-screens .owl-nav .owl-next:hover {
  right: -70px;
  background: 0 0
}

@media only screen and (max-width:1169px) {
  .slide-screen {
    max-width: 700px;
    margin: 0 auto
  }
}

.statistics-section .box {
  margin-bottom: 30px
}

.pricing-section {
  position: relative;
  background-size: cover
}

.pricing-box {
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  transition: all .4s ease;
  margin: 15px 0 30px;
  border-radius: 8px;
  background: #fff
}

.pricing-box:hover {
  transform: translateY(-8px)
}

.pricing-top {
  border-radius: 8px 8px 0 0;
  padding: 30px 0
}

.pricing-top h5 {
  color: #fff;
  margin-bottom: 12px
}

.pricing-top h2 {
  color: #666;
  font-weight: 300;
  font-size: 3.6em
}

.pricing-badge {
  color: #fff;
  font-size: .9em;
  display: inline-block;
  margin-bottom: 15px;
  font-weight: 400;
  text-transform: uppercase
}

.pricing-bottom {
  background: #fff;
  border-radius: 0 0 8px 8px;
  padding: 25px 40px 40px
}

.pricing-bottom ul {
  margin-bottom: 25px
}

.pricing-bottom ul li {
  font-weight: 400;
  line-height: 2.5;
  position: relative
}

.pricing-bottom ul li .ti {
  margin-right: 10px;
  font-size: .8em;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 2.5
}

.pricing-bottom .ti-close {
  color: #e0486e
}

.pricing-bottom .ti-check {
  color: #7bd812
}

.pricing-top {
  position: relative
}

.pricing-box-curbed .pricing-top:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: url(../images/pricing-carb.png) bottom no-repeat;
  background-size: contain
}

.pricing-box-curbed .pricing-top {
  padding: 50px 0
}

@media only screen and (max-width:991px) {
  .pricing-section.has-bg-image {
    background-color: #000
  }

  .pricing-section.has-bg-image .imagebg {
    height: 400px;
    background-size: cover
  }

  .pricing-section.has-bg-image .imagebg:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 1) 100%);
    height: 200px
  }

  .pricing-box {
    max-width: 420px;
    margin: 0 auto 30px
  }
}

.faqs {
  margin-bottom: 40px;
  position: relative
}

.faqs:before {
  position: absolute;
  content: "";
  height: calc(100% + 35px);
  width: calc(100% + 25px);
  top: -20px;
  left: -20px;
  opacity: 0;
  background: rgba(0, 0, 0, .07);
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease
}

.team-member,
.team-member .team-photo {
  position: relative
}

.faqs:hover:before {
  opacity: 1
}

.faq-heading {
  margin-bottom: 10px
}

.heading-light.side-heading a {
  color: #fff;
  border-bottom: 1px solid #fff
}

.side-heading a {
  color: #333;
  border-bottom: 1px solid #333
}

.faq-alt .faq-heading {
  color: #666
}

.faq-heading,
.faqs p {
  color: #fff
}

.faq-alt .faqs p {
  color: #333
}

.team-member {
  margin-bottom: 30px;
  text-align: center
}

.expand-trigger,
.team-member .team-photo:after {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  transition: all .5s ease
}

.team-member .team-photo:after {
  height: 100%;
  opacity: .35;
  border-radius: 8px;
  content: "";
  background-image: -o-linear-gradient(157deg, #ED355B 0, #1F65DB 100%);
  background-image: linear-gradient(293deg, #ED355B 0, #1F65DB 100%)
}

.team-member .team-photo img {
  margin: 0;
  width: 100%;
  height: auto;
  border-radius: 8px
}

.team-member .team-info {
  margin-top: 25px
}

.team-member .team-info .name,
.team-member .team-info h4 {
  margin-bottom: 5px
}

.team-member .team-info .sub-title {
  font-size: .875em
}

.team-photo:hover:after {
  opacity: .7
}

.team-photo img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%)
}

.expand-trigger {
  display: block;
  height: 100%;
  z-index: 5;
  color: #fff;
  opacity: 0;
  transform: scale(0);
  font-size: 14px
}

.expand-trigger .ti,
.expand-trigger span {
  position: absolute;
  height: 50px;
  line-height: 50px;
  top: 50%;
  text-align: center
}

.expand-trigger span {
  width: 100%;
  left: 0;
  text-transform: uppercase;
  font-weight: 500;
  transform: translateY(-50%)
}

.expand-trigger:focus,
.expand-trigger:hover {
  color: #fff
}

.team-photo:hover .expand-trigger {
  opacity: 1;
  transform: scale(1)
}

.expand-trigger .ti {
  font-size: 30px;
  width: 50px;
  left: 50%;
  transform: translate(-50%, -50%)
}

.team-profile {
  max-width: 970px;
  margin: 30px auto;
  padding: 50px 35px;
  background: #fff;
  position: relative
}

.team-profile-photo img {
  min-width: 100%
}

.team-profile-info {
  padding: 30px 0 20px 20px
}

.team-profile-info .name {
  margin-bottom: 5px
}

.team-profile-info .sub-title {
  font-size: .875em;
  margin-bottom: 5px
}

.team-profile-info .social li {
  display: inline-block
}

.team-profile-info .social li a {
  font-size: 14px;
  margin-right: 10px
}

.team-profile-info p {
  font-size: .875em;
  margin-bottom: 8px
}

.team-profile-info p:last-of-type {
  margin-bottom: 28px
}

.single-skill-bar {
  font-size: 12px;
  text-transform: capitalize;
  padding-bottom: 15px
}

.skill-bar {
  position: relative;
  width: 100%;
  height: 4px;
  background: #ccc;
  margin-top: 5px
}

.skill-bar-percent {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0
}

@media only screen and (max-width:991px) {
  .team-profile-info {
    padding: 40px 0 0
  }
}

.testimonial-section {
  background-size: cover
}

.testimonial-carousel.owl-carousel .owl-item img {
  display: block;
  width: auto
}

.quotes {
  position: relative;
  padding: 20px 40px 65px;
  margin: 15px 15px 40px;
  border-radius: 8px;
  background: #fff;
  -moz-box-shadow: 0 0 12px 0 rgba(2, 3, 3, .06);
  -webkit-box-shadow: 0 0 12px 0 rgba(2, 3, 3, .06);
  box-shadow: 0 0 12px 0 rgba(2, 3, 3, .06)
}

.quotes h6 {
  font-size: 16px
}

.quotes blockquote {
  margin: 16px 0
}

.quotes img {
  margin: 0 auto
}

.quote-icon {
  max-width: 65px
}

.client-image {
  height: 100px;
  width: 100px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  bottom: -40px
}

.client-image:before {
  position: absolute;
  content: "";
  top: -12px;
  left: 40px;
  z-index: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #ccc
}

.client-image img {
  border-radius: 50%
}

.contact-form {
  border-radius: 8px;
  padding: 35px 35px 40px;
  margin-bottom: 30px
}

.contact-form h3 {
  color: #e8365e;
  margin-bottom: 15px
}

.contact-info {
  border-radius: 8px 8px 0 0;
  padding: 30px 40px
}

.contact-info h6 {
  font-size: .9em;
  font-weight: 400;
  margin: 10px 0
}

.contact-info h6 .fa {
  width: 20px;
  color: #e8365e
}

.footer-navigation li a,
.form-note,
.social-list li a {
  color: #979797
}

.google-map {
  height: 318px;
  border-radius: 0 0 8px 8px
}

.form-note {
  font-size: .8em;
  display: block;
  margin-top: 15px
}

.form-results {
  margin: 20px 0;
  font-size: 13px;
  text-align: center
}

.form-results:last-child {
  margin-bottom: 0
}

.footer-section {
  background: #e6e6e6;
  padding: 60px 0
}

.inline-list li {
  display: inline-block
}

.footer-navigation {
  padding-bottom: 10px
}

.footer-navigation li {
  font-size: .91em;
  margin: 3px;
  padding: 3px;
  color: rgba(111, 111, 111, 0.5)
}

.social-list li {
  font-size: 1.25em;
  margin: 0 15px
}

.footer-links li {
  color: #979797;
  font-size: .813em;
  margin: 0 10px
}

.footer-links li a {
  color: #979797
}

.footer-links li a:hover,
.footer-navigation li a:hover,
.social-list li a:hover {
  color: #fff
}

.theme-pro .footer-section {
  background: #fff
}

.theme-pro .footer-links li,
.theme-pro .footer-links li a,
.theme-pro .footer-navigation li a,
.theme-pro .social-list li a {
  color: #333
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .tab-center {
    text-align: center
  }

  .tab-left {
    text-align: left
  }
}

@media only screen and (max-width:767px) {
  .footer-navigation li {
    margin: 0 7px;
    font-size: .81em
  }

  .social-list li {
    font-size: 1.1em;
    margin: 0 7px
  }

  .footer-links li {
    font-size: .7em;
    margin: 0 7px
  }

  .mobile-left {
    text-align: left
  }

  .mobile-center {
    text-align: center
  }

  .navigation,
  body {
    min-width: 300px
  }
}

@media only screen and (max-width:991px) {
  .navbar-nav .demo-dropdown .dropdown-menu {
    display: none !important
  }
}

.steps-section .nav-tabs {
  border-bottom: none
}

.steps-section .nav-tabs li {
  width: 100%;
  cursor: pointer
}

.txt-feature h2 {
  font-size: 2em;
  margin-bottom: 15px;
  margin-top: 15px
}

.txt-feature h3 {
  font-size: 1.5em
}

.steps {
  display: block;
  width: 100%;
  padding: 25px 30px;
  transition: all .5s ease
}

.steps h4 {
  margin-bottom: 10px
}

.active .steps {
  cursor: default;
  -moz-box-shadow: 0 0 11px 1px rgba(0, 0, 0, .07);
  -webkit-box-shadow: 0 0 11px 1px rgba(0, 0, 0, .07);
  box-shadow: 0 0 11px 1px rgba(0, 0, 0, .07)
}

.active .steps h4 {
  color: #1F65DB
}

.steps-screen {
  background: url(../images/macbook.png) top center no-repeat;
  background-size: 100%;
  padding: 30px 90px 60px
}

.logo-item {
  border-radius: 8px
}

.contact-panel {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: 0;
  margin-right: 0
}

.contact-panel [class*=col-] {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  float: none;
  padding: 25px
}

.single-contact {
  color: #fff;
  padding: 60px 0
}

.single-contact .fa {
  display: block;
  font-size: 36px;
  margin-bottom: 15px
}

.scontact-info {
  font-size: 1.1em;
  line-height: 2
}

.download-form input.form-control {
  border-radius: 25px;
  padding-left: 20px
}

.download-form h2 {
  margin-bottom: 18px
}

.download-form>.heading-light {
  margin-bottom: 20px
}

@media only screen and (min-width:992px) and (max-width:1199px) {
  .steps-screen {
    margin: 100px 0
  }

  .row-vm .steps-screen {
    margin: 0
  }
}

@media only screen and (max-width:991px) {
  .steps-section .nav-tabs li {
    margin-bottom: 10px;
    background: #fff
  }

  .steps-section .nav-tabs {
    margin-bottom: 30px
  }

  .steps-screen {
    max-width: 720px;
    padding: 30px 95px 100px
  }

  .txt-feature h2 {
    margin-top: 30px
  }

  .scontact-info {
    font-size: .9em
  }
}

@media only screen and (max-width:767px) {
  .steps-screen {
    max-width: 420px;
    padding: 20px 60px 70px;
    margin: 0 auto
  }

  .contact-panel [class*=col-] {
    flex: 0 0 100%;
    max-width: 100%
  }

  .scontact-info {
    font-size: 1.1em
  }

  .single-contact {
    padding: 25px 0
  }
}

@media only screen and (max-width:479px) {
  .steps-screen {
    max-width: 300px;
    padding: 14px 40px 25px;
    margin: 0 auto
  }

  .row-vm .steps-screen {
    margin-bottom: 20px
  }

  .steps-section .nav-tabs {
    margin-bottom: 15px
  }
}

.overflow-scroll {
  overflow: hidden !important;
  overflow-y: scroll !important
}

.section-overflow-fix {
  position: relative
}

.section-overflow-fix:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 130px;
  background: #fff;
  content: ""
}

.nav.inline-nav {
  border-bottom-color: transparent;
  margin-bottom: 40px
}

.inline-nav .steps {
  padding: 12px 20px;
  border-radius: 25px
}

.inline-nav .steps h4 {
  margin-bottom: 0
}

.nav-tabs.inline-nav li {
  cursor: pointer;
  float: none;
  display: inline-block;
  margin: 0 15px
}

.header-curbed-circle {
  height: auto;
  z-index: 2
}

.background-circles:before,
.header-curbed-circle:after {
  display: block;
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  right: 0
}

.header-curbed-circle:after {
  background: url(../images/carb-circle.png) bottom no-repeat;
  background-size: contain
}

.background-circles:before {
  background: url(../images/bubble.png) top center no-repeat;
  background-size: cover
}

.theme-pro .background-circles:before {
  background-image: url(../images/bubble-pro.png)
}

.theme-pro .has-bg-image.background-circles:before,
.theme-pro .has-bg-video.background-circles:before {
  background-image: url(../images/bubble.png)
}

.header-laptop-mockup.black {
  background: url(../images/macbook-black.png) top center no-repeat;
  background-size: 100%
}

.header-mockups {
  position: relative;
  padding-bottom: 10px
}

.header-mockups .iphonex-flat-mockup {
  position: absolute;
  bottom: 15px;
  right: 0;
  z-index: 99
}

.half-header .header-mockups .iphonex-flat-mockup {
  bottom: -15px;
  right: -30px
}

.iphonex-flat-mockup {
  position: relative;
  width: 190px;
  height: 390px
}

.iphonex-flat-mockup.large {
  position: relative;
  width: 240px;
  height: 520px
}

.iphonex-flat-mockup img {
  border-radius: 20px
}

.iphonex-flat-mockup:after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: url(../images/iphonex-flat.png);
  transform: translate(-51.5%, -49.3%) scale(.5)
}

.iphonex-flat-mockup.large:after {
  transform: translate(-51.5%, -50.8%) scale(.645)
}

.feature-box {
  margin-bottom: 60px
}

.feature-box h4 {
  margin: 13px 0
}

.fearures-software-mockup.black {
  background: url(../images/macbook-black.png) top center no-repeat;
  background-size: 100%
}

.fearures-software-mockup.right {
  float: left
}

.feature-mockups {
  position: relative
}

.feature-mockups .phone-mockup {
  position: absolute;
  left: 20px;
  bottom: 25px;
  z-index: 1
}

.laptop-mockup,
.tab-pane {
  position: relative
}

.steps-screen.black {
  background: url(../images/macbook-black.png) top center no-repeat;
  background-size: 100%
}

.laptop-mockup {
  background: url(../images/macbook.png) top center no-repeat;
  background-size: 100%;
  padding: 30px 100px 90px;
  z-index: 1
}

.laptop-mockup.laptop-black {
  background: url(../images/macbook-black.png) top center no-repeat;
  background-size: 100%
}

.fade {
  transition: all .5s ease
}

.tab-pane .laptop-mockup {
  opacity: 0;
  transform: translate(0, 100px);
  transition: all .5s ease
}

.tab-pane .iphonex-flat-mockup {
  opacity: 0;
  transform: translate(50px, 0);
  transition: all .5s ease .2s
}

.fade.in .iphonex-flat-mockup,
.fade.in .laptop-mockup {
  opacity: 1;
  transform: translate(0)
}

.tab-pane .phone-mockup {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1
}

.feature-mockups .fearures-software-mockup {
  margin-bottom: 50px
}

@media only screen and (min-width:768px) and (max-width:1200px) {
  .laptop-mockup {
    padding: 35px 95px 60px;
    max-width: 720px;
    margin: 0 auto
  }

  .iphonex-flat-mockup {
    width: 150px;
    height: 310px
  }

  .iphonex-flat-mockup.large {
    position: relative;
    width: 190px;
    height: 390px
  }

  .iphonex-flat-mockup:after {
    transform: translate(-51.5%, -49.3%) scale(.4)
  }

  .iphonex-flat-mockup.large:after {
    transform: translate(-51.5%, -49.3%) scale(.5)
  }

  .inline-nav .steps {
    padding: 12px 20px;
    border-radius: 25px
  }

  .inline-nav .steps h4 {
    font-size: 1em
  }

  .nav-tabs.inline-nav li {
    margin: 0 10px
  }
}

@media only screen and (max-width:991px) {

  .inline-nav li,
  .inline-nav li:nth-child(2n) {
    background: 0 0
  }
}

@media only screen and (max-width:767px) {
  .laptop-mockup {
    padding: 16px 55px 32px;
    max-width: 440px;
    min-width: 440px;
    margin: 0 auto
  }

  .iphonex-flat-mockup,
  .iphonex-flat-mockup.large {
    width: 100px;
    height: 204px
  }

  .iphonex-flat-mockup.large:after,
  .iphonex-flat-mockup:after {
    transform: translate(-50.8%, -49.3%) scale(.265)
  }

  .feature-mockups,
  .header-mockups,
  .tab-pane {
    max-width: 450px;
    margin: 0 auto
  }

  .feature-mockups .phone-mockup {
    bottom: -40px
  }
}

@media only screen and (max-width:479px) {

  .feature-mockups,
  .header-mockups,
  .laptop-mockup,
  .tab-pane {
    max-width: 280px;
    margin: 0 auto
  }

  .laptop-mockup {
    padding: 14px 40px 25px;
    min-width: 280px
  }

  .iphonex-flat-mockup,
  .iphonex-flat-mockup.large {
    width: 70px;
    height: 142px
  }

  .iphonex-flat-mockup img {
    border-radius: 10px
  }

  .iphonex-flat-mockup.large:after,
  .iphonex-flat-mockup:after {
    transform: translate(-50.6%, -49.6%) scale(.185)
  }

  .header-mockups .iphonex-flat-mockup {
    bottom: -10px
  }

  .nav-tabs.inline-nav li {
    display: block
  }
}

.cursor-pointer {
  cursor: pointer;
}