#admin .contacts-import .list-report {
  margin-top: 0%;
  margin-bottom: 5%;
  overflow-y: scroll;
  height: 90%;
}
#admin .contacts-import .list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  width: 90%;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  text-align: left;
  margin-bottom: 0px !important;
}

#admin .contacts-import .list-group-item {
  cursor: pointer;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  color: #8c8c8c;
}

#admin .contacts-import {
  height: 100%;
}

#admin .contacts-import .card-body {
  height: 100%;
}

#admin .contacts-import .card-body .dropzone {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#admin .contacts-import .card-box {
  height: 95%;
}

#admin .green-status {
  color: #7ad810;
  font-size: 16px !important;
}

.file-upload-info {
  font-size: 16px;
}

.file-upload-info {
  font-size: 16px;
}

#admin .contacts-import-header {
  display: flex;
}
#admin .contacts-import-header .navigate-before {
  margin-right: 5px;
}
#admin .contacts-import-header .navigate-before svg {
  font-size: 30px;
}
