#admin .orders .order-details {
  height: 100%;
}

.order-details-transported-by {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end !important;
  width: 100%;
  height: 15%;
}

.order-details-form-group {
  height: 100%;
}

.form-order-details-information-fields {
  height: 85%;
  padding-top: 5%;
}

.form-order-details-information-fields input::-webkit-outer-spin-button,
.form-order-details-information-fields input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-order-details-information-fields #regular-input.company-number {
  margin-top: 7%;
}

.form-order-details-information-fields input[type='number'] {
  -moz-appearance: textfield;
}

.order-details-button-group .svg-iconiz {
  margin-right: 10px;
}

.form-order-details-information-fields #regular-input:nth-child(2) {
  margin: 6px 0 17px 0px;
}

.order-details-information-fields-container {
  height: 100%;
}

.order-details-information-fields-container
  .order-details-information-fields-top-part {
  height: 92%;
}

.order-details-information-fields-container
  .order-details-information-fields-bottom-part {
  display: flex;
  align-items: center;
  height: 8%;
  justify-content: center;
}

.order-group-calendar {
  background: white;
  width: 100%;
}

.order-group-list {
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  position: relative;
  left: 1px;
}

#admin .orders .order-details .order-details-form-group .button-group {
  height: 30% !important;
}

#admin .orders .order-details .transported-by-text {
  font-size: 23px;
  opacity: 0.6;
  margin: 0;
  width: 50%;
  text-align: right;
  padding-right: 38px;
}

@media screen and (max-width: 1355px) {
  #admin .orders .order-details .transported-by-text {
    font-size: 19px !important;
    width: 60%;
  }
}

#delivery-button-group {
  height: 30% !important;
  width: 44% !important;
}

#delivery-button-group .btn-white {
  width: 40% !important;
  font-size: 20px !important;
  padding: 12px 12px !important;
  line-height: 0.8 !important;
}

.btn-active {
  color: #7bd812 !important;
}

#delivery-button-group button:nth-child(1) {
  border-radius: 11px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: white;
}

#delivery-button-group button:nth-child(2) {
  border-radius: 11px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: white;
}

.order-details .form-controled {
  border-radius: 0px;
  padding-left: 0px;
  opacity: 0.9;
  font-family: 'Titillium-Regular' !important;
  font-size: 23px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.7;
  letter-spacing: normal;
  text-align: left;
  color: rgba(112, 112, 112, 0.75);
}

.order-details .form-controledBlack {
  color: rgba(12, 12, 12);
}

.order-details .line {
  width: 100%;
  height: 2px;
  object-fit: contain;
  opacity: 0.75;
  background-color: #bdbdbd;
  margin-left: 0 !important;
}

.order-details .space-top-bottom-form {
  margin-top: 30px;
  margin-left: 63px;
  margin-bottom: 120px;
}

.order-details .textCenter {
  text-align: center;
}

.order-details .form-controled {
  position: relative;
  right: 6px;
  width: 95%;
  margin: 0 auto;
  text-align: left;
}

.order-details .space-top-bottom {
  height: 10%;
  margin-top: 40px;
  margin-bottom: 15px;
}

.order-details .user-input-wrp .floating-label-top {
  position: absolute;
  pointer-events: none;
  transition: 0.2s ease all;
  top: -25%;
  opacity: 0.8;
  font-family: 'Titillium-Regular' !important;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(112, 112, 112, 0.4);
  text-align: left;
}

.order-details-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;
}

.order-details-button-group button:nth-child(2) {
  margin-left: 3%;
  width: 40%;
}

/* .order-details-button-group button:nth-child(1) {
  width: 100%;
} */

@media screen and (max-width: 1160px) {
  #admin .orders .order-details .transported-by-text {
    text-align: unset;
    padding-right: 0;
  }
  #tab-Event {
    height: 92% !important;
  }
}

/* Order details design ameliorations */

.order-details .underline {
  width: 95% !important;
}

.order-details .order-details-transported-by {
  display: flex;
  justify-content: flex-end !important;
  padding-right: 5%;
}

.order-details .order-group-list {
  padding-right: 5%;
}

.order-details #delivery-button-group button:nth-child(2) {
  border: none;
  box-shadow: none;
  border-radius: 0 7px 7px 0;
  width: 50% !important;
}

.order-details #delivery-button-group button:nth-child(1) {
  border-right: 2px solid #efefef !important;
  box-shadow: none;
  border: none;
  border-radius: 7px 0px 0px 7px;
  width: 50% !important;
}

.order-details #delivery-button-group .btn-white {
  width: 44% !important;
  line-height: 0.3 !important;
}

.order-details #delivery-button-group button span {
  font-size: 17px;
  color: #b7b7b7 !important;
}

.order-details #delivery-button-group button.btn-active span {
  color: #7bd812 !important;
}

.order-details #delivery-button-group {
  width: 38% !important;
  box-shadow: 0px -5px 14px 4px rgba(226, 222, 222, 0.17),
    1px 1px 16px 0px rgba(202, 202, 202, 0.14),
    1px 2px 14px 0px rgba(175, 175, 175, 0.25);
  border-radius: 7px;
}

.order-details .order-group-calendar {
  padding-right: 7%;
}

.order-details .card-title {
  color: #77d609;
}

.order-details .transported-by-text {
  font-weight: 600;
  color: #9c9696;
}

.order-details .btn-bold {
  opacity: 1 !important;
  font-weight: bold;
}

.order-details .react-datepicker__triangle {
  left: 245px;
}

.order-details .react-datepicker-popper {
  top: 7px !important;
  left: -165px !important;
}

.react-datepicker__day--keyboard-selected {
  background: #868686;
  border-radius: 50%;
}

.order-details .react-datepicker__input-container input {
  opacity: 0;
  width: 50px;
}

.order-details .date-field {
  width: 10%;
}

.date-field {
  position: relative;
  width: 6%;
  top: 8px;
}

/* Resale button */

button.resale-btn {
  width: 20% !important;
  opacity: 1 !important;
  background-color: #cdcecc !important;
  padding: 3px 6px;
  color: white;
  margin: 0px 2% 10px 2%;
  box-shadow: 3px 1px 14px 4px rgba(218, 210, 210, 0.28),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

/* Resale button end */

.order-details .select-language-val {
  background: #ffff !important;
  font-size: 1em;
  font-weight: 700;
  padding: 5px 0px 5px 13px;
  /* margin: 3px 63px 0px 70px; */
}

.order-details .order-details-form .date-field.picker {
  right: 6%;
  width: 0;
  top: -5px;
}

.order-details .order-details-form {
  display: flex;
  align-items: baseline;
}

.open-buyings-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  position: absolute;
  bottom: -53px;
  width: 100%;
}

.flexmargininfo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.flexmargininfob1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
}
.flexmargininfobloc {
  width: 40%;
}
.flexmargininfobloc2 {
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
}

.order-details #delivery-button-groupt button:nth-child(2) {
  border: none;
  box-shadow: none;
  border-radius: 0 7px 7px 0;
}

.order-details #delivery-button-groupt button:nth-child(1) {
  border-right: 2px solid #efefef !important;
  box-shadow: none;
  border: none;
  border-radius: 7px 0px 0px 7px;
}

.order-details #delivery-button-groupt .btn-white {
  line-height: 0.3 !important;
}

.order-details #delivery-button-groupt button span {
  font-size: 17px;
  color: #b7b7b7 !important;
}

.order-details #delivery-button-groupt button.btn-active span {
  color: #7bd812 !important;
}

.order-details #delivery-button-groupt {
  box-shadow: 0px -5px 14px 4px rgba(226, 222, 222, 0.17),
    1px 1px 16px 0px rgba(202, 202, 202, 0.14),
    1px 2px 14px 0px rgba(175, 175, 175, 0.25);
  border-radius: 7px;
}

#delivery-button-groupt {
  height: 30% !important;
}

#delivery-button-groupt .btn-white {
  font-size: 20px !important;
  padding: 12px 12px !important;
  line-height: 0.8 !important;
}

.btn-active {
  color: #7bd812 !important;
}

#delivery-button-groupt button:nth-child(1) {
  border-radius: 11px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: white;
}

#delivery-button-groupt button:nth-child(2) {
  border-radius: 11px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: white;
}

.gleflex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.gleflexb1 {
  width: 42%;
}

.modalsize{
  width: auto;

}
.modalsize .deorder-ertagsp{
  height: 100% !important;
  height: 300px !important;
  min-width:100px !important;
}
.modalsize  .modal-content {
  width: 380px !important;
}

.modalsize  .linestyle  {
  text-align: center;
  height: 220px;
  overflow-y: scroll;
  margin-bottom: 20px;
  width: 90%;

}
.modalsize .flexbox{
  height: 100%;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
	align-content: center;
} 
.modalsize .btn-filter {
  width: 40% !important;
  font-size: 17px !important;
  color: white !important;
  background-color: #7bd812 !important;
  border: #ddd;
  font-family: 'Titillium-Regular';
}

.modalsize  .linestyle::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
}


/* /**/
.modalsizeTwo{
  width: auto;
  margin-left: 12%;
  margin-top:8%;

}
.modalsizeTwo .deorder-ertagsp{
  height: 100% !important;
  height: 300px !important;
  min-width:100px !important;
}
.modalsizeTwo  .modal-content {
  width: 380px !important;
}

.modalsizeTwo  .linestyle  {
  text-align: center;
  height: 220px;
  overflow-y: scroll;
  margin-bottom: 20px;
  width: 90%;

}
.modalsizeTwo .flexbox{
  height: 100%;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
	align-content: center;
} 
.modalsizeTwo .btn-filter {
  width: 40% !important;
  font-size: 17px !important;
  color: white !important;
  background-color: #7bd812 !important;
  border: #ddd;
  font-family: 'Titillium-Regular';
}

.modalsizeTwo  .linestyle::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
}
