$breakpoint-ms-surface: 1500px;
$agrabiz-green: #7bd812;

#m-information-section {
  display: flex;
  & .i-section {
    padding: 0px 17%;
    & .first-section {
      display: flex;
      justify-content: center;
      padding: 6%;
    }
    & img {
      width: 75px;
    }
    & .second-section {
      font-size: 23px;
      text-align: center;
      color: #797979;
      & .highlight {
        color: $agrabiz-green;
      }
    }
    & .description {
      text-align: center;
      color: #b1b7b7;
      font-size: 15px;
      padding-top: 7%;
    }
    & .show-more {
      color: #fba93a;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 5%;
      & span {
        cursor: pointer;
      }
      & span:first-child {
        font-size: 16px;
      }
      & span:last-child {
        font-size: 27px;
        padding-left: 2%;
        padding-bottom: 1%;
      }
    }
  }
  & .separation {
    border-left: 2px solid #e8e8e8;
    height: 160px;
    margin-top: 5%;
  }
}

@media (max-width: $breakpoint-ms-surface) {
  #m-information-section {
    & .second-section {
      font-size: 15px !important;
    }
    & .show-more {
      padding-top: 2% !important;
    }
  }
}
#membership-type {
  & .m-description {
    color: #b1b7b7;
    font-size: 15px !important;
  }
}
