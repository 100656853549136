#register-page {
  background: white;
  min-height: 100%;
  width: 100% !important;
}

#register-page .header {
  margin-bottom: 20px;
}

#register-page .register-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 4%;
}

#register-page .register-h-logo {
  width: 15%;
}

#register-page .register-h-stepper {
  width: 7%;
}

#register-page .nav-step {
  text-align: center;
  margin-top: 2%;
}

#register-content {
  padding: 80px 0 0 40px;
}

#register-footer {
  position: fixed;
  padding: 1% 10% 1% 6%;
  left: 0;
  bottom: 0 !important;
  width: 100%;
  color: rgba(120, 120, 120, 0.94) !important;
  background: #f0f0f0;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#register-footer .language-content {
  color: rgba(120, 120, 120, 0.94) !important;
}

#register-footer .language-content + img {
  display: none;
}

#register-footer .language-content a {
  margin-right: 8px;
}

#register-footer .language-content .active {
  font-weight: 700;
}

#register-content .form-control {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px;
  color: black !important;
}

#register-content .form-control:focus {
  border: 0 0 2px 2px !important;
  box-shadow: 0 2px 2px -2px #7bd812 !important;
  color: #7bd812;
}

#register-content input:focus::-webkit-input-placeholder {
  color: #7bd812 !important;
}

#register-content .input-group-prepend {
  background-color: white !important;
  border: none !important;
}

#register-content .heading,
#register-content h3,
#register-content h4,
#register-content p,
#register-content button {
  font-family: 'Titillium-Regular' !important;
}

#register-content .form-control::placeholder {
  font-size: 17px;
  color: rgba(183, 183, 183, 0.7);
}

#register-content .ic_p6off {
  background-image: url('../../static/images/icon-users/mdpi/ic_p6off.png') !important;
}

#register-content .input-group-prepend svg,
#register-content .glyphicon-eye-open,
#register-content .glyphicon-eye-close {
  font-size: 180%;
  color: rgba(183, 183, 183, 0.7);
}

#register-content .input-group:focus-within .input-group-prepend svg {
  border: 0 0 0 0 !important;
  color: #7bd812;
}

#register-content p,
#titleStep3 {
  color: rgba(183, 183, 183, 0.7) !important;
  margin-bottom: 30px;
  font-size: 22px !important;
}

/* page 1 */

#register-content .register-form-title {
  margin-top: 0%;
  margin-bottom: 15% !important;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
}

#register-content .firstText span {
  color: #626262 !important;
}

#register-content .firstTextClair span {
  color: #c3c3c3 !important;
}

.register-button-sub-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10%;
}

.register-button-sub-text {
  color: #b5b5b5;
}

.sign-in-link {
  color: #7bd812;
  padding-left: 5px;
}

.register-bottom-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #b5b5b5;
}

#register-content .container-register-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#register-content .margin-btn-top {
  margin-top: 16%;
  margin-bottom: 7%;
}

#register-content .invalid-feedback {
  font-size: 11px;
  color: red;
}

#register-content .register-form-btn {
  width: 100%;
  height: 50px;
  border-radius: 7px !important;
  background-color: #7bd812 !important;
  font-size: 17px;
  border: none;
  color: #fff !important;
}

#register-content .register-form-btn:hover {
  background-color: #b6b6b6 !important;
}

/* page 2 */

#register-content .role-txtcke {
  height: 35px;
}

#register-content .role-txtcke.first {
  margin-top: 8%;
}

#register-content .btn-group {
  width: 100%;
}

#register-content .btn-card {
  width: 18%;
  margin: 1%;
  height: 150px;
  border: transparent;
  -webkit-appearance: initial;
  background-size: 30% !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  opacity: 0.5;
}

#register-content .btn-card input {
  display: none;
}

#register-content .btn-card.active {
  background: transparent;
  opacity: 1.5;
  background-size: 90% !important;
  border: 4px solid #7bd812 !important;
}

#register-content .ic_p1off {
  background-image: url('../../static/images/icon-users/mdpi/ic_p1off.png') !important;
}

#register-content .ic_p2off {
  background-image: url('../../static/images/icon-users/mdpi/ic_p2off.png') !important;
}

#register-content .ic_p3off {
  background-image: url('../../static/images/icon-users/mdpi/ic_p3off.png') !important;
}

#register-content .ic_p4off {
  background-image: url('../../static/images/icon-users/mdpi/ic_p4off.png') !important;
}

#register-content .ic_p5off {
  background-image: url('../../static/images/icon-users/mdpi/ic_p5off.png') !important;
}

#register-content .toolbarUser {
  margin-top: 5%;
}

/* page 3 */

#register-content .titleStep3 {
  font-size: 25px;
  color: #b6b6b6;
}

#register-content .titleStep3Form {
  margin-bottom: 5%;
}

#register-content .titleStep3Form .agrabiz-select {
  margin: 37px 0 0;
}

#register-content .titleStep3Form label {
  color: #999;
  font-size: 16px;
}

#register-content .titleStep3Form .agrabiz-select .css-en01pp-control {
  border-color: transparent transparent #c6c6c6;
}

#register-content .register-step-three .top-part .federal-state {
  margin: 38px 0 17px 0 !important;
}

#register-content .register-step-three .top-part {
  margin-bottom: 2%;
}

#register-content .register-step-three .bottom-part {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buisness-text {
  margin: auto !important;
}

@media screen and (max-width: 1024px) {
  #register-content .btn-card.active {
    background-size: 100% !important;
  }
  #register-content .register-left-side {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  #register-content .register-left-side {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  #register-footer {
    margin: 0 !important;
  }
}

#register-content .user-input-wrp {
  position: relative;
}

#register-content .user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #777;
}

#register-content .user-input-wrp .inputText:invalid {
  box-shadow: none !important;
}

#register-content .user-input-wrp .inputText:focus {
  border-width: medium medium 2px;
}

#register-content .user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  color: #b6b6b6;
  top: 6px;
  left: 10px;
  transition: 0.2s ease all;
}

#register-content .user-input-wrp input:focus ~ .floating-label,
#register-content .user-input-wrp input:not(:focus):valid ~ .floating-label {
  top: 0px;
  left: 10px;
  color: #7bd812;
  font-size: 10px;
  opacity: 1;
}

/** CSS Checkbox **/

#register-content input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Styling checkbox */
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 2px solid #7bd812;
  margin-right: 10px;
}

#register-content input[type='checkbox']:checked {
  background-color: #7bd812;
  border-color: #7bd812;
}

#register-content input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Styling checkbox */
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: 2px solid #7bd812;
}

#register-content input[type='checkbox']:checked {
  background-color: #7bd812;
  border-color: #7bd812;
}

#register-content .textColor {
  color: #7bd812;
  font-weight: 600;
}

.AccpectCon-btn {
  width: 50% !important;
  height: 40px !important;
  border-radius: 10px !important;
  background-color: #7bd812 !important;
  font-size: 17px !important;
  color: #fff !important;
}

.btn-center-Accept {
  width: 100% !important;
  height: 50px !important;
  text-align: center !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.btn-center-Accept.add-company {
  padding-top: 7%;
}

/** end block checkbox**/

.registration-top-part {
  display: flex;
  justify-content: center;
  width: 100%;
  /* align-items: center; */
}

.registration-top-container {
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.registration-top-title {
  margin: 0 0 2%;
  font-size: 17px !important;
}

.registration-top-sub-parag {
  padding-top: 2%;
  color: #b5b5b5 !important;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.5;
}

.registration-flex-box {
  display: flex;
  flex-direction: column;
}

.registration-flex-inner-box {
  display: flex;
  flex-direction: row !important;
}

.registration-top-container .registration-top-sub-parag:last-child {
  margin-bottom: 10%;
}

#register-content .bottom-part button {
  margin: 7% 0 3%;
}

#register-content {
  margin-bottom: 75px !important;
}

.registration {
  font-family: 'Titillium-Regular' !important;
  font-size: 17px !important;
}

.col-sm-offset-1 {
  margin-left: 8.33333333%;
}

.col-sm-offset-3 {
  margin-left: 25%;
}

.col-sm-offset-4 {
  margin-left: 33.33333333%;
}

.col-sm-offset-2 {
  margin-left: 16.66666667%;
}

.input-margin {
  margin-left: 18.66666667% !important;
}

.register-step-three-docs .modal-dialog {
  height: 90% !important;
  width: 50% !important;
}

.register-step-three-docs .modal-content {
  height: 100%;
  width: 100%;
}

.register-step-three-docs .modal-header {
  height: 3%;
}

.register-step-three-docs .modal-pdfCondition {
  height: 97%;
  width: 100%;
  margin: 0;
}

.register-step-three-docs .modal-pdfCondition.modal-body object {
  height: 85%;
}

.register-step-three-docs .modal-pdfCondition.modal-body .btn-center-Accept {
  height: 15% !important;
}

#register-content .heading + .role-txt,
#register-content .role-txtcke {
  font-size: 17px !important;
  color: #b5b5b5 !important;
  font-family: 'Titillium-Regular' !important;
}

.download-pdf {
  display: none;
}

.register-step-three-docs .modal-pdfCondition.modal-body object {
  display: block;
}

#register-content .btn-group .btn-card {
  background: white;
}

@media (max-width: 374px) {
  #register-page {
    padding: 0 4% !important;
  }
  #register-content .role-txtcke {
    font-size: 15px !important;
  }
  #register-content .titleStep3 {
    font-size: 16px !important;
  }
  #register-content .bottom-part button {
    font-size: 15px !important;
  }
  #regular-input.agrabiz-input input {
    font-size: 15px !important;
  }
  #regular-input.agrabiz-input label {
    font-size: 12px !important;
  }
}

@media (max-width: 991px) {
  #register-page {
    padding: 0 5% !important;
  }
  .register-step-three-docs .modal-pdfCondition.modal-body object {
    display: none;
  }
  #register-page {
    padding: 0 7%;
  }
  .registration-top-container {
    width: 80%;
  }
  .register-bottom-text {
    text-align: center;
  }
  .registration-top-container .registration-top-sub-parag {
    font-size: 15px;
    text-align: justify;
  }
  #register-content .registration {
    font-size: 15px !important;
  }
  .registration-top-title {
    text-align: center;
  }
  #register-content .registration-top-title {
    font-size: 15px !important;
  }
  #register-content .registration-top-container {
    width: 100% !important;
    padding: 0 5%;
  }
  #register-footer {
    padding: 3% 10% 3% 6% !important;
    justify-content: start;
  }
  #register-content {
    padding: 0 2%;
  }
  #register-content .register-form-btn {
    width: 100%;
    height: 42px !important;
    margin-top: 11% !important;
  }
  #register-content .btn-group .btn-card {
    background-size: 60% !important;
  }
  #register-content .btn-card {
    width: 16.5% !important;
    margin: 0% !important;
    height: 80px !important;
  }
  #register-content .titleStep3 span {
    font-size: 20px !important;
    color: #cfcfcf;
    font-weight: 100;
  }
  #register-content .heading + .role-txt {
    font-size: 17px !important;
  }
  .register-step-three-docs .modal-dialog {
    width: 95% !important;
  }
  #register-page .register-h-logo {
    width: 35%;
  }
  #register-page .register-h-stepper {
    width: 17%;
  }
  #register-page .register-header {
    padding: 5% 6%;
  }
  .language-content {
    padding-left: 25px;
  }
  .login-language-screen .language-content {
    padding-left: 0;
  }
  .login-language-screen .language-content:first-child {
    padding-right: 10px;
  }
  #register-footer .language-content + img {
    width: 19px;
    padding-top: 2px;
    display: block;
  }
  #register-footer .language-content .language-text {
    display: none;
  }
  /* modal download file */
  .download-pdf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85%;
  }
  .download-pdf .file-icon {
    width: 150px;
    height: 150px;
    background: #7ad819;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .download-pdf img {
    width: 80px;
    height: 80px;
  }
  .download-pdf .download-file {
    margin: 12% 0 0%;
  }
  .download-pdf .download-file a {
    color: #7ad819;
    font-size: 19px;
  }
  .download-pdf .browse-file {
    padding: 15% 0;
  }
  .download-pdf .browse-file a {
    border: 1px solid #c2c2c2;
    color: #9a9a9a;
    border-radius: 2px;
    padding: 3px 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #register-page .register-h-logo {
    width: 25%;
  }
  #register-page .register-h-stepper {
    width: 10%;
  }
  #register-content .btn-card.active {
    background-size: 45% !important;
  }
}

#register-content .btn-group .btn-card {
  background-size: 60% !important;
}

@media (max-width: 768px) {
  .registration-step-one-form .register-number,
  .registration-step-one-form .register-pin,
  .registration-step-one-form .register-submit {
    width: 100%;
    margin: 0 !important;
    padding-bottom: 10px;
  }
  .registration-step-one-form .register-bottom-section {
    margin: 0 !important;
  }
  .register-button-sub-text {
    padding-bottom: 5%;
  }
  .policy-container-two {
    display: block;
  }
  .policy-container-one {
    display: none;
  }
}

@media (min-width: 991px) {
  .step-two-next-btn button {
    display: none;
  }
  .register-btn button {
    display: block;
  }
}

@media (max-width: 992px) {
  .step-two-next-btn button {
    display: block;
  }
  .register-btn button {
    display: none;
  }
}

@media (min-width: 768px) {
  .policy-container-two {
    display: none;
  }
  .policy-container-one {
    display: block;
  }
}
