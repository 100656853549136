#contact .contact-details {
    margin-top: 50px;
}
#contact .contact-info {
    font-size: 23px !important;
    color: #7bd812 !important;
    padding: 0;
    margin: 0;
    margin-top: 30px;
}
#contact .contact-value {
    object-fit: contain;
    font-family: 'Titillium-Regular'!important;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #626262; 
}

#contact .btn-call {
    margin-top: 20rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80% !important;
}