.list-tour {
  height: 80% !important;
}

.card-list {
  background: url('../../../static/images/backgroundTruckField.svg');
  background-size: contain;
  height: 76%;
  overflow-y: scroll;
  overflow: auto;
  max-height: 600px;
  overflow-x: hidden;
}

.card-list > div {
  height: 20%;
}

.card-list div .row {
  height: 100%;
}

.list-tour-animal {
  height: auto !important;
}

.list-truck {
  height: 80% !important;
}

.list-truck-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}

.list-truck-wrapper .tours-image {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.truck-management {
  height: 35% !important;
}

.list-tour .list-group-item {
  height: 111px;
  width: 100% !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.list-tour .list-group-item > .row {
  height: 100% !important;
}

.tour-text-groupe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.truck-text-groupe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.tours-top-container {
  display: flex;
  justify-content: center;
  padding-bottom: 2%;
}

.tours-top-container .tour-image.menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tours-top-container .tours-image > img {
  width: 75px;
}

.tours-top-container .tours-image {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tour-name {
  font-size: 20px;
  font-weight: 600;
  color: #7bd812;
  font-family: 'Titillium-Regular';
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
}

.tour-item {
  font-family: 'Titillium-Regular';
  color: #888888;
  float: left;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
  font-size: 15px;
}

.tour-item-twoval {
  font-family: 'Titillium-Regular';
  float: left;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.7em;
  color: #868686;

}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
  .tour-item-twoval {
    font-size: 1vw;
  }
}

.bar-icon {
  height: 100%;
  position: relative;
}

.icon-truck {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  margin: 0;
  display: block;
  content: ' ';
  background-size: 28px 28px;
  height: 28px;
  width: 28px;
}

.menu-icon {
  background-image: url('../../../static/images/admin/icons_final/menu.svg');
}

.edite-icon {
  background-image: url('../../../static/images/admin/icons_final/editIcon.svg');
}

.tour-information-block {
  height: 49%;
  margin-bottom: 15px;
}

.truck-information-block {
  height: 49%;
}

.tour-management-info {
  display: flex;
}

.first-block {
  height: 10% !important;
  display: flex;
  font-size: 30px;
  color: #868686;
  font-family: 'Titillium-Regular';
}

.truck-management {
  display: flex;
  font-size: 30px;
  color: #868686;
  font-family: 'Titillium-Regular';
}

.close-block-icon:before {
  display: block;
  content: ' ';
  background-image: url('../../../static/images/admin/icons_final/arrow-back.svg');
  background-size: 28px 28px;
  width: 16px;
  height: 28px;
}

.tour-management-info {
  height: 8%;
  align-items: center;
}

.info-not-assigned {
  font-size: 23px;
  color: #707070;
  font-family: 'Titillium-Regular';
  padding-right: 10px;
}

.info-number {
  font-size: 23px;
  font-weight: 600;
  color: #7bd812;
}

.truck-capacity-groupe {
  position: relative;
  height: 100%;
}

.truck-capacity-block {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.truck-capacity {
  font-size: 65px;
  font-weight: normal;
  color: #7bd812;
}

.truck-full-capacity {
  font-family: 'Titillium-Regular';
  font-size: 31.5px;
  font-weight: 100;
  color: #707070;
}

.truc-title-block {
  height: 10%;
  display: flex;
  justify-content: space-between;
}

.select-truck {
  height: 34px;
  color: #7bd812;
}

.button-block-field {
  position: absolute;
  width: 100%;
  bottom: 14px;
  display: flex;
  justify-content: center;
  left: 0;
}

.truck-complete {
  border-radius: 10px !important;
  box-shadow: 0px 21px 24px 0 rgba(0, 0, 0, 0.08) !important;
  opacity: 0.5;
  background-color: #7bd812 !important;
  line-height: 2.15 !important;
  color: #ffffff !important;
  font-family: 'Titillium-Regular' !important;
  width: 45% !important;
}

.truck-completed {
  opacity: 1 !important;
}

@media screen and (max-width: 1182px) {
  .truc-title-block {
    height: 30%;
    display: block;
    justify-content: none;
  }
  .list-tour {
    height: 55% !important;
  }
}


.view-producer-b1-new .accordions2 {
  text-align: center;
  width: 100%;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.15);
  background: #c1c1c1;
  border-radius: 1px;
  color: white;
  height: 38px;
  overflow: hidden;
  border-radius: 7px;
  right: 1%;
    border: 1px solid #c1c1c1 !important;

    border-radius: 5px;
    font-size: 18px;
    color: #fff;
    line-height: 1.4;
    border: 1px solid transparent;
    padding: 6px 12px;
}

.view-producer-b1-new .accordions2.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.view-producer-b1-new .accordions2:hover {
  height: 120px;
  transition: 0.2s;
  background: white;
  color: #7bd812;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2% 0;
  margin-bottom: 42px;
}

.view-producer-b1-new .accordions2:hover .accordions-toggle2 {
  color: #7bd812;
  display: none !important;
  z-index: 100;

}

.view-producer-b1-new .accordions2:hover .accordions-inner2 {
  padding: 0;
  width: 100%;
}

.view-producer-b1-new .accordions-inner2 {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.view-producer-b1-new .accordions-toggle2 {
  border-radius: 3px;
  display: block;
  text-align: center;
  font-size: 15px;
  text-decoration: none;
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-producer-b1-new .accordions2:hover .accordions-inner2 > .accordions-text2 {
  padding: 0 10px;
  width: 100%;
}

.view-producer-b1-new .accordions-inner2 > .accordions-text2:hover {
  background: #bbbaba;
  color: white !important;
}

.view-producer-b1-new .accordions-inner2 > .accordions-text2 {
  font-size: 20px !important;
  font-weight: 300 !important;
  color: #767676 !important;
  cursor: pointer;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
}

@media (min-width: 1200px) and (max-width: 1900px) {
  .view-producer-b1-new .accordions-inner2 > .accordions-text2 {
    font-size: 12px !important;
  }
}

.tour-management-loading-modal .modal-t-m-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tour-management-loading-modal .modal-body {
  padding: 15px 15px 20px;
}

.tour-management-loading-modal .modal-header .close span {
  font-size: 30px;
}

.tour-management-modal .truck-modal.modal-body {
  height: 85% !important;
  margin-top: 0 !important;
}

.tour-management-modal .modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.tour-management-modal .modal-dialog {
  width: 66%;
  height: 74%;
  margin-bottom: auto;
  margin-top: 7%;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .tour-management-modal .modal-dialog {
    height: 85% !important;
    margin-top: 5%;
  }
}

.tour-management-modal .modal-content {
  height: 100%;
}

.truck-modal {
  height: 80%;
}

.tour-management-modal-two .modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.tour-management-modal-two .modal-dialog {
  width: 66%;
  height: 74%;
  margin-bottom: auto;
  margin-top: 7%;
}

.tour-management-modal-two .modal-content {
  height: 83%;
}

@media (max-width: 1200px), (max-height: 880px) {
  .tour-management-modal-two .modal-dialog {
    height: 85% !important;
  }
}

.truck-modal-two {
  height: 80%;
}

.icon-new-transport {
  display: block;
  content: ' ';
  background-size: 28px 28px;
  width: 60px;
  height: 30px;
}

.Modal-add-truck-title {
  display: flex;
  justify-content: center;
  height: 100%;
}

/* .Modal-add-truck-title>h5 {
  margin: 23px;
  margin-left: 0;
  margin-right: 0;
  font-size: 30px !important;
  text-align: center !important;
  color: #707070 !important;
  font-size: 30px !important;
} */

.eartag-modal .modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.eartag-modal .modal-dialog {
  width: 23%;
  height: 50%;
  margin-bottom: auto;
  margin-top: 7%;
}

.eartag-modal .modal-content {
  height: 50%;
}

.add-signature .modal-content {
  height: 100%;
}

.remove-selected-eartag .text-submit {
  padding: 1px 7px;
  border-radius: 5px;
  cursor: pointer;
}

.remove-selected-eartag .text-submit:first-child {
  border: 1px solid #868686;
  margin-left: 5%;
}

.remove-selected-eartag .text-submit:last-child {
  background: #868686;
  margin-left: 5%;
  color: white;
}

.eartag-list-remove-modal {
  height: 100%;
}

.eartag-list-remove-modal .eartag-header-modal {
  height: 20%;
}

.eartag-list-remove-modal .eartag-modal-body {
  height: 55%;
  padding: 15px 25px;
  margin: 0 !important;
}

.eartag-list-remove-modal .eartag-modal-footer {
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.remove-selected-eartag {
  display: flex;
  justify-content: center;
  width: 100%;
}

.eartag-modal-footer.modal-footer {
  padding: 0 !important;
  border: none !important;
}

.capacity-modal .modal-content {
  height: 50%;
  width: 50%;
}

.capacity-modal .modal-dialog {
  width: 23%;
  height: 50%;
  margin-bottom: auto;
  margin-top: 7%;
}

.eartag-modal-body {
  height: 90%;
}

#message-accept-deny .eartag-modal-body {
  text-align: center;
}

.button-truck-field {
  text-align: center;
}

.eartag-header-modal {
  height: 0%;
  border-bottom: none !important;
}

.Number-Popup-modal .modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.Number-Popup-modal .modal-dialog {
  width: 500px !important;
  height: 400px !important;
  margin-bottom: auto;
  margin-top: 7%;
}

.Number-Popup-modal .modal-content {
  width: 100% !important;
  height: 100%;
}

.Number-Popup-modal .Number-Flex {
  width: 100% !important;
  height: 300px;
}

.Number-Popup-modal .Number-Flex-b1 {
  height: 70%;
  width: 100% !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.Number-Popup-modal .Number-Flex-b2 {
  width: 95% !important;
  height: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  bottom: -3%;
}

.Number-Popup-modal .Number-Flex-b1-arrow {
  height: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.Number-Popup-modal .Number-Flex-b1-arrow .svg-arrow {
  margin-top: 10px;
}

.Number-Popup-modal .Block-arrow {
  height: 60px;
  width: 60px;
  margin-bottom: 8px;
  background-color: #7bd812;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 10px !important;
  box-shadow: 0px 21px 24px 0 rgba(0, 0, 0, 0.08) !important;
  opacity: 0.8 !important;
  background-color: #7bd812;
}

.Number-Popup-modal .Block-arrow img {
  width: 45px;
}

.Number-Popup-modal .inputNumber {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.Number-Popup-modal .inputNumberStyle {
  border-radius: 10px;
  border: 2px solid #77d609 !important;
  height: 102px;
  width: 190px;
  font-size: 100px;
  text-align: center;
}

.Number-Popup-modal .add-Number {
  width: 50% !important;
  height: 90%;
  font-size: 23px !important;
  padding: 1px !important;
  border-radius: 10px !important;
  box-shadow: 0px 21px 24px 0 rgba(0, 0, 0, 0.08) !important;
  opacity: 0.8 !important;
  background-color: #7bd812;
  line-height: 2.15 !important;
  color: #ffffff !important;
  font-family: 'Titillium-Regular' !important;
}

.tour-planning-box .tour-planning-top-part {
  height: 93%;
  overflow-y: scroll;
}

.tour-planning-box .tour-planning-top-part .card-scene .card-container {
  box-shadow: none;
}

/* .tour-planning-box .tour-planning-top-part .card-scene {
  border-radius: 7px;
} */

.smooth-dnd-draggable-wrapper .card {
  box-shadow: 0px 0px 9px 1.5px rgba(0, 0, 0, 0.06),
    0 1px 0px 0 rgba(0, 0, 0, 0.02);
}

.tour-planning-box .tour-planning-bottom-part {
  height: 7%;
  display: flex;
  align-items: flex-end !important;
  justify-content: center;
}

.btn-complete-outer.tour-planning-bottom-part button:first-child {
  width: 60%;
  margin-right: 2%;
}

.btn-complete-outer.tour-planning-bottom-part button:nth-child(2) {
  width: 35%;
}

.truck-list-blc-b1 {
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
}

.truck-list-blc-b2 {
  height: 100%;
  margin-top: 15px;
}

.truck-list-blc-b3 {
  height: 100%;
  margin-top: 0px;
}

/* accordion view */

.accordion-custom:hover
  .accordion-custom-item:hover
  .accordion-custom-item-content,
.accordion-custom
  .accordion-custom-item--default
  .accordion-custom-item-content {
  height: 6em;
}

.accordion-custom-item-content,
.accordion-custom:hover .accordion-custom-item-content {
  height: 0;
  overflow: hidden;
  transition: height 0.25s;
}

.accordion-custom h1 {
  visibility: visible;
  opacity: 1;
}

.accordion-custom:hover h1 {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.accordion-custom {
  padding: 0;
  margin: 0 auto;
  width: 20%;
  font-family: 'PT Sans' sans-serif;
}

.accordion-custom .accordion-custom-item {
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  border-bottom: 1px solid #666;
  padding: 1em;
  color: #eee;
}

.accordion-custom h1 {
  margin: 0;
}

.accordion-custom .accordion-custom-item {
  background-color: hsl(200, 80%, 60%);
  background-image: linear-gradient(
    -90deg,
    hsl(200, 80%, 60%),
    hsl(200, 80%, 45%) 2em,
    hsl(200, 80%, 60%)
  );
}


