.selectCompany {
  color: #7bd812;
  background: #ffff;
  font-size: 1em;
  font-weight: 700;
  padding: 0 -10px;
}

.selectCompany .css-dvua67-singleValue {
  color: #7bd812;
}

.selectCompany .css-18rs4dp-menu {
  z-index: 999 !important;
}

.selectLang {
  color: #7bd812;
}

.select-company-nav .css-utw1zs-control .css-1hwfws3 {
  display: flex;
  justify-content: center;
}

.selectCompany .css-14rsqe7-control .css-1hwfws3 {
  display: flex;
  justify-content: center;
}
.css-utw1zs-control .css-1wy0on6,
.css-14rsqe7-control .css-1wy0on6 {
  display: block !important;
}
.css-14rsqe7-control {
  width: 67px !important;
}
.hidden {
  display: none;
}

.logout-btn .icon:hover svg {
  fill: #fff;
}

.logout-btn .icon {
  border-radius: 50%;
  background: #fff;
  height: 50px;
  width: 50px;
  padding: 9px 6px !important;
}

.logout-btn .icon:hover {
  background: #7bd813 !important;
}

.AddCompanyNbr .modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.AddCompanyNbr .modal-dialog {
  width: 500px !important;
  height: 500px !important;
  margin-bottom: auto;
  margin-top: 7%;
}

.AddCompanyNbr .modal-content {
  width: 100% !important;
  height: 100%;
}

.AddCompanyNbr .Number-Flex {
  width: 100% !important;
  height: 300px;
}

.AddCompanyNbr .Number-Flex-b1 {
  height: 70%;
  width: 100% !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.AddCompanyNbr .Number-Flex-b2 {
  width: 100% !important;
  height: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.AddCompanyNbr .Number-Flex-b1-arrow {
  height: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.AddCompanyNbr .Block-arrow {
  height: 60px;
  width: 60px;
  margin-bottom: 8px;
  color: #7bd812;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 10px !important;
  box-shadow: 0px 21px 24px 0 rgba(0, 0, 0, 0.08) !important;
  opacity: 0.8 !important;
  background-color: #7bd812;
}

.AddCompanyNbr .inputNumber {
  margin-right: 20px;
  border-bottom: 6px solid #77d609 !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.AddCompanyNbr .inputNumberStyle {
  height: 102px;
  width: 190px;
  font-size: 100px;
  text-align: center;
}

.AddCompanyNbr .add-Number {
  width: 50% !important;
  height: 90%;
  font-size: 23px !important;
  padding: 1px !important;
  border-radius: 10px !important;
  box-shadow: 0px 21px 24px 0 rgba(0, 0, 0, 0.08) !important;
  opacity: 0.8 !important;
  background-color: #7bd812;
  line-height: 2.15 !important;
  color: #ffffff !important;
  font-family: 'Titillium-Regular' !important;
}

.navbar-container {
  display: flex;
  align-items: center;
  margin-left: 300px;
  justify-content: space-between;
  padding: 20px 30px;
}

.navbar-container .navbar-right-section {
  display: flex;
  align-items: center;
}

.navbar-container .navbar-left-section {
  font-size: 25px;
  color: #767676;
}

.navbar-container .navbar-item {
  padding: 0 10px;
}

.navbar-container .navbar-item .css-rmf9zv-menu {
  z-index: 999 !important;
}
