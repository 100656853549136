#admin .ok-icon::after {
  padding: 5px;
  content: url('../../../static/images/admin/calque-9.png');
}

#admin .add-icon::after {
  padding: 5px;
  content: url('../../../static/images/admin/add.png');
}

#admin .update-icon::after {
  padding: 5px;
  content: url('../../../static/images/admin/d-2-bdaceccc-2-be-6098-df-0547-debfa-98-e-2.png');
}

#admin .update-icon {
  border-right: 2px solid #ddd !important;
  margin-right: -6px !important;
}

#admin .update-icon-two::after {
  padding: 5px;
  content: url('../../../static/images/admin/d-2-bdaceccc-2-be-6098-df-0547-debfa-98-e-2.png');
}

#admin .update-icon-two {
  margin-right: -6px !important;
}

#admin .update-icon-two-Bar {
  margin-top: 3px;
  height: 80%;
  border-right: 2px solid #ddd !important;
}

#admin .padding-icon {
  padding-right: 75px;
}

#admin .box-icon1 {
  margin-left: 27% !important;
  margin-right: 49% !important;
}

#admin .box-icon2 {
  margin-left: 27% !important;
  margin-right: 29% !important;
}

#admin .reload-icon::after {
  padding: 5px;
  content: url('../../../static/images/admin/calque-10.png');
}

#admin .delete-icon::after {
  content: url('../../../static/images/admin/cancel-cross-delete-icon-128.png');
}

.quality-program-content .box:hover {
  background-color: #7bd812;
}

.quality-program-content .active {
  background-color: #7bd812;
}

/*Tabs css*/

#admin-setting .nav-tabs {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}

#admin-setting .person-title-container {
  margin-top: -8%;
  margin-bottom: 8%;
}

#admin-setting .person-title-container h4,
#admin-setting .person-title-container p {
  color: white;
  text-transform: inherit;
  font-size: 20px;
  margin-left: -30px;
  margin-top: 10px;
}

#admin-setting .card {
  margin: 0 0px 36px;
}

#admin-setting .setting-users .active {
  background-color: #7bd812;
}

#admin-setting .tab-content .card .header {
  margin: 0;
}

#admin-setting .setting-users .box-action {
  height: auto;
  text-align: right;
  margin: 1px 0px -1px 0px;
  padding: 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 0px;
  background-color: #f6f6f6;
  box-shadow: 0 0 8px 0 rgba(97, 97, 97, 0.18);
}

#admin-setting .setting-users .person-txt {
  color: white !important;
  margin: auto;
  font-size: 13.5px;
  margin-left: 4%;
  font-weight: 500;
  text-align: left;
}

#admin-setting .setting-users .person-txt3 {
  color: white !important;
  margin: auto;
  margin-left: 4%;
  text-align: left;
}

#admin-setting .setting-users .person-title {
  color: white !important;
  margin: auto;
  font-size: 16.5px;
  margin-left: 4%;
  font-weight: 500;
  text-align: right;
}

#admin-setting .txt-right-fixed {
  position: absolute;
  right: 0px;
  background: white !important;
}

#admin-setting .txt-right-fixed a {
  opacity: 0.41;
  font-size: 50px;
  font-weight: 100;
  padding: 0px;
  background-color: white !important;
  color: rgba(156, 156, 156, 0.41);
  top: 3%;
}

#admin-setting .setting-users .contact-info {
  padding: 4% !important;
}

#admin-setting .setting-users .person-icon::before {
  content: url('../../../static/images/admin/person-icon.png');
}

#admin-setting a::before {
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px;
}

#admin-setting a.setting-tab span.tab-title {
  position: relative;
  color: #767676;
  bottom: 3px;
}

#admin-setting a.setting-tab.active span.tab-title {
  color: #7bd812;
}

#admin-setting a.setting-tab.tab-Member::before {
  content: url('../../../static/images/admin/member_ship/handshake.png');
}

#admin-setting a.setting-tab.tab-Member.active::before {
  content: url('../../../static/images/admin/member_ship/handshake-green.png');
}

#admin-setting a.setting-tab.tab-company::before {
  content: url('../../../static/images/admin/company.png');
}

#admin-setting a.setting-tab.tab-company.active::before {
  content: url('../../../static/images/admin/company-green.png');
}

#admin-setting a.setting-tab.tab-users::before {
  content: url('../../../static/images/admin/users.png');
}

#admin-setting a.setting-tab.tab-users.active::before {
  content: url('../../../static/images/admin/users-green.png');
}

#admin-setting a.setting-tab.tab-security::before {
  content: url('../../../static/images/admin/security.png');
}

#admin-setting a.setting-tab.tab-security.active::before {
  content: url('../../../static/images/admin/security-green.png');
}

@media (max-width: 1366px) {
  #admin .setting-company .form-label {
    font-size: 12px;
    margin-top: 20px !important;
  }
  #admin-setting .txt-right-fixed a {
    padding: 11%;
  }
  #admin-index .col-md-6 {
    width: 1%;
  }
  #admin-index .col-md-5 {
    margin-left: -140px;
  }
  .col-md-4 {
    padding-left: 32pxpx;
  }
}

#admin-setting .setting-tab {
  background-position: 50% 5px !important;
  font-size: 20px;
  line-height: 1.7;
  letter-spacing: 0.02em;
  font-weight: 300;
  background-size: 50px 50px;
  background-repeat: no-repeat !important;
  background-color: none !important;
  margin-right: 20px;
  margin-left: 20px;
  height: 60px;
}

@media screen and (min-width: 1566px) {
  #admin-setting .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    color: #7bd812;
    background-color: none;
    border: 2px solid #7bd812;
    cursor: default;
    font-weight: 600;
  }
  #admin-setting .text-centered {
    text-align: center !important;
    font-size: 33px;
  }
  #admin .setting-company .form-label {
    font-family: 'Titillium-Regular' !important;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-top: 3%;
    color: #2d3342;
    margin-top: 20px !important;
  }
  #admin-setting .use-hit-pw {
    font-family: 'Titillium-Regular' !important;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-top: 5%;
    margin-left: 30px;
    margin-right: -63px;
    padding-left: 30px;
    color: #2d3342;
  }
  .modif-pw {
    font-family: 'Titillium-Regular' !important;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-top: 5.5%;
    margin-left: 4%;
    color: #2d3342;
  }
  #admin-setting .form-inputs-info {
    margin-left: -5%;
    width: 50%;
  }
  #admin-setting .form-inputs-info-sec {
    margin-left: -2%;
    margin-top: 1%;
    width: 56%;
  }
  #admin-setting .form-inputs-vet {
    margin-left: -10%;
    width: 50%;
  }
  #admin-setting .form-info {
    width: 50%;
  }
  #admin-setting .card .content {
    padding: 15px 15px 25px 15px;
  }
  #admin-setting .card .content {
    padding: 15px 15px 25px 15px;
  }
  #admin-setting .form-vet {
    margin-left: -8%;
    width: 50%;
  }
  #admin-setting .form-btn {
    width: 100%;
    height: 50px;
    background-color: #7bd812 !important;
    border-color: #7bd812;
    border-radius: 5px;
    font-size: 18px;
    color: #fff !important;
  }
  #admin-setting .form-btn-sec {
    width: 100%;
    height: 50px;
    background-color: #7bd812 !important;
    border-color: #7bd812;
    border-radius: 5px;
    font-size: 18px;
    color: #fff !important;
    margin-left: -38%;
    margin-top: 50%;
    margin-bottom: 20px;
  }
  #admin-setting .setting-tab {
    background-position: 50% 5px !important;
    font-size: 20px;
    line-height: 1.7;
    letter-spacing: 0.02em;
    font-weight: 300;
    background-size: 50px 50px;
    background-repeat: no-repeat !important;
    background-color: none !important;
    margin-right: 12px;
    margin-left: 12px;
    height: 60px;
  }
  #admin-setting .setting-tab a {
    height: 100%;
    color: #767676;
    border-top: transparent !important;
    border-right: transparent !important;
    border-left: transparent !important;
  }
  #admin-setting .setting-tab a:hover {
    background-color: transparent !important;
  }
  #admin-setting .setting-users .person-role-txt {
    margin-top: inherit;
  }
  #admin-setting .card-title-sec {
    object-fit: contain;
    font-size: 19px;
    text-align: left;
    color: #7bd812;
    margin-bottom: 47px;
    margin-top: 2%;
    margin-left: 2%;
  }
  /* Description style  */
  #setting-description .billedM {
    font-family: 'Titillium-Regular' !important;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 30px -52px 0px 0px;
  }
  #setting-description .billedY {
    font-family: 'Titillium-Regular' !important;
    font-size: 12px;
    font-weight: 600;
    margin: 30px 0px 0px -84px;
    text-transform: uppercase;
  }
  #setting-description .rowsMargin {
    margin: 20px 0px -10px 22px;
  }
  #setting-description .freePlanText {
    font-family: 'Titillium-Regular' !important;
    font-size: 25px;
    font-weight: 600;
    margin: 0px 106px 0px 0px;
    color: #767676;
    align-content: center;
  }
  #setting-description .AutoUse {
    font-family: 'Titillium-Regular' !important;
    font-size: 14px;
    width: 503px;
    height: 64px;
    margin: 11px 90px 55px 0px;
    color: #767676;
    align-content: center;
  }
  #setting-description .form-btn-m {
    width: 192px;
    height: 54px;
    background-color: #7bd812 !important;
    border-color: #7bd812;
    border-radius: 30px;
    font-size: 18px;
    color: #fff !important;
    margin-top: 80px;
    margin-bottom: 7px;
  }
  #setting-description .ft-text {
    color: #2d3342;
    margin-left: -25px;
    margin-top: 2px;
    margin-left: -65px;
  }
  #setting-description .ft-rows {
    margin: 0px 0px 0px 0px;
  }
  .container-description {
    margin: 20px 0px 0px 0px;
  }
  /* .bg {
    background-image: url('../../../static/images/admin/member_ship/bgMember.png');
    height: 82.2vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  } */
}

@media screen and (max-width: 1566px) {
  #admin-setting .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    color: #7bd812;
    background-color: none;
    border: 2px solid #7bd812;
    cursor: default;
  }
  #admin-setting .text-centered {
    text-align: center !important;
    font-size: 24px;
  }
  #admin-index .col-md-3 {
    width: 32.5%;
  }
  #admin-index .col-md-6 {
    width: 60%;
  }
  #admin-index .col-md-5 {
    width: 56%;
  }
  .container-description {
    margin: 40px 0px 0px 0px;
    width: 100% !important;
  }
  .pricing-section {
    min-width: 100% !important;
  }
  #admin-setting .setting-tab {
    background-position: 50% 5px !important;
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    margin-right: 12px;
    margin-left: 12px;
  }
  #admin-setting .setting-tab a {
    height: 100%;
    color: #767676;
    border-top: transparent !important;
    border-right: transparent !important;
    border-left: transparent !important;
  }
  #admin-setting .setting-tab a:hover {
    background-color: transparent !important;
  }
  #admin-setting .form-info {
    margin-left: 1%;
    width: 100%;
  }
  #admin-setting .form-vet {
    margin-left: 2.5%;
    margin-top: 3%;
    width: 100%;
  }
  #admin-setting .form-inputs-info {
    margin-left: 28px;
    width: 62%;
  }
  #admin-setting .form-inputs-info-txt {
    width: 70%;
  }
  #admin-setting .form-inputs-vet {
    margin-left: 24px;
    width: 59%;
  }
  #admin-setting .form-btn {
    width: 100%;
    height: 50px;
    background-color: #7bd812 !important;
    border-color: #7bd812;
    border-radius: 5px;
    font-size: 18px;
    color: #fff !important;
    margin-left: -282%;
    margin-bottom: 20%;
    margin-top: 20%;
  }
  #admin-setting .form-btn-sec {
    width: 100%;
    height: 50px;
    background-color: #7bd812 !important;
    border-color: #7bd812;
    border-radius: 5px;
    font-size: 18px;
    color: #fff !important;
    margin-left: -38%;
    margin-top: 50%;
  }
  #admin-setting .card-title-sec {
    object-fit: contain;
    font-size: 17px;
    text-align: left;
    color: #7bd812;
    margin-bottom: 57px;
    margin-top: 1%;
    /* margin-left: 1%; */
    padding-left: 0;
    white-space: nowrap;
  }
  #admin-setting .use-hit-btn {
    margin-top: -2%;
  }
  #admin-setting .modif-pw {
    font-family: 'Muller' !important;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-top: 5%;
    margin-left: 4%;
    color: #2d3342;
  }
  #admin-setting .form-inputs-info-sec {
    margin-left: -5%;
    margin-top: 0%;
    width: 50%;
  }
  /* Description style  */
  #setting-description .billedM {
    font-family: 'Titillium-Regular' !important;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 30px -47px 0px 0px;
  }
  #setting-description .billedY {
    font-family: 'Titillium-Regular' !important;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 30px 0px 0px -125px;
  }
  #setting-description .rowsMargin {
    margin: 20px 0px 0px 22px;
  }
  #setting-description .freePlanText {
    font-family: 'Titillium-Regular' !important;
    font-size: 28px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    color: #2d3342;
    align-content: center;
  }
  #setting-description .Surprisingly {
    font-family: 'Titillium-Regular' !important;
    font-size: 14px;
    width: 800px;
    height: 49px;
    margin: 0px -28px 2px 0px;
    color: #2d3342;
    align-content: center;
  }
  #setting-description .form-btn-m {
    width: 192px;
    height: 54px;
    background-color: #7bd812 !important;
    border-color: #7bd812;
    border-radius: 30px;
    font-size: 18px;
    color: #fff !important;
    margin-top: 50px;
  }
  #setting-description .ft-text {
    color: #2d3342;
    margin-left: -25px;
    margin-top: 2px;
    margin-left: -65px;
  }
  #setting-description .ft-rows {
    margin: 0px 0px 0px 0px;
  }
  .container-description {
    margin: 20px 0px 0px 140px;
  }
  /* .bg {
    background-image: url('../../../static/images/admin/member_ship/firma.png');
    height: 200vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 117% 125%;
  } */
  #admin-member .pricing-bottom-business {
    color: #ffffff;
  }
  #admin-member .pricing-bottom-all {
    color: #767676;
  }
  #setting-description .AutoUse {
    font-family: 'Titillium-Regular' !important;
    font-size: 14px;
    width: 503px;
    height: 64px;
    margin: 11px 90px 55px 2px;
    color: #767676;
    align-content: center;
  }
}

#admin-member .text-center {
  text-align: center !important;
  margin-top: 30px;
}

.text-center-block-sketch {
  width: 100% !important;
}

.text-center-b1-sketch {
  width: 100% !important;
}

.sign .card {
  box-shadow: 0px 0px 9px 1.5px rgba(0, 0, 0, 0.06),
    0 1px 0px 0 rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 0px;
}

.text-center-block-sketch-direct {
  width: 100% !important;
}

.text-center-b1-sketch-direct {
  width: 100% !important;
}

.text-center-sketch {
  width: 80% !important;
  margin: 30px auto;
  border: 1px solid #ddd !important;
}

.text-center-sketch-b1 {
  width: 80% !important;
  margin: 30px auto;
  border: 1px solid #ddd !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.text-center-sketch-b2 {
  width: 90%;
  text-align: end;
  margin-top: -20px;
}

.text-center-sketch-b3 {
  width: 100%;
  text-align: center;
  margin-top: -20px;
}

#admin-member .ti-check {
  color: #ffee00;
  position: absolute;
  left: -25px;
  font-weight: 800;
}

#admin-member .ti-close {
  color: #e0486e;
  position: absolute;
  left: -25px;
  font-weight: 800;
}

#admin-member .pricing-bottom ul {
  margin-bottom: -24px;
  margin-left: 22px;
  font-size: 10px;
}

#admin-member .pricing-bottom-business {
  color: #ffffff;
}

#admin-member .pricing-bottom-all {
  color: #767676;
}

#admin-member .pricing-bottom p {
  font-size: 20px;
  font-weight: 800;
  margin-top: 28px;
}

#admin-member .pricing-bottom span {
  font-size: 18px;
  margin-top: 12%;
}

#admin-member .pricing-number {
  font-size: 80px;
  font-weight: bold;
  margin-bottom: -20%;
  margin-top: -50px;
}

#admin-member .pricing-number-all {
  color: #767676;
}

#admin-member .discription {
  font-size: 15px;
  /* font-weight: bold; */
  font-family: 'Titillium-Regular' !important;
  margin-bottom: -20%;
  color: #a7a7a7;
  margin-left: 2%;
  margin-right: 2%;
}

#admin-member .active {
  font-size: 20px;
  font-weight: bold;
  color: #7bd812;
  margin: 0% 20% 0% 20%;
}

#admin-member .activepro {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  margin: 0% 20% 0% 20%;
}

#admin-member .inactive {
  visibility: hidden;
}

#admin-member .margin-house {
  margin-top: -55px;
}

#admin-member .margin-cows {
  margin-top: -29px;
}

#admin-member .pricing-section {
  position: relative;
  background-size: cover;
}

#admin-member .button {
  display: inline-block;
  color: #7bd812 !important;
  background: #ffff !important;
  border: 2px solid #ffffff;
  font-size: 1em;
  font-weight: 700;
  line-height: 3.125;
  border-radius: 25px;
  padding: 0 28px;
  text-align: center;
  transition: all 0.5s ease;
}

#admin-member .button-cancel {
  display: inline-block;
  color: #a7a7a7 !important;
  background: rgb(255, 255, 255) !important;
  text-decoration: underline;
  font-size: 1em;
  font-weight: 400;
  line-height: 3.125;
  padding: 0 28px;
  text-align: center;
  transition: all 0.5s ease;
}

#admin-member .button-cancel-pro {
  display: inline-block;
  color: #767676 !important;
  text-decoration: underline;
  font-size: 1em;
  font-weight: 700;
  line-height: 3.125;
  padding: 0 28px;
  text-align: center;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#admin-member .button-all {
  display: inline-block;
  color: #7bd812 !important;
  background: #ffff !important;
  border: 2px solid #ffffff;
  font-size: 1em;
  font-weight: 700;
  line-height: 2.125;
  border-radius: 5px;
  padding: 0 28px;
  text-align: center;
  transition: all 0.5s ease;
  margin-top: 10px;
}

#admin-member .button-start-free {
  display: inline-block;
  color: #ffff !important;
  background: #ffff !important;
  border: 2px solid #ffffff;
  font-size: 1em;
  font-weight: 700;
  line-height: 2.125;
  border-radius: 5px;
  padding: 0 28px;
  text-align: center;
  transition: all 0.5s ease;
  margin-top: 10px;
}

#admin-member .card-box .container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

#admin-member .text-alignement {
  font-size: 10px;
  margin-top: 52px;
  text-align: left !important;
}

#admin-member .text-alignement p {
  font-size: 11px;
  margin-top: 7%;
  font-family: 'Titillium-Regular' !important;
}

#admin-member .card-basic {
  margin-top: 40px;
  height: 650px;
  text-align: left !important;
}

#admin-member .card-premium {
  margin-top: 40px;
  height: 750px;
  text-align: left !important;
}

#admin-member .card-business {
  margin-top: 40px;
  text-align: left !important;
  height: 850px;
}

#admin-member .bg-business {
  background: linear-gradient(
      rgba(123, 216, 18, 0.58),
      rgba(123, 216, 18, 0.58)
    ),
    url('../../../static/images/admin/member_ship/bg-business.png');
  margin-top: 50%;
  background-repeat: no-repeat;
  background-size: 200% 100%;
}

/* business plan membership*/

#admin-member .pricing-box-member-business {
  -webkit-box-shadow: 5px 10px 18px #888888 !important;
  box-shadow: 5px 10px 18px #888888 !important;
}

#admin-member .pricing-box-member-business:hover {
  transform: translateY(-8px);
}

#admin-member .pricing-box-member-business {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-left: -22px;
  border-radius: 8px;
  height: 728px;
  margin-top: 19.5%;
}

/* premium plan membership*/

#admin-member .pricing-box-member-premium {
  -webkit-box-shadow: 5px 10px 18px #888888 !important;
  box-shadow: 5px 10px 18px #888888 !important;
}

#admin-member .pricing-box-member-premium:hover {
  transform: translateY(-8px);
}

#admin-member .pricing-box-member-premium {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-left: -22px;
  border-radius: 8px;
  height: 710px;
  margin-top: 26.5%;
  background: #fff;
}

/* basic plan membership*/

#admin-member .pricing-box-member-basic {
  -webkit-box-shadow: 5px 10px 18px #888888 !important;
  box-shadow: 5px 10px 18px #888888 !important;
}

#admin-member .pricing-box-member-basic:hover {
  transform: translateY(-8px);
}

#admin-member .pricing-box-member-basic {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-left: -22px;
  border-radius: 8px;
  height: 690px;
  margin-top: 34.5%;
  background: #fff;
}

#admin-setting .react-switch {
  vertical-align: middle;
  margin-right: 7px;
}

/* security */

#admin-setting form label {
  object-fit: contain;
  font-size: 17px;
  margin-top: 1%;
  text-align: left;
  color: #767676;
}

#admin-setting .card-title {
  object-fit: contain;
  font-size: 19px;
  text-align: left;
  color: #7bd812;
  margin-bottom: 10px;
  margin-top: 1%;
  /* margin-left: 1%; */
  white-space: nowrap;
}

#admin-setting form input {
  width: 100%;
  object-fit: contain;
  border-radius: 0px !important;
  border-top: transparent !important;
  border-right: transparent !important;
  border-left: transparent !important;
  box-shadow: 0 0 6.6px 1.4px rgba(97, 97, 97, 0.15);
}

#admin-setting input[type='checkbox'] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  /* Styling checkbox */
  width: 19px !important;
  height: 19px !important;
  background-color: #fff !important;
  border: 2px solid #7bd812 !important;
  margin-right: 10px !important;
}

#admin-setting .form-container input[type='checkbox']:checked {
  background-color: #7bd812 !important;
  border-color: #7bd812 !important;
}

#admin-setting .form-container input[type='checkbox'] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  /* Styling checkbox */
  width: 19px !important;
  height: 19px !important;
  background-color: #fff !important;
  border: 2px solid #7bd812 !important;
}

#admin-setting .form-container input[type='checkbox']:checked {
  background-color: #7bd812 !important;
  border-color: #7bd812 !important;
}

#admin-setting form .form-control[disabled] {
  background-color: white;
  color: rgba(112, 112, 112, 0.4);
  opacity: 1;
}

/**/

.form-check-label {
  font-size: 15px !important;
  margin: auto;
}

#admin-setting form input:focus {
  box-shadow: none;
  color: #7bd812;
  border-color: #7bd812;
}

.quality-program-content input[type='file'] {
  opacity: 0;
  -moz-opacity: 0;
  /* IE 5-7 */
  filter: alpha(Opacity=0);
  /* Safari  */
  -khtml-opacity: 0;
  /* IE 8 */
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
}

.quality-program-content .input-file {
  text-align: center;
  color: #fff;
  display: block;
  width: auto;
  height: auto;
  font-size: 17px;
  color: #fff;
  padding: 5px;
  font-weight: bold;
  border-radius: 10px;
}

.quality-program-content label {
  min-height: 100%;
  min-width: 100%;
}

.quality-program-content .box-action {
  display: flex;
  justify-content: space-around;
  height: auto;
  text-align: right;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 0px;
  background-color: #f6f6f6;
  box-shadow: 0 0 8px 0 rgba(97, 97, 97, 0.18);
  height: 50px !important;
}

.quality-program-content .box label,
.quality-program-content .box-action .box-icons {
  cursor: pointer;
  width: 40px;
  margin-top: 10px !important;
}

#admin .box-icons {
  margin-left: 12% !important;
  margin-right: 12% !important;
}

#admin-setting .right-icon {
  height: 30px;
  width: 100%;
}

#tab-security .list-group-item {
  border-right: none;
  border-left: none;
  font-size: 12px;
  height: 62px;
}

#tab-security .list-group-item > .txt-right {
  float: right;
}

#tab-security input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

#tab-security .switch {
  cursor: pointer;
  min-width: 50px;
  text-indent: -9999px;
  height: 23px;
  background: grey;
  border-radius: 100px;
  position: relative;
}

#tab-security .switch:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

#tab-security input:checked + label {
  background: #7bd812;
  width: 40px;
}

#tab-security input + label {
  width: 40px;
}

#tab-security input:checked + label:after {
  left: calc(100% - 0px);
  transform: translateX(-116%);
}

#tab-security .switch:active:after {
  width: 130px;
}

#tab-security button {
  width: 20%;
  border: none;
  float: right;
}

.setting-company .css-vj8t7z,
.setting-company .css-2o5izw {
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: #eee !important;
}

@media (min-width: 992px) {
  .col-md-6 {
    width: 60%;
  }
}

.title-disc {
  margin: -6px -60px 0px 7px;
  font-weight: bold;
}

.clock {
  margin-left: -25px;
  margin-top: 4px;
  margin-left: -65px;
  color: #2d3342;
  font-size: 13px;
}

img {
  margin: -11px;
  padding: 0;
  border: 0;
  background: 0 0;
  max-width: 100%;
}

.planPrice {
  margin-top: 4px;
  font-weight: bold;
}

.icon-margin {
  margin-top: -12px;
  margin-left: 55px;
}

.princing-img {
  margin-left: -25px;
}

.switch-text-color {
  color: #2d3342;
}

.rotate-position {
  transition: 0.5s transform ease-in-out;
  transform: rotate(180deg);
}

/* change plan discription */

.first-discription {
  font-family: 'Titillium-Regular' !important;
  width: 66%;
  font-size: 26px;
  font-weight: 600;
  margin: 34px 9px 2px 135px;
  color: #767676;
  align-content: center;
  text-align: center;
}

.second-discription {
  font-family: 'Titillium-Regular' !important;
  font-size: 13px;
  font-weight: 300;
  margin: -8px 0px 90px 133px;
  color: #767676;
  align-content: center;
}

.third-description {
  font-family: 'Titillium-Regular' !important;
  font-size: 16px;
  margin: 94px 79px 178px 93px;
  color: #767676;
  text-align: center;
}

.fourth-discription {
  font-family: 'Titillium-Regular' !important;
  font-size: 13px;
  font-weight: 300;
  margin: 110px 137px 90px 142px;
  color: #767676;
  text-align: center;
}

.margin-premium {
  margin-top: 20px;
}

.margin-business {
  margin-top: 37px;
}

.box-contain-img {
  color: #ffffff !important;
}

.text-centered:hover {
  color: #ffffff !important;
}

.css-si2hz3-control .css-1wy0on6 {
  display: block !important;
}

.css-wpfdww-control .css-1wy0on6 {
  display: block !important;
}

.css-wpfdww-control .css-1wy0on6 .css-20a7fl-indicatorContainer {
  color: gray !important;
}

.css-wpfdww-control {
  background: none !important;
}

.css-1g6gooi {
  visibility: hidden !important;
}

.css-1szy77t-control {
  box-shadow: 0 0 0 1px #7bd812 !important;
  border-color: #7bd812 !important;
}

.hidden {
  display: none;
}

.new-cards {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.new-cards-com {
  width: 10% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.new-company-user {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-content: center !important;
}

.new-company-user-block {
  width: 50%;
}

.new-company-user-block .card-title {
  padding-left: 0;
}

.new-company-user-btn {
  margin-top: 20% !important;
}

.new-company-user-Form {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-content: center !important;
}

.new-company-user-Form-b1 {
  width: 60%;
}

.new-company-user-Form-b1 {
  width: 80%;
}

.new-cards-owner {
  height: 100%;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  padding: 0 5%;
  padding-left: 4px;
}

.new-cards-owner-inner {
  height: 55px !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.new-cards-owner-inner-switch {
  height: 55px !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  align-items: center !important;
  align-content: center !important;
}

.order-reconfirmation-container {
  padding-bottom: 6%;
}

.new-cards-owner-inner.type-of-use {
  height: 118px !important;
}

.new-cards-owner-inner.hit-use-switch .new-cards-owner-inner-b2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.new-cards-owner-inner.hit-use-switch .new-cards-owner-inner-b2 .react-switch {
  margin-top: 0 !important;
  width: 40%;
}

.new-cards-owner-inner.type-of-use #regular-input {
  margin: 0 !important;
}

.new-margin {
  margin-left: 14px !important;
}

.new-cards-owner-inner-b1 {
  width: 40% !important;
  font-family: 'Titillium-Regular' !important;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.new-cards-owner-inner-b1-switch {
  width: 60% !important;
  font-family: 'Titillium-Regular' !important;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.new-cards-owner-inner-b2 {
  width: 55% !important;
  padding: 8px 0px 8px 20px;
  margin-top: 1%;
  margin-bottom: 1%;
  color: #666;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: 16px;
  border: 0px solid red !important;
}

.new-cards-owner-inner-b2-switch {
  width: 30% !important;
  padding: 8px 0px 8px 20px;
  margin-top: 1%;
  margin-bottom: 1%;
  color: #666;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: 16px;
  border: 0px solid red !important;
  text-align: end;
}

.new-cards-owner-in-b2 {
  border: 0px solid red !important;
}

.new-cards-ow-inner-b2 {
  border: 0px solid red !important;
  width: 100%;
  box-shadow: none !important;
  border-bottom: 1px solid #eee !important;
}

.new-cards-ow-inner-b2:focus {
  border-bottom: 1px solid #7bd812;
  color: #7bd812;
}

.new-cards-ow-inner-b2:active {
  color: #7bd812;
  border-bottom: 1px solid #7bd812;
}

.new-cards-ow-inner-b2:focus {
  border-bottom: 1px solid #7bd812;
}

.new-cards-owner-inner-b2-btn {
  width: 50% !important;
  margin-top: 3%;
  /* margin-top: 20% !important; */
  /* margin-bottom: 30px !important; */
}

#admin-setting .form-btn-valide {
  width: 100%;
  height: 50px;
  background-color: #7bd812 !important;
  border-color: #7bd812;
  border-radius: 5px;
  font-size: 18px;
  color: #fff !important;
}

.user-cards-helper {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  width: 100% !important;
}

.form-user-modal .modal-body,
#modal-transporter .modal-body {
  margin: 0;
  padding: 0 15px;
  min-height: 420px;
}

#modal-transporter .add-s-bottom-part-cols {
  width: 100% !important;
  height: 38px !important;
  border-radius: 5px !important;
  font-size: 18px !important;
  color: #fff !important;
  line-height: 1.4 !important;
  border: 1px solid transparent !important;
}

.new-Position {
  text-align: center;
  margin-top: -90px;
}

#settings-inner-card.new-cards-owner-inner-b2 > input,
#settings-inner-card.new-cards-owner-inner-b2 .css-ssi0ig-container,
#settings-inner-card.new-cards-owner-inner-b2
  .css-1pcexqc-container
  .css-si2hz3-control {
  margin: 0 !important;
  border-left: 1px solid #cbcbcb !important;
  border-right: 1px solid #cbcbcb !important;
  border-top: 1px solid #cbcbcb !important;
  border-bottom: 1px solid #cbcbcb !important;
  border-radius: 5px !important;
  color: #646464 !important;
}

#settings-inner-card.new-cards-owner-inner-b2 .css-wpfdww-control {
  border: none;
}

#settings-inner-card.new-cards-owner-inner-b2
  .css-wpfdww-control
  .css-1wy0on6
  svg
  path,
#settings-inner-card.new-cards-owner-inner-b2
  .css-si2hz3-control
  .css-1wy0on6
  svg
  path {
  fill: #b9b9b9;
}

#settings-inner-card.new-cards-owner-inner-b2
  .css-wpfdww-control
  .css-ue83k8-singleValue {
  margin-top: 5px;
}

#settings-inner-card.new-cards-owner-inner-b2
  .css-si2hz3-control
  .css-dvua67-singleValue {
  color: #909090 !important;
}

#settings-inner-card.new-cards-owner-inner-b2 > input:disabled {
  color: #cbcbcb !important;
}

#regular-input .css-es53b3-multiValue {
  height: 25px;
  align-items: center;
  color: #7bd812;
  background: white;
  box-shadow: 3px 1px 14px 4px rgba(243, 243, 243, 0.97),
    0px 2px 2px 0px rgba(154, 154, 154, 0.22),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

#regular-input .css-es53b3-multiValue .css-1alnv5e {
  padding: 0;
  margin: 4px 4px 5px 4px;
}

#regular-input .css-es53b3-multiValue .css-1alnv5e:hover {
  background-color: #77d718;
  color: #ffffff;
}

#regular-input .css-12jo7m5 {
  color: #909090;
}

.new-cards-owner-inner {
  background: white;
  border-radius: 5px !important;
}

.edite-icon {
  background-image: url('../../../static/images/admin/icons_final/editIcon.svg');
  width: 30px;
}

#admin .password-setting .input-group-addon,
#admin .password-setting .search {
  background-color: #ffffff !important;
  border: none !important;
  height: 100% !important;
  margin-bottom: 0px !important;
}

#admin .password-setting .search {
  font-family: 'Titillium-Regular' !important;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#admin .password-setting .input-group-addon {
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#admin .img-settingpassword {
  width: 20px;
  height: 20px;
}

.password-Setting {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #666;
}

.password-Popup-modal .modal-content {
  width: 100% !important;
  height: 400px !important;
}

.password-Popup-modal .button-txt {
  min-width: 100% !important;
  text-align: center;
  bottom: -28%;
}
