/* Payment form styles */

.rad-group {
  display: flex;
  cursor: pointer;
}

.rad-group input[type='radio'] {
  visibility: hidden;
}

.rad-group input[type='radio'],
.form-description input[type='checkbox'] {
  margin-right: 30px;
}

.form-description {
  padding-top: 60px;
  padding-left: 15px;
  color: #767676;
}

.credentials-btns .form-label {
  margin-top: 1.5% !important;
}

.rad-color {
  color: #767676;
  font-size: 18px;
  cursor: pointer;
}

/* inputs animations */
#rad-group > .input > .active {
  color: #7bd812;
  font-size: 18px;
  cursor: pointer;
}

.input-color {
  color: #b3b1b1;
  font-size: 15px;
}

#payment-input .form-control {
  object-fit: contain;
  border-radius: 0px !important;
  border-top: transparent !important;
  border-right: transparent !important;
  border-left: transparent !important;
  border-radius: 8px;
  box-shadow: none;
  margin: 0px !important;
  padding: 0px !important;
  transition: none;
}

#payment-input form input:focus {
  color: #7bd812;
  border-bottom: transparent !important;
}

.form-description .rad-groupe > input {
  cursor: pointer;
}
.form-container {
  height: 680px !important;
}
.form-container.change-plan input[type='radio'],
.form-container.change-plan input[type='checkbox'] {
  cursor: pointer;
  width: 13% !important;
  height: 1.3em !important;
  color: #fffdfd;
  border: none !important;
  box-shadow: none !important;
}

/* radio buttons input animation */
.form-container.change-plan input[type='radio']:after {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  top: -2px;
  left: 0px;
  position: relative;
  background-color: #ffffff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #d1d3d1;
}

.form-container.change-plan input[type='radio']:checked:after {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  top: -2px;
  left: 0px;
  position: relative;
  background-color: #7bd812;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #7bd812;
}

.label1 {
  cursor: pointer;
}

.newlabel {
  white-space: nowrap;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Titillium-Regular';
  font-size: 18px;
  width: 150px;
  color: #999;
}

/* checkbox input animation */

/*responsive buttons input and text*/
.btn-res {
  background-color: #7bd812;
}

.col-25 .col-75 {
  float: left;
  width: 25%;
}

.text-res {
  margin-top: 6px;
}

table.table.tab-list {
  width: 96% !important;
}

#box-container {
  margin-bottom: 20px;
}

.payment-radio {
  display: flex;
  margin-left: 10%;
}

.payment-radio .rad-group:first-child {
  margin-right: 30px;
}

/* Using media query to make the responsive web page */
/* screen with max width of 992px */

@media screen and (max-width: 1556px) {
  .Bank-text {
    position: absolute;
    text-align: left;
    width: 55%;
    margin-left: 130px;
  }

  .pictures {
    margin-left: -30px;
  }

  .form-label {
    width: 100%;
  }

  .btn-res {
    width: 40px;
  }

  .form-container.change-plan {
    margin-left: -150px;
  }

  .payment-radio {
    margin-left: -20px;
  }

  .form-container.change-plan input[type='checkbox']:after,
  .form-container.change-plan input[type='checkbox']:checked:after {
    left: 30px;
  }

  .form-container.change-plan input[type='radio']:after,
  .form-container.change-plan input[type='radio']:checked:after {
    left: 1px;
  }

  .form-container.change-plan .col-75 {
    width: 360px;
  }

  .credentials-btns .form-label {
    margin-top: 30% !important;
  }
}

@media screen and (max-width: 1248px) {
  .form-label {
    width: 100%;
  }

  .btn-res {
    width: 40px;
  }

  .form-container.change-plan {
    margin-left: -250px;
    margin-top: 80px;
  }

  .payment-radio {
    margin-left: -20px;
  }

  .form-container.change-plan input[type='checkbox']:after,
  .form-container.change-plan input[type='checkbox']:checked:after {
    left: 28px;
  }

  .form-container.change-plan input[type='radio']:after,
  .form-container.change-plan input[type='radio']:checked:after {
    left: 0px;
  }

  .form-container.change-plan .col-75 {
    width: 330px;
  }
}

.credentials-btns .form-label {
  margin-top: 5% !important;
}

.sepa-buttom {
  margin-bottom: 22px;
}

.form-container.change-plan .btn-lg,
.btn-group-lg > .btn {
  border: none !important;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.form-container.change-plan .btn-success {
  border: none !important;
  box-shadow: 0px 21px 24px 0 rgba(0, 0, 0, 0.08);
  background-color: #7bd812 !important;
}

.btn {
  /* display: inline-block; */
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
}

.mandate div {
  text-align: left;
  font-size: 15.2px;
  font-family: Titillium-Light;
  color: #767676;
}

.mandate .btn-sepa > button {
  padding-left: 0;
}
.checkbox-tearms {
  display: flex;
  flex-direction: row;
  background: white;
  width: 100%;
  position: relative;
  left: 1px;
  margin: 20px 0 0 -34px;
}
.tearms {
  color: rgba(98, 98, 98, 0.6);
  margin-top: 3px;
}

.FlexPayementBlock {
  height: 680px !important;
}
