// cattle add card styles
.add-cattle-card {
  height: 325px;
  margin: 4% 2% 3% 1%;
  border: 2px dashed #7bd813;
  border-radius: 5px;
  padding: 20px 15px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  & img {
    width: 100px;
    height: 100px;
  }
  & .cattle-add-container {
    display: flex;
    justify-content: center;
    & .cattle-add-icon {
      border: 2px dashed #7bd813;
      border-radius: 50%;
      padding: 12px 20px 12px 7px;
      margin-top: 15%;
    }
  }
  & .add-cattle-button {
    width: 95%;
    border: 2px solid #7bd813;
    border-radius: 4px;
    color: #7bd813;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding-top: 3px;
    cursor: pointer;
    padding: 2px 10px;
    text-align: center;
  }
  & .add-cattle-button.german-text {
    width: 85% !important;
    padding: 2px 10px;
    text-align: center;
  }
  & .add-cattle-button:hover {
    color: white !important;
    background: #7bd813;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding-top: 3px;
  }
}

.edit-cattle-container {
  &.non-agrabiz-external-request {
    padding: 1% 4% 4% 4% !important;
  }
}

@media (max-width: 1560px) {
  .add-cattle-card {
    margin: 4% 4% 3% 4% !important;
    width: 40% !important;
  }
}
