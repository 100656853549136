$green: #7bd812; // agrabiz greeny
$dark-green: #68b314; // dark green
$darken-green: #437905; // really dark green
$dark-grey: #767676; // dark grey
$light-grey: #999;
$input-grey: #c6c6c6;
$white: #ffffff;
$border-grey: #eaeaea;
$black: #000000;
$button-shadow: 0px 0px 9px 1.5px rgba(0, 0, 0, 0.06),
  0 1px 0px 0 rgba(0, 0, 0, 0.02);
$input-bar-color: #424242;
$muted-color: mix(white, $input-bar-color, 70%);
$trans-time: 200ms;
