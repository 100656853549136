#basic-nav-dropdown-language .caret {
    display: none !important;
  }
  
  @media (min-width: 992px) {
  
    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
      margin-left: -45px;
    }
  }
  
  a {
    Text-decoration: none;
  }
  
  .navbar-right .nav>a {
    margin-right: 70px !important;
  }
  
  .navbar-right .nav a {
    color: white !important;
  }
  
  .affix .navbar-right .nav li a {
    color: black !important;
  }
  
  .navbar-right .nav li.active a {
    color: #7bd812 !important;
  }
  
  .navbar-toggle.collapsed {
    background: none !important;
  }
  
  .affix .navbar-toggle.collapsed .icon-bar {
    background: #7bd812 !important;
  }
  
  .is-transparent .navbar-default .navbar-nav>li>a {
    margin-right: 90px !important;
    color: #9c9894 !important;
    font-size: 1.1em;
  }
  
  
  @media only screen and (max-width: 1023px) {
    .navbar-right .nav li a {
      color: #333 !important;
    }
  
    .navbar-toggle {
  
      border: none;
  
    }
  
  }
  