.order-bidders {
  height: 100%;
}

.order-bidders > div:nth-child(4) {
  height: 80%;
}

.order-bidders .description .description-info {
  margin-bottom: 1%;
}

.order-bidders .card-title {
  margin-bottom: 3% !important;
}

.order-bidders .list-group.list-group-item {
  padding: 0 !important;
  min-height: 0 !important;
}

.order-bidders .list-group.list-group-item > .row {
  padding: 2% 0 !important;
  height: 101px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.order-bidders .list-group.list-group-item > .row.order-item-active {
  background: #effae3 !important;
}

.order-bidders .list-group.list-group-item > .row .green {
  font-size: 16px !important;
}

.status-image {
  width: 50px;
  height: auto;
  object-fit: contain;
}

.info-item {
  font-size: 14px;
}

.orders-for-bidders {
  padding: 0 4%;
}
