$breakpoint-gt-surface: 1700px;
$breakpoint-lt-surface: 1300px;

#membership-type {
  & .m-header {
    display: flex;
    height: 85px;
    justify-content: space-between;
    & .header-text {
      display: flex;
      flex-direction: column;
      & span {
        font-weight: 700;
      }
      & span:first-child {
        font-size: 30px;
        color: grey;
      }
    }
    & img {
      width: 100px;
      height: 65px;
    }
  }
  & .m-description {
    padding-bottom: 5px;
  }
  & .separator {
    width: 100%;
    border-top: 1px solid #ececec;
    padding: 5px 0;
  }
  & .m-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .price-box {
      color: grey;
      font-size: 45px;
      font-weight: 600;
      & .digits {
        display: flex;
        align-items: center;
        padding-left: 20px;
        height: 40px;
        & .price-right {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0;
          bottom: 39px;
          & .price-tag {
            padding-right: 2px;
            font-size: 15px;
            font-weight: 600;
          }
        }

        & .p-r-agr {
          left: 17px;
        }
        & .price-notice {
          font-size: 38px;
          padding-left: 4px;
        }
        & .price-after-comma {
          font-size: 18px;
          padding-left: 3px;
        }
      }
      & .per-month {
        font-size: 13px;
        padding-left: 20px;
      }
    }
    & .status {
      color: grey;
      font-weight: 600;
      font-size: 18px;
    }
    & .start-now-btn {
      height: 38px;
      padding: 0 20px;
      font-size: 16px;
      border-radius: 5px;
      box-shadow: 0px 0px 9px 1.5px rgba(0, 0, 0, 0.06),
        0 1px 0px 0 rgba(0, 0, 0, 0.02);
      & .cancel-plan {
        color: #fba93a;
      }
    }
  }
}

@media (max-width: $breakpoint-gt-surface) {
  #membership {
    & .m-header {
      height: 77px !important;
      & .header-text span:first-child {
        font-size: 25px !important;
      }
      & img {
        width: 90px;
        height: 55px;
      }
    }
    & .bottom-text {
      font-size: 17px !important;
    }
    & .m-description {
      font-size: 13px;
    }
    & .m-footer {
      & .start-now-btn {
        padding: 0 5px;
        height: 30px;
        font-size: 14px;
      }
      & .price-box {
        font-size: 35px;
      }
    }
  }
}

@media (max-width: $breakpoint-gt-surface) {
  #membership {
    & .m-footer {
      & .price-box {
        font-size: 20px;
        & .digits {
          padding-left: 10px;
          height: 30px;
        }
        & .per-month {
          font-size: 10px;
          padding-left: 15px;
        }
        & .price-notice {
          font-size: 20px !important;
        }
        & .price-right {
          bottom: 27px !important;
        }
        & .p-r-agr {
          left: 13px !important;
          & svg {
            width: 15px !important;
            height: 15px !important;
          }
        }
      }
    }
  }
  #membership-type {
    & .m-header {
      & .header-text {
        & span:first-child {
          font-size: 20px !important;
        }
        & span:last-child {
          font-size: 14px !important;
        }
      }
    }
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {
  #membership-type {
    & .m-header {
      & .header-text {
        & span:first-child {
          font-size: 25px !important;
        }
        & span:last-child {
          font-size: 14px !important;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  #membership {
    & .m-footer {
      & .price-box {
        font-size: 20px;
        & .digits {
          padding-left: 0px;
          height: 30px;
        }
        & .per-month {
          font-size: 10px;
          padding-left: 10px;
        }
        & .price-notice {
          font-size: 20px !important;
        }
        & .price-right {
          left: -20px !important;
          bottom: 20px !important;
        }
        & .p-r-agr {
          left: 3px !important;
          & svg {
            width: 15px !important;
            height: 15px !important;
          }
        }
      }
    }
  }
}
