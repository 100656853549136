.details {
  height: 100%;
}

.details > div:nth-child(4) {
  height: 80%;
}

.details .description .description-info {
  margin-bottom: 1%;
}

.details .card-title {
  margin-bottom: 3% !important;
}

.details .list-group.list-group-item {
  padding: 0 !important;
  min-height: 0 !important;
}

.details .list-group.list-group-item > .row {
  padding: 2% 0 !important;
  height: 101px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.details .list-group.list-group-item > .row.order-item-active {
  background: #effae3 !important;
}

.details .list-group.list-group-item > .row .green {
  font-size: 16px !important;
}

.status-image {
  width: 50px;
  height: auto;
  object-fit: contain;
}

.details-table {
  margin: 40px 0px 23px 5px;
}
.details-table table tbody td {
  border: none !important;
}

table tbody th {
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  color: #626262;
  border-collapse: collapse;
  border-style: hidden;
  text-align: center;
}

.tr-table {
  border-collapse: collapse;
  text-align: center;
}

.details-table .breed {
  font-size: 25px;
  color: #7bd812 !important;
  font-weight: bold;
  border-style: hidden;
  border-collapse: collapse;
  border-style: hidden;
  vertical-align: middle !important;
}

.details-table .total-breed {
  font-size: 20px;
  color: #7bd812 !important;
  vertical-align: middle !important;
  padding-left: 10px;
}

.contact-form-buttons {
  position: absolute;
  bottom: 21px;
  left: 0px;
  width: 100%;
}

.info-item {
  font-size: 14px;
}

.details-row {
  padding: 0 4%;
}

.day-total {
  display: flex !important;
  align-items: flex-end !important;
  align-content: flex-end !important;
  justify-content: flex-end;
  font-size: 22px;
  font-weight: 600;
}

.total-span {
  color: #7bd812 !important;
  padding-left: 22px;
  font-size: 20px;
}

.today {
  font-size: 22px;
}

.details .right-section-header {
  display: flex;
  justify-content: space-between;
}

.details-table .details-line {
  padding: 10px 0;
  display: flex;
}

.details-table .details-races {
  display: flex;
}

.details-table .details-item-breed {
  text-align: center;
  margin-right: 7%;
  width: 130px;
}

.details-table .details-item-race {
  margin-right: 7%;
  display: flex;
  align-items: center;
  width: 70px;
}
