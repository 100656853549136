.stages {
  max-height: '628px';
  width: 97% !important;
  overflow-y: 'scroll';
}

.birthCv {
  border: 0;
  padding: 4px 0;
  background-color: transparent;
  font: 18px 'Titillium-Regular', sans-serif;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  line-height: 1.4;
  font-size: 23px;
  opacity: 0.6;
}

.birthDate {
  margin-top: 4px;
  font-family: 'Titillium-Regular';
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7em;
}

.blocBirth {
  margin-top: 20px;
  height: 50px !important;
}

.newBlock-cv {
  width: 100% !important;
  height: 50px !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.newBlock-cv-b1 {
  width: 10% !important;
}

.newBlock-cv-b2 {
  width: 30% !important;
}

.newBlock-cv-b3 {
  width: 30% !important;
}

.newBlock-cv-img {
  width: 20px;
  height: 20px;
}

#admin .newBlock-cv hr.style-border {
  margin: 0 !important;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.22),
    rgba(0, 0, 0, 0)
  );
}

.text-noir-report-cv {
  font-size: 16px !important;
  font-weight: 300 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 2 !important;
  letter-spacing: normal !important;
  font-family: 'Titillium-Regular', sans-serif;
  font: 16px  'Titillium-Regular', sans-serif !important;
  color: #333 !important;
}

.text-right-cv {
  text-align: right;
}
.cv-b1-flex{    
  height:600px;
  overflow:scroll;
  overflow-x:hidden;
}

#admin .cv-b1-flex::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
}

#admin .cv-b1-flex::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
}