@import 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700';
@import 'https://fonts.googleapis.com/css?family=Quicksand';

/* @import 'https://fonts.googleapis.com/css?family=Muller'; */
@font-face {
  font-family: Muller;
  src: url(../fonts/Muller-Thin-DEMO.ttf);
}

@font-face {
  font-family: MullerBold;
  src: url(../fonts/Muller-ExtraBold-DEMO.ttf);
}

@font-face {
  font-family: muller-light;
  src: url(../fonts/muller-light.otf);
}

@font-face {
  font-family: Titillium-Regular;
  src: url(../fonts/Titillium-Regular.otf);
}

@font-face {
  font-family: Titillium-Bold;
  src: url(../fonts/Titillium-Bold.otf);
}

@font-face {
  font-family: Titillium-Light;
  src: url(../fonts/Titillium-Light.otf);
}

.active .steps h4,
.heading span,
.box-icon .fa,
.box-icon .ti,
.video-play,
.video-play:hover,
.video-play:focus,
.owl-theme .owl-nav [class*='owl-'],
.single-features .ti,
.single-features .fa,
.contact-form h3,
.contact-info h6 .fa,
.footer-navigation li a:hover,
.social-list li a:hover,
.owl-theme .owl-nav [class*='owl-']:hover,
.footer-links li a:hover {
  color: #7bd812;
}

.flex4 {
  flex: 4;
}

.flex1 {
  flex: 1;
}

.column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.column-space-arround-center {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.column-space-arround {
  display: flex;
  flex-direction: column;
  justify-content: unset;
}

.span-style {
  padding-top: 8px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.top10 {
  margin-top: 10px;
}

.top20 {
  margin-top: 20px;
}

.top50 {
  margin-top: 50px;
}

.bottom50 {
  margin-bottom: 50px;
}

.illustration-spacing {
  margin-bottom: 6%;
}

@media (min-width: 768px) {
  #banner {
    display: flex;
    justify-content: space-around;
    z-index: 9999999;
    width: 100%;
    position: absolute;
    background-color: #c7c7c7;
    color: white;
    font-size: 12px;
    margin-bottom: 5% !important;
    padding: 1px;
    font-family: 'muller-light';
    height: 72px;
    top: 0px;
  }

}

@media (max-width: 767px) {
  #banner {
    justify-content: space-around;
    z-index: 9999999;
    width: 100%;
    position: absolute;
    background-color: #c7c7c7;
    color: white;
    font-size: 8px;
    padding: 1px;
    font-family: 'muller-light';
    height: 60%;
    top: 0px;
  }
}

#banner .accept {
  color: #7bd812;
  height: 32px;
}

#banner .reject {
  color: #c7c7c7;
}

.pricing-bottom .ti-check {
  color: #7bd812;
}

.pricing-bottom .ti-close {
  color: #de0000;
}

.owl-theme .owl-dots .owl-dot span:after,
.affix .navbar-default .navbar-toggle,
.affix .navbar-default .navbar-toggle,
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  border-color: #7bd812;
}

.primary-bg {
  background: #7bd812;
}

.secondary-bg {
  background: #8ccc86;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span,
.affix .navbar-default .navbar-toggle,
.affix .navbar-default .navbar-toggle,
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background: #7bd812;
}

.button {
  background: #9e38cf;
}

.button:hover {
  background: #7bd812;
}

.header-texts .button {
  background: #9e38cf;
  color: #fff;
}

.header-texts .button:hover {
  background: #fff;
  color: #9e38cf;
}

.button.button-transparent {
  background: transparent;
}

.button.button-transparent:hover {
  background: #fff;
  color: #9e38cf;
  border-color: #fff;
}

.navbar-default .navbar-nav>li>a.button,
.navbar-default .navbar-nav>li>a.button:focus {
  border: 2px solid #9e38cf;
  background: #9e38cf;
}

.navbar-default .navbar-nav>li>a.button:hover {
  color: #9e38cf;
}

.affix .navbar-default .navbar-nav>li>a.button:hover,
.affix .navbar-default .navbar-nav>li.active>a.button,
.affix .navbar-default .navbar-nav>li.active>a.button:focus {
  color: #9e38cf;
  border-color: #9e38cf;
}

.meta-heading {
  color: #00deec;
  margin-bottom: 5px;
}

.feature-col .icon {
  background: #effeff;
  color: #00deec;
}

.button.alt {
  background: #fff;
  color: #7bd812;
}

.button.alt:hover {
  background: #7bd812;
  color: #fff;
}

.button.light {
  background: #e5f7f8;
  color: #00deec;
}

.button.light:hover {
  background: #00deec;
  color: #fff;
}

.bg-circle .small,
.bg-circle .big {
  background: #dff9fe;
}

.bg-circle .big:before {
  background-image: -o-linear-gradient(157deg, #fff 0%, #24e3ef 100%);
  background-image: linear-gradient(157deg, #fff 0%, #24e3ef 100%);
}

.bg-circle .big:after {
  background-image: -o-linear-gradient(157deg, #fff 0%, #80ffc8 100%);
  background-image: linear-gradient(157deg, #fff 0%, #80ffc8 100%);
}

.pricing-box.alt h5 {
  color: #00deec;
}

.Toastify__toast-container {
  width: 30% !important;
}

@media only screen and (max-width: 1024px) {
  .Toastify__toast-container {
    width: 90% !important;
    font-size: 10px;
  }
}

@media only screen and (max-width: 991px) {

  .navbar-default .in .navbar-nav>li>a:hover,
  .navbar-default .in .navbar-nav>li>a:focus,
  .affix .navbar-default .navbar-nav>li>a:hover,
  .affix .navbar-default .navbar-nav>li>a:focus,
  .affix .navbar-default .navbar-nav>.active>a,
  .affix .navbar-default .navbar-nav>.active>a:hover,
  .affix .navbar-default .navbar-nav>.active>a:focus {
    background: #7bd812;
  }
}
