#admin .orders {
  height: 100%;
}

#admin .orders-block {
  height: 95%;
}

#admin .orders .card-box {
  height: 95%;
  position: relative;
  margin: 0 !important;
}

.orders .card-box.left-box {
  padding: 0 !important;
}

.center-timer {
  padding: 10% !important;
  text-align: center;
  border-bottom: unset !important;
}

.orders .card-box.left-box .card-body {
  padding: 0 !important;
}

#admin .orders .card-title {
  margin-top: 5px;
  padding-left: 4%;
  height: 6%;
}

#admin .orders .order-panel {
  height: 100% !important;
}

#admin .orders .order-row {
  height: 100%;
}

#admin .orders .order-groupe {
  height: 95%;
}

#admin .orders .list-group-item {
  position: relative;
  display: block;
  width: 100%;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none;
  text-align: left;
  margin-bottom: 0px !important;
  cursor: pointer;
}

.btnn {
  /* display: inline-block; */
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  display: inline-block;
  /* margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer; */
  /* user-select: none; */
  color: gray;
  background-color: gray;
}

#admin .orders .btn-group {
  width: 100%;
  height: 6%;
}

#admin .orders .order-btn-groupe.btn-group {
  height: 7% !important;
}

#admin .orders .order-btn-groupe.btn-group .btn {
  border: 0 !important;
  box-shadow: none !important;
  color: #545454;
}

#admin .orders .dropdown-width .dropup.btn-group {
  width: 100% !important;
}

#admin .orders .dropup.btn-group {
  width: 40% !important;
  display: flex;
  justify-content: center;
  height: 6%;
}

#admin .orders .btn-default {
  width: 33%;
  /* opacity: 0.37; */
  background-color: #cdf0b2;
  cursor: pointer;
}

#admin .orders .btn-primary {
  width: 33%;
  /* opacity: 0.37; */
  background-color: #cdf0b2;
  cursor: pointer;
}

#admin .orders .view-stock .btn-default {
  width: 45% !important;
}

#admin .orders .active {
  opacity: 1;
  color: white !important;
  background-color: #7bd812;
  outline: none;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px;
}

#admin #tab-Event .active {
  color: #7bd812 !important;
}

.active-tab-icon {
  color: white !important;
  border-bottom-right-radius: 5px !important;
}

.active-tab {
  border: none !important;
  border-bottom: 2px solid #77d609 !important;
  object-fit: contain;
  font-size: 30px;
  line-height: 1.19;
  text-align: left;
  color: #77d609;
}

#admin .orders .button-groupe-wrapper .btn {
  border-radius: 0;
}

#admin .orders .button-groupe-wrapper .btn:first-child {
  border-radius: 5px 0 0 0;
}

#admin .orders .button-groupe-wrapper .btn:last-child {
  border-radius: 0 5px 0 0;
}

/* #admin .orders .button-groupe-wrapper .btn:first-child {
  border-top-left-radius: 5px !important;
}

#admin .orders .button-groupe-wrapper .btn:last-child {
  border-top-right-radius: 5px !important;
} */

#admin .orders .button-groupe-wrapper .btn .assigned {
  font-size: 14px;
  font-weight: 600;
}

#admin .orders .not-available {
  color: #d6d812;
}

@media screen and (max-width: 768px) {
  #admin .orders .list-group {
    height: 40%;
    margin-bottom: 10%;
  }
}

#admin .orders .text-grey {
  font-size: 20px;
  color: #626262;
}

#admin .orders .header-notifications {
  margin-top: 30px;
  margin-bottom: 42px;
}

#admin .orders .disabled {
  opacity: 0.5;
}

#admin .orders .form-control {
  height: 42px;
  text-align: center;
  font-size: 24.5px;
}

#admin .orders .status {
  text-align: center;
  font-size: 20px;
}

#admin .orders .info-item {
  font-size: 14px;
}

#admin .orders .order-btn-groupe>button {
  font-size: 20px;
}

#admin .orders hr.style-border {
  margin: 0 !important;
  border: 0;
  height: 1px;
  background-image: linear-gradient( to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0));
}

.plus {
  display: flex;
  justify-content: flex-end;
  background: white;
  width: 100%;
  margin-top: 50px;
  left: 1px;
}

.plus-item {
  margin-left: auto;
  margin-inline: 10px 20px;
}

#admin .buy {
  margin-left: 10px;
  color: #97e044;
  width: 211px;
  height: 25px;
  object-fit: contain;
  font-family: 'Titillium-Regular' !important;
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
}

#admin .description {
  font-size: 20px;
}

#admin .bidders-description-groupe {
  margin-bottom: 15px;
}

#admin .bidders-description-groupe~.btn-group button {
  border: none;
}

#admin .bidders-description-groupe .description:first-child {
  border-right: 1px solid #bfbfbf;
}

#admin .optional-tab {
  right: 0;
  position: fixed;
  width: 43%;
}

#admin .orders .list-group {
  height: 95%;
  min-height: 100px;
  margin: 0 !important;
  scrollbar-color: #7bd812 #ffffff;
}

#admin .orders #buy-sell.list-group.bought-orders {
  min-height: 0;
  height: unset !important;
}

#admin .orders #buy-sell.list-group.bought-orders .green {
  font-size: 16px !important;
}

#admin .orders #buy-sell.list-group.bought-orders .info-item {
  font-size: 14px !important;
}

#admin .orders #buy-sell.list-group.bought-orders #checkbox1 .container {
  position: unset !important;
}

#admin .orders #buy-sell.list-group.bought-orders #checkbox1 .checkmark {
  margin-left: 25px;
}

#admin .orders #buy-sell.list-group.bought-orders .list-group-item>.row {
  display: flex;
  align-items: center;
}

#admin .orders #buy-sell.list-group.bought-orders .list-group-item {
  padding: 0 !important;
  min-height: 0 !important;
}

#admin .orders #buy-sell.list-group {
  height: 92% !important;
}

#admin .orders .list-groupp {
  overflow-y: auto;
  height: 80%;
  min-height: 100px;
  margin: 0 !important;
  scrollbar-color: #7bd812 #ffffff;
}

.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: #ffffff;
  color: #7bd812 !important;
  font-weight: bold;
  border: 1px solid #7ad819 !important;
  border-radius: 50%;
}

.react-datepicker__day--keyboard-selected {
  background: #868686;
  border-radius: 50%;
}

.react-datepicker__day--highlighted:hover {
  border-radius: 0.3rem;
  background-color: #7ad819 !important;
  color: #ffffff !important;
}

#admin .orders .listSell {
  margin-top: 0%;
  margin-bottom: 5%;
  overflow-y: scroll;
  max-height: 333px;
  min-height: 100px;
  scrollbar-color: #7bd812 #ffffff;
}

#admin .orders .list-group::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
}

/* #admin .orders .list-group:hover ::-webkit-scrollbar {
  width: 15px !important;
} */

#admin .orders .back-button {
  margin: 0;
  position: absolute;
  z-index: 5;
  left: -7px;
  top: 10px;
}

#admin .buyBtn {
  margin: 25px !important;
}

#admin .orders .order-groupe div:nth-child(2) {
  height: 95% !important;
}

.orders .navigate-before {
  color: #c5c3c3;
  position: absolute;
  top: 22px;
  left: 14px;
}

#admin .orders .navigate-before>div svg {
  font-size: 33px;
}

#admin .orders .order-panel {
  height: 100% !important;
  padding-top: 0;
}

.status-image {
  width: 50px;
  height: auto;
  object-fit: contain;
}

.status-name {
  font-size: 14px !important;
}

#admin .orders .orders-col {
  height: 100%;
}

#admin .orders .card-body {
  height: 100%;
}

#admin .orders .panel-body-row {
  height: 92%;
}

#admin .orders .panel-body-col {
  height: 100%;
}

#admin .orders .card-body.panel-body-sell-offer {
  padding: 0 !important;
}

.card-body.panel-body-sell-offer .card-list-stock-custom.stockSell {
  padding: 0 5%;
}

#admin .orders .panel-body-col>div:nth-child(2) {
  height: 93%;
}

#admin .orders .infinite-scroll-component {
  height: 100% !important;
}

.button-groupe-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.button-groupe-wrapper>button {
  flex-grow: 1;
}

#admin .orders .order-btn-groupe {
  /* display: table !important; */
  table-layout: fixed !important;
}

#admin .orders .date-time {
  position: relative;
  border-bottom: 0px solid #ccc !important;
}

#admin .orders .the-day {
  font-family: 'Titillium-Regular';
  font-size: 20px;
  font-weight: 300;
  color: #727272;
  font-size: 17px;
  width: 105px;
}

.date-time i {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
}

.date-time .up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  border-color: #7bda19;
}

.date-time .down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border-color: #7bda19;
}

.user-input-wrp .date-time {
  border: none !important;
}

/* datetimepicker wrapper  */

.date-picker-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3%;
  padding-bottom: 2%;
}

.user-input-wrp .date-time {
  margin-left: 0;
  color: #727272 !important;
  font-size: 17px !important;
}

.date-picker-icon {
  width: 80px;
  height: 35px;
  cursor: pointer;
}

.date-picker-icon:before {
  content: '';
  background: url('../../../static/images/calendar.svg');
  background-size: 48% 48%;
  width: 70px;
  height: 70px;
  position: absolute;
  background-repeat: no-repeat;
}

.button-up {
  padding-bottom: 8px;
}

.button-down {
  padding-top: 10px;
}

.btn-hide {
  visibility: hidden;
}

/* datetimepicker wrapper end */

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #7bd811;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-wrapper {
  display: flex;
  justify-content: center;
}

/* End Loader style  */

/* Buy-sell-resell-trucklist list  */

#buy-sell-scroller .list-group.list-group-item, #buy-sell .list-groupp.list-group-item {
  padding: 0 !important;
  min-height: 0 !important;
}

#buy-sell-scroller .list-group.list-group-item>.row, #buy-sell .list-groupp.list-group-item>.row {
  padding: 2% 0 !important;
  height: 101px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

/* #buy-sell .list-groupp.list-group-item > .row {
  padding-left: 4% !important;
} */

#buy-sell-scroller .list-group.list-group-item>.row.order-item-active {
  background: #effae3 !important;
}

#buy-sell-scroller .list-group.list-group-item>.row .green, #buy-sell .list-groupp.list-group-item>.row .green, #buy-sell-scroller .list-group.list-group-item>.row .grey, #buy-sell .list-groupp.list-group-item>.row .grey, #buy-sell-scroller .list-group.list-group-item>.row .yellow, #buy-sell .list-groupp.list-group-item>.row .yellow {
  font-size: 16px !important;
  /* line-height: 1.2 !important; */
}

#buy-sell-scroller .list-group.list-group-item>.row .grey, #buy-sell .list-groupp.list-group-item>.row .grey {
  margin: 0;
  color: #999;
}

#buy-sell-scroller .list-group.list-group-item>.row .yellow, #buy-sell .list-groupp.list-group-item>.row .yellow {
  margin: 0;
  color: #eddf01;
}

/* #buy-sell {
  padding-top: 3%;
} */

/* prevent bar cursor from datepicker  */

.date-picker-wrapper .react-datepicker__input-container input {
  color: transparent !important;
  text-shadow: 0 0 0 #727272;
}

@media (max-width: 1520px) {
  .date-picker-wrapper .react-datepicker__input-container input {
    width: 100% !important;
  }
  .date-picker-icon:before {
    left: 20px
  }
}

/* Fix posionning inside left box  */

.orders.all-orders .panel-body-options.bottom-part {
  height: 8%;
  display: flex;
  align-items: center;
}

/* select tags in sell offer  */

.select-container-bsr .render-breed-race {
  display: flex;
  flex-direction: column;
}

.select-container-bsr .render-breed-race.races {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  border-bottom: 1px solid #eee;
}

.select-container-bsr .render-breed-race.breeds {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eee;
}

.select-container-bsr .render-breed-race .label-sell {
  color: #cdcecd;
  font-weight: 400;
  font-size: 14px;
  padding: 0 0 0 5px;
}

.select-container-bsr .render-breed-race .tags {
  display: flex;
  padding: 5px 0 12px 5px;
}

.select-container-bsr .render-breed-race .inner-tag {
  padding: 1px 7px;
  height: 25px;
  background: #ecebeb;
  color: dimgrey;
  border-radius: 7px;
  margin-right: 7px;
}

.center-element {
  text-align: center;
}