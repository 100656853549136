/* react datepicker overried design  */

.react-datepicker__input-container input {
  color: #727372 !important;
  opacity: 1;
  font-size: 17px !important;
  width: 120px !important;
  cursor: pointer !important;
}

.react-datepicker-popper {
  position: absolute;
  top: 30px !important;
  left: 280px !important;
}

.react-datepicker {
  padding: 6% 8% !important;
}
.react-datepicker__triangle {
  border-bottom-color: white !important;
}
.react-datepicker__navigation {
  top: 28px !important;
  border: 0.7rem solid transparent !important;
}

.react-datepicker__navigation--previous {
  left: 30px !important;
  border-right-color: #ccc !important;
}

.react-datepicker__day--keyboard-selected {
  background: #868686;
  border-radius: 50%;
}

.react-datepicker__navigation--next {
  right: 30px !important;
  border-left-color: #ccc !important;
}

.react-datepicker__day-name {
  color: #7ad819 !important;
  font-size: 12px !important;
  width: 3rem !important;
  line-height: 3rem !important;
}

.react-datepicker__day.react-datepicker__day--selected {
  border-radius: 50% !important;
  background-color: #7ad819 !important;
  color: white !important;
}

.react-datepicker__current-month {
  padding-bottom: 15px !important;
  color: #5d5d5d !important;
  font-size: 15px !important;
}

.react-datepicker__header {
  background-color: white !important;
  border: none !important;
}

.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 12px !important;
  width: 3rem !important;
  line-height: 3rem !important;
  color: #5d5d5d !important;
}
.react-datepicker__day.react-datepicker__day--today {
  color: #7ad819 !important;
}

.react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--today {
  color: white !important;
}
.react-datepicker__day:hover {
  border-radius: 50% !important;
}
.react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  background-color: #7ad819;
  color: white !important;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #7ad819 !important;
}
/* react datepicker overried design end */
