html,
body {
  margin: 0;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 1.2em;
}

#page-numbers {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  padding-left: 4em;
  padding-right: 2.5em;
  cursor: pointer;
}

#page-numbers > li {
  margin-top: 24.5px;
  margin-left: 8.8px;
  margin-right: 12.8px;
  padding-bottom: 1.5em;
  width: 9px;
  height: 12px;
  object-fit: contain;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  font-size: 16.2px;
  color: #bebebe;
}

#page-numbers > .active {
  margin-top: 0px;
  margin-left: 8.8px;
  margin-right: 12.8px;
  width: 16px;
  object-fit: contain;
  font-size: 30px;
  font-weight: 500;
  line-height: 2.2;
  border-bottom: 3px solid #7bd812;
  color: #7bd812;
}

#box-container {
  border: 1px solid;
  color: #e9e9e9;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 20px;
  /* width: 1415px; */
}

.Agr {
  margin-top: 8px;
  margin-bottom: 28px;
  width: 442px;
  height: 17px;
  font-size: 14.2px;
  font-family: Titillium-Light;
  color: #767676;
  margin-left: -22px;
  position: relative;
}

/* Tabs styles */
.Tabs {
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
}

.Tabs__Tab {
  display: inline-block;
  padding: 1em;
  color: #767a7d;
  cursor: pointer;
}

.Tabs__Tab:hover,
.Tabs__Tab--active {
  color: #7bd812;
}

.Tabs__Underline {
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  border-bottom: 2px solid #7bd812;
}

.invoices-table-tr {
  line-height: 40px;
}

/* styling texts */
.Bank {
  margin-top: 8px;
  margin-bottom: 28px;
  margin-left: 18px;
  width: 60%;
  text-align: left;
  font-size: 15.2px;
  font-family: Titillium-Light;
  color: #767676;
}

.Bank-text {
  margin-top: 2px;
  margin-bottom: 75px;
  width: 110%;
  height: 17px;
  text-align: left;
  font-size: 15.2px;
  font-family: Titillium-Light;
  color: #767676;
  cursor: pointer;
}

/* table styles */
.tab-list {
  position: relative;
  text-align: center;
  color: #767676;
  margin-left: 15px;
}

.tab-header {
  position: relative;
  text-align: center;
  font-weight: bold;
  color: #767676;
}

.tabCol {
  margin-top: 150px;
}

.table-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.Tabs__Underline {
  z-index: 9;
}

.side-title {
  font-size: 45px;
}

.hr {
  display: block;
  height: 2px;
  background: #e8e8e8;
  position: relative;
  top: -20px;
}

.header-container {
  width: 85%;
  padding-right: 20px;
}

.header-side-title {
  width: 290px;
}
.D-tag,
.active {
  color: #7bd812;
}

.mc {
  width: 73px;
  height: 57px;
  margin-top: 30px;
  margin-left: 63px;
  height: auto;
}

.description {
  width: 100%;
  color: #767676;
}

.form-description {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* Payment form styles */

.rad-group {
  display: flex;
  cursor: pointer;
}
.rad-group input[type='radio'] {
  visibility: hidden;
}

.rad-group input[type='radio'],
.form-description {
  padding-top: 60px;
  padding-left: 15px;
  color: #767676;
}

.credentials-inp {
  height: 100%;
}
.credentials-inp-b1 {
  height: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.form-description-b1-Bloc {
  width: 75%;
  text-align: center;
  color: #767676;
  font-family: 'Titillium-Regular' !important;
  font-size: 16px;
  margin-top: 120px;
  color: #767676;
  text-align: justify;
}
.form-description-b1-Bloc-chech {
  margin-top: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  margin-left: 19px;
}
.credentials-inp-b2 {
  height: 20%;
  width: 121%;
  margin-top: 16px;
}
.credentials-input1 .form-label {
  margin-top: 10% !important;
}

.credentials-input2 {
  padding-top: 20px;
}

.credentials-val {
  padding-top: 30px;
}

.credentials-input2 .form-label {
  margin-top: 5% !important;
}

.credentials-btns .form-label {
  margin-top: 1.5% !important;
}

.rad-color {
  color: #767676;
  font-size: 18px;
  cursor: pointer;
}

/* inputs animations */
#rad-group > .input > .active {
  color: #7bd812;
  font-size: 18px;
  cursor: pointer;
}

.input-color {
  color: #b3b1b1;
  font-size: 15px;
}

#payment-input .form-control {
  object-fit: contain;
  border-radius: 0px !important;
  border-top: transparent !important;
  border-right: transparent !important;
  border-left: transparent !important;
  border-radius: 8px;
  box-shadow: none;
  margin: 0px !important;
  padding: 0px !important;
  transition: none;
}

#payment-input form input:focus {
  color: #7bd812;
  border-bottom: transparent !important;
}

.form-description .rad-groupe > input {
  cursor: pointer;
}

.form-container input[type='radio'],


/* radio buttons input animation */
.form-container input[type='radio']:after {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  top: -2px;
  left: 0px;
  position: relative;
  background-color: #ffffff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #d1d3d1;
  cursor: pointer;
}

.form-container input[type='radio']:checked:after {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  top: -2px;
  left: 0px;
  position: relative;
  background-color: #7bd812;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #7bd812;
  cursor: pointer;
}

.label1 {
  cursor: pointer;
}
.newlabel {
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Titillium-Regular';
  font-size: 18px;
  font-weight: 300;
  line-height: 1.7em;
  width: 100%;
  color: #999;
  cursor: pointer;
}
/* checkbox input animation */

/*responsive buttons input and text*/
.btn-res {
  background-color: #7bd812;
}

.col-25 .col-75 {
  float: left;
  width: 25%;
}

.text-res {
  margin-top: 6px;
}

.credentials-btns .form-label {
  margin-top: 5% !important;
}

.sepa-buttom {
  margin-bottom: 22px;
}

.form-container .btn-lg,
.btn-group-lg > .btn {
  border: none !important;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.form-container .btn-success {
  border: none !important;
  box-shadow: 0px 21px 24px 0 rgba(0, 0, 0, 0.08);
  background-color: #7bd812 !important;
}

.btn {
  /* display: inline-block; */
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
}

.mandate div {
  text-align: left;
  font-size: 15.2px;
  font-family: Titillium-Light;
  color: #767676;
}

.mandateDiv {
  height: 100%;
}
.mandateDivB1 {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center !important;
  align-items: center;
}
.mandateDivB2 {
  height: 20%;
  width: 105%;
  margin-left: 100px;
  /* display: flex; */
  /* flex-direction: column; */
  /* flex-wrap: wrap; */
  justify-content: center !important;
  align-items: center;
  margin-top: 10px;
}

.mandate .btn-sepa > button {
  padding-left: 0;
}

.checkbox-tearms {
  display: flex;
  flex-direction: row;
  background: white;
  width: 100%;
  position: relative;
  left: 1px;
  margin: 20px 0 0 -34px;
}

.checkbox-tearmsFlex {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;

  align-items: center !important;
  align-content: center !important;
}

.tearms {
  color: rgba(98, 98, 98, 0.6);
  margin-top: 3px;
}
.paiment-field {
  border: 1px solid rgba(112, 112, 112, 0.4);
}
.success-subscription {
  font-family: 'Titillium-Regular' !important;
  font-size: 30px;
  font-weight: 500;
  color: #767676;
  text-align: center;
}
.success-under-subscription {
  font-family: 'Titillium-Regular' !important;
  font-size: 20px;
  font-weight: 300;
  margin: 16px 0px 75px 0px;
  color: #767676;
  text-align: center;
}
.success-images {
  text-align: center;
}
.success-footer {
  font-family: 'Titillium-Regular' !important;
  font-size: 16px;
  font-weight: 300;
  color: #767676;
  text-align: center;
  margin-top: 60px;
}
.container-success {
  margin: 99px 328px;
}
.special-discription {
  font-weight: bold;
  color: #7bd812;
  text-align: center;
}
.modal-pdf {
  height: 100%;
  min-height: 800px;
  min-width: 800px;
}

.invoice-Popup-modal .modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.invoice-Popup-modal .modal-dialog {
  width: 850px !important;
  height: 800px !important;
  margin-bottom: auto;
  margin-top: 7%;
}

.invoice-Popup-modal .modal-content {
  width: 100% !important;
  height: 100%;
}

.invoice-Popup-modal .SEPA-Lastschriftsmandat {
  font-family: 'Titillium-Regular' !important;
  font-size: 45px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #7bd812 !important;
}

.invoice-Popup-modal .SEPA-b1 {
  font-family: 'Titillium-Regular' !important;
  font-size: 22px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #7a7a7a !important;
  text-align: justify !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.invoice-Popup-modal .SEPA-bold {
  font-weight: 600 !important;
  font-family: 'Titillium-Regular' !important;
  font-size: 22px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #7a7a7a !important;
  text-align: justify !important;
}

.btn-signteur {
  width: 100%;
  margin-top: 15px;
  height: 130px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.invoice-Popup-modal .btn-signteur-b1 {
  width: 20%;
}

.invoice-Popup-modal .btn-signteur-b2 {
  width: 60%;
}

.invoice-Popup-modal .btn-success {
  border: none !important;
  box-shadow: 0px 21px 24px 0 rgba(0, 0, 0, 0.08);
  background-color: #7bd812 !important;
}

#payment-input input {
  box-shadow: none !important;
}

#payment-input .payment-form-input #regular-input {
  padding: 0 15px;
}

#payment-input .checkbox-tearms {
  padding: 0 20px;
}

#payment-input .payment-form-input .invalid-feedback {
  padding-left: 15px;
}

#payment-input .payment-form #regular-input {
  margin-top: 5px;
}

#payment-input .payment-form .payment-form-input:first-child #regular-input {
  margin-top: 25px;
}

.banking-left-section,
.banking-right-section {
  width: 50%;
}

.banking-container {
  display: flex;
  height: 100%;
}

.banking-container .form-container {
  height: 100% !important;
}

.banking-container .form-description {
  padding-left: 0;
  padding-right: 60px;
}
.banking-container .form-description a {
  color: #7ad819;
}

.banking-left-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banking-container .payment-radio {
  display: flex;
  margin-left: 5%;
}

.banking-container .payment-radio .rad-group:first-child {
  margin-right: 30px;
}

@media (max-width: 1550px) {
  .banking-container .form-description {
    padding-right: 45px !important;
  }
}

@media screen and (max-width: 1360px) {
  .banking-container {
    display: flex;
    justify-content: center;
  }
  .banking-left-section {
    display: none;
  }
  .banking-right-section {
    width: 70%;
  }
  .banking-container .payment-radio {
    display: flex;
    margin-left: 10%;
  }
}
