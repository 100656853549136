.button {
  color: #7bd812 !important;
  background: grey !important;
  border: 2px solid #e7e7e7;
}
.select-style {
  border: 1px solid #ccc;
  width: 60px;
  border-radius: 3px;
  overflow: hidden;
}

.select-style::-ms-expand {
  display: none;
  background-color: #7bd812;
}
.select-style:hover {
  border-color: #7bd812;
}
.select-style:focus {
  box-shadow: #7bd812;
  box-shadow: -moz-mac-focusring;
}
.select-style:active {
  outline-color: #7bd812;
}
.select-style option {
  font-weight: normal;
  background-color: #ffffff;
  color: #7bd812;
}

.select-style :disabled,
.select-style [aria-disabled='true'] {
  color: #aaa;
}
.select-style :disabled:hover,
.select-style [aria-disabled='true'] {
  border-color: #aaa;
}
.lang {
  margin-left: 62px;
  margin-right: -150px;
}