#admin .overviews {
  height: 100%;
  color: #8c8c8c;
}

#admin .overviews-block {
  height: 95%;
}

#admin .overviews .card-box {
  height: 95%;
  position: relative;
  margin: 0 !important;
}

.overviews .card-box.left-box {
  padding: 0 !important;
}

.overviews .card-box.left-box .panel-body {
  padding: 0 !important;
}

#admin .overviews .card-title {
  margin-top: 5px;
  padding-left: 4%;
  height: 6%;
}

#admin .overviews .order-panel {
  height: 95%;
}

#admin .overviews .overview-row {
  height: 100%;
}

#admin .overviews .overview-groupe {
  height: 95%;
}

#admin .overviews .list-group-item {
  position: relative;
  display: block;
  width: 100%;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none;
  text-align: left;
  margin-bottom: 0px !important;
  cursor: pointer;
}

.div2 {
  margin-top: 70px;
  padding-left: 4%;
  height: 6%;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.overviews .btn-flex-center {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  margin-top: 40px;
}

.overviews .buying-table {
  margin: 10px 10px;
  max-height: 45vh;
  overflow: auto;
}

.overviews .buying-table .no-border {
  border: none;
}

/* .overviews .btn-day{
width: 62px;
height: 64px; 
} */
.overviews .btn-day:focus {
  background-color: #f0fae3 !important;
  border-radius: 60%;
}

.overviews .buying-totals {
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7bd812 !important;
  margin: 0px 2%;
  color: white;
  padding: 6px 0px;
  font-size: 20px;
  font-weight: 600;
}

.overviews .buying-totals .total-number:first-child {
  width: 20% !important;
  text-align: center;
}

.overviews .buying-totals .total-number {
  width: 13%;
  text-align: center;
}

.buyings-overview-table {
  width: 100%;
}

.buyings-overview-table td:first-child {
  width: 20% !important;
}

.buyings-overview-table td {
  width: 13%;
}

#admin .overviews .total {
  color: #7bd812 !important;
  padding-left: 22px;
  font-size: 30px;
}

.checkbox {
  margin-right: 10px;
}

.clickBtn {
  width: 157px;
  height: 43px;
  border-radius: 30px !important;
  font-size: 12px;
  color: #7bd812 !important;
  margin: 5px 2px;
  box-shadow: 0 3px 5px #ccc;
}

.group-btn-filter {
  width: 157px !important;
  height: 43px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #7bd812 !important;
  margin: 5px 4px !important;
  background-color: white !important;
  border: none !important;
  /* box-shadow: 0 3px 5px #ccc !important; */
  box-shadow: 0px -5px 14px 4px rgba(226, 222, 222, 0.17),
    1px 1px 8px 0px rgba(202, 202, 202, 0.14),
    1px 1px 6px 0px rgba(175, 175, 175, 0.25);
  display: none;
}

.group-btn-filter input[type='checkbox'] {
  display: none;
}

#admin .overviews .active {
  opacity: 1;
  color: white !important;
  background-color: #7bd812 !important;
  outline: none;
  border-radius: 30px !important;
  z-index: 0;
}

#admin .overviews .active:before {
  opacity: 1;
  color: white !important;
  background-color: #7bd812 !important;
  outline: none;
  border-radius: 30px !important;
}

#admin .overviews .group-btn-filter:focus {
  opacity: 1;
  color: white !important;
  background-color: #7bd812 !important;
  outline: none;
  border-radius: 30px !important;
}

.title-row {
  font-size: 25px;
  color: #7bd812;
  font-weight: bold;
  border-collapse: collapse;
}

.buying-table .breed {
  font-size: 25px;
  color: #7bd812 !important;
  font-weight: bold;
  border-style: hidden;
  border-collapse: collapse;
  border-style: hidden;
  vertical-align: middle !important;
}

.buying-table .total-breed {
  font-size: 20px;
  color: #7bd812 !important;
  vertical-align: middle !important;
  padding-left: 15px;
}

.tr-table {
  border-collapse: collapse;
  text-align: center;
}

table tbody tr td {
  border-collapse: collapse;
  text-align: center;
  font-size: 15px;
  height: 24px;
}

.style-border {
  margin: 0 !important;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.22),
    rgba(0, 0, 0, 0)
  );
}

.my-custom-scrollbar {
  position: relative;
  height: 400px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}

#admin .overviews .btn-group {
  width: 100%;
  height: 6%;
  margin-left: 0;
}

#admin .overviews .overview-btn-groupe.btn-group {
  height: 7% !important;
}

#admin .overviews .overview-btn-groupe.btn-group .btn {
  border: 0 !important;
  box-shadow: none !important;
  color: #545454;
}

#admin .overviews .dropup.btn-group {
  width: 40%;
  height: 6%;
}

#admin .overviews .btn-default {
  width: 33%;
  background-color: #cdf0b2;
  cursor: pointer;
}

#admin .ordeoverviewsrs .view-stock .btn-default {
  width: 45% !important;
}

#admin .overviews .button-groupe-wrapper .btn {
  border-radius: 0;
}

#admin .overviews .button-groupe-wrapper .btn:first-child {
  border-radius: 5px 0 0 0;
}

#admin .overviews .button-groupe-wrapper .btn:last-child {
  border-radius: 0 5px 0 0;
}

#admin .overviews .button-groupe-wrapper .btn .assigned {
  font-size: 14px;
  font-weight: 600;
}

#admin .overviews .not-available {
  color: #d6d812;
}

.table tbody tr td:last-child,
.table thead tr th:last-child {
  border: 0;
  background: linear-gradient(
      to left,
      #fbfbfb 0%,
      #dcdcdc 20%,
      #cecece 65%,
      #b9b9b9 100%
    )
    left bottom #b9b9b9 no-repeat;
  background-size: 100% 1px;
  background-color: transparent;
}
.table tbody tr td:nth-child(1),
.table thead tr th:nth-child(1) {
  border: 0;
  background: linear-gradient(
      to left,
      #b9b9b9 0%,
      #cecece 20%,
      #dcdcdc 65%,
      #fbfbfb 100%
    )
    left bottom #b9b9b9 no-repeat;
  background-size: 100% 1px;
  background-color: transparent;
}

.table tbody tr td,
.table thead tr th {
  border: 0;
  background: linear-gradient(
      to left,
      #b9b9b9 0%,
      #b9b9b9 20%,
      #b9b9b9 65%,
      #b9b9b9 100%
    )
    left bottom #b9b9b9 no-repeat;
  border-top: 0 !important;
  background-size: 100% 1px;
  background-color: transparent;
}

/* .table tbody tr td:first-child,
.table thead tr th:first-child {
  background: transparent;
} */

@media screen and (max-width: 768px) {
  #admin .overviews {
    height: 40%;
    margin-bottom: 10%;
  }
}

#admin .overviews .text-grey {
  font-size: 20px;
  color: #626262;
}

#admin .overviews .header-notifications {
  margin-top: 30px;
  margin-bottom: 42px;
}

#admin .overviews .disabled {
  opacity: 0.5;
}

#admin .overviews .form-control {
  height: 42px;
  text-align: center;
  font-size: 24.5px;
}

#admin .overviews .status {
  text-align: center;
  font-size: 20px;
}

#admin .overviews .info-item {
  font-size: 14px;
}

#admin .overviews .overview-btn-groupe > button {
  font-size: 20px;
}

#admin .overviews hr.style-border {
  margin: 0 !important;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.22),
    rgba(0, 0, 0, 0)
  );
}

.plus {
  display: flex;
  justify-content: flex-end;
  background: white;
  width: 100%;
  margin-top: 50px;
  left: 1px;
}

.plus-item {
  margin-left: auto;
  margin-inline: 10px 20px;
}

#admin .buy {
  margin-left: 10px;
  color: #97e044;
  width: 211px;
  height: 25px;
  object-fit: contain;
  font-family: 'Titillium-Regular' !important;
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
}

#admin .description {
  font-size: 20px;
}

#admin .bidders-description-groupe {
  margin-bottom: 15px;
}

#admin .bidders-description-groupe ~ .btn-group button {
  border: none;
}

#admin .bidders-description-groupe .description:first-child {
  border-right: 1px solid #bfbfbf;
}
#admin .optional-tab {
  right: 0;
  position: fixed;
  width: 43%;
}

#admin .overviews .list-group {
  height: 95%;
  min-height: 100px;
  margin: 0 !important;
  scrollbar-color: #7bd812 #ffffff;
}

#admin .overviews .list-groupp {
  overflow-y: auto;
  height: 80%;
  min-height: 100px;
  margin: 0 !important;
  scrollbar-color: #7bd812 #ffffff;
}

.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: #ffffff;
  color: #7bd812 !important;
  font-weight: bold;
  border: 1px solid #7ad819 !important;
  border-radius: 50%;
}

.react-datepicker__day--highlighted:hover {
  border-radius: 0.3rem;
  background-color: #7ad819 !important;
  color: #ffffff !important;
}

.react-datepicker__day--keyboard-selected {
  background: #868686;
  border-radius: 50%;
}

#admin .overviews .listSell {
  margin-top: 0%;
  margin-bottom: 5%;
  overflow-y: scroll;
  max-height: 333px;
  min-height: 100px;
  scrollbar-color: #7bd812 #ffffff;
}

#admin .overviews .list-group::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
}

#admin .overviews .back-button {
  margin: 0;
  position: absolute;
  z-index: 5;
  left: -7px;
  top: 10px;
}

#admin .buyBtn {
  margin: 25px !important;
}

#admin .overviews .overview-groupe div:nth-child(2) {
  height: 95% !important;
}

.overviews .navigate-before {
  color: #c5c3c3;
  position: absolute;
  top: 22px;
  left: 14px;
}

#admin .overviews .navigate-before > div svg {
  font-size: 33px;
}

#admin .overviews .overview-panel {
  height: 95%;
  padding-top: 0;
}

.status-image {
  width: 50px;
  height: auto;
  object-fit: contain;
}

.status-name {
  font-size: 14px !important;
}

#admin .overviews .overviews-col {
  height: 100%;
}

#admin .overviews .card-body {
  height: 100%;
}

#admin .overviews .panel-body-row {
  height: 85%;
}

#admin .overviews .panel-body-col {
  height: 100%;
}

#admin .overviews .panel-body.panel-body-details {
  padding: 0 !important;
}
.panel-body.panel-body-details .card-list-stock-custom.stockSell {
  padding: 0 5%;
}
#admin .overviews .panel-body-col > div:nth-child(2) {
  height: 93%;
}

#admin .overviews .infinite-scroll-component {
  height: 100% !important;
}

.button-groupe-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.button-groupe-wrapper > button {
  flex-grow: 1;
}

#admin .overviews .overview-btn-groupe {
  /* display: table !important; */
  table-layout: fixed !important;
}

#admin .overviews .date-time {
  position: relative;
  border-bottom: 0px solid #ccc !important;
}

#admin .overviews .the-day {
  font-family: 'Titillium-Regular';
  font-size: 20px;
  font-weight: 300;
  color: #727272;
  font-size: 17px;
  width: 105px;
}

.date-time i {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
}

.date-time .up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  border-color: #7bda19;
}

.date-time .down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border-color: #7bda19;
}

.user-input-wrp .date-time {
  border: none !important;
}

/* datetimepicker wrapper  */

.date-picker-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3%;
  padding-bottom: 2%;
}

.user-input-wrp .date-time {
  margin-left: 0;
  color: #727272 !important;
  font-size: 17px !important;
}

.date-picker-icon {
  width: 80px;
  height: 35px;
  cursor: pointer;
}
.date-picker-icon:before {
  content: '';
  background: url('../../../static/images/calendar.svg');
  background-size: 48% 48%;
  width: 70px;
  height: 70px;
  position: absolute;
  background-repeat: no-repeat;
}

.button-up {
  padding-bottom: 8px;
}

.button-down {
  padding-top: 10px;
}
.btn-hide {
  visibility: hidden;
}

/* datetimepicker wrapper end */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.date-picker-wrapper .react-datepicker__input-container input {
  color: transparent !important;
  text-shadow: 0 0 0 #727272;
  width: 160px !important;
}

/* Fix posionning inside left box  */
.overviews .card-total.bottom-part {
  height: 15%;
  display: flex !important;
  align-items: center !important;
}

.overviews .btn-group {
  margin-bottom: 20px;
}

.buying-table thead th {
  text-align: center;
  padding: 5px !important;
}

.buying-table tbody td {
  font-size: 20px;
}

.group-btn-filter {
  font-size: 25px;
  line-height: 1.9;
  width: 130px !important;
  height: 40px !important;
}

.buying-table button {
  width: 55px;
  height: 55px;
  padding: 0;
}

.buying-table button:hover {
  background: #effbe1 !important;
  border-radius: 50%;
}

.group-btn-filter {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.group-btn-filter input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 5px;
  left: 25px;
  height: 25px;
  width: 25px;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.group-btn-filter:nth-child(2) .checkmark {
  left: 15px;
}

.group-btn-filter input:checked ~ .checkmark:after {
  display: block;
}

.group-btn-filter .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 1500px) {
  .group-btn-filter {
    font-size: 15px !important;
    line-height: 1.9 !important;
    width: 115px !important;
    height: 36px !important;
  }
  .buying-table button {
    width: 45px;
    height: 45px;
    padding: 0;
  }
  .buying-table button {
    font-size: 22px;
  }
  .buying-table .total-breed {
    padding-left: 5px;
  }
  .checkmark {
    position: absolute;
    top: 5px;
    left: 15px;
    height: 25px;
    width: 25px;
  }
  .group-btn-filter:nth-child(2) .checkmark {
    left: 5px;
  }
}

@media (max-width: 1330px) {
  .buying-table button {
    width: 40px;
    height: 40px;
    padding: 0;
  }
  .buying-table button {
    font-size: 20px;
  }
}

@media (min-width: 1600px) {
  .group-btn-filter {
    width: 150px !important;
  }
}
