#tab-Event {
  background: white !important;
  height: 100%;
}

#tab-Event .tab-content {
  height: 100%;
  /* padding-top: 7%; */
}

#tab-Event .hide-show-tabs {
  display: none;
}

#tab-Event .tab-content .page {
  background-color: white !important;
}

#tab-Event .tab-content > div {
  height: 100%;
  background-color: white !important;
  color: black !important;
}

.fade {
  opacity: 1 !important;
}

.order-details-custom-popup {
  min-width: 700px;
  height: 580px;
}

.custom-popup-tour {
  min-width: 800px;
  min-height: 400px;
}

#tab-Event .nav-tabs .active {
  background-color: white !important;
  border: none !important;
  border-bottom: 1px solid #77d609 !important;
  object-fit: contain;
  font-size: 25px;
  line-height: 1.19;
  text-align: left;
  margin-left: 0;
  margin-right: 0;
  border-bottom-right-radius: 0 !important;
}

@media (max-width: 1500px) {
  #tab-Event .nav-tabs .active {
    font-size: 22px !important;
  }
  #tab-Event .nav-tabs a {
    font-size: 22px !important;
  }
  #tab-Event .nav > a {
    padding: 10px 8px !important;
  }
}

@media (max-width: 1420px) {
  #tab-Event .nav-tabs {
    /* height: 45px !important; */
  }
  /* #tab-Event .nav>a {
    padding: 17px 0!important;
    ;
  } */
  #admin #tab-Event .active {
    margin-left: 0 !important;
  }
}

@media (max-width: 1355px) {
  #tab-Event .nav-tabs .active {
    font-size: 14px !important;
  }
  #tab-Event .nav-tabs a {
    font-size: 14px !important;
  }
  #tab-Event .nav-tabs {
    height: 37px;
  }
}

@media (max-width: 1215px) {
  #tab-Event .nav-tabs .active {
    font-size: 14px;
  }
  #tab-Event .nav-tabs a {
    font-size: 14px;
  }
  #tab-Event .nav-tabs {
    height: 35px;
  }
  #tab-Event .first-tab {
    margin-left: 0 !important;
  }
  #tab-Event .nav > a {
    margin: 0 15px !important;
  }
}

#tab-Event .nav-tabs a {
  color: #bfbebe;
  font-size: 25px;
}

#tab-Event .nav-tabs {
  border-bottom: unset;
  height: 50px;
  /* margin-top: 3%;
  margin-left: 3%; */
  position: relative;
}

#tab-Event .nav-tabs > li > a:hover,
#tab-Event .nav-tabs > li > a:focus {
  text-decoration: none;
  background: white;
  border-color: white;
}

/* #tab-Event .nav-tabs>li>a{
  margin: 0;
  padding-left: 3%;
  padding-right: 3%;
} */

#tab-Event .btn-default {
  width: 33%;
  opacity: 1 !important;
}

#tab-Event .btn-submit {
  object-fit: contain;
  font-size: 25;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #ffffff;
  height: 54px;
}

#tab-Event .btn-submit .confirme {
  height: 54px;
  background-color: #7bd812 !important;
}

#tab-Event .btn-submit .confirme .active {
  height: 54px;
  background-color: #77d609 !important;
}

#tab-events-container .active-tab {
  background-color: white !important;
  border: none !important;
  border-bottom: 2px solid #77d609 !important;
  object-fit: contain !important;
  font-size: 30px !important;
  line-height: 1.19 !important;
  text-align: left !important;
  color: #77d609 !important;
}

#tab-events-container nav > a {
  border-bottom: 1px solid #ddd;
  color: #bfbebe;
  font-size: 20px;
}

#tab-Event-producer .nav-tabs .active {
  background-color: white !important;
  border: none !important;
  border-bottom: 1px solid #77d609 !important;
  object-fit: contain;
  color: #77d609;
  font-weight: 400;
  font-size: 25px;
  line-height: 1.19;
  text-align: left;
  margin-left: 0;
  margin-right: 0;
  border-bottom-right-radius: 0 !important;
}

@media (max-width: 1500px) {
  #tab-Event-producer .nav-tabs .active {
    font-size: 22px !important;
  }
  #tab-Event-producer .nav-tabs a {
    font-size: 22px !important;
  }
  #tab-Event-producer .nav > a {
    padding: 10px 25px !important;
  }
}

@media (max-width: 1420px) {
  #admin #tab-Event-producer .active {
    margin-left: 0 !important;
  }
}

@media (max-width: 1355px) {
  #tab-Event-producer .nav-tabs .active {
    font-size: 14px !important;
  }
  #tab-Event-producer .nav-tabs a {
    font-size: 14px !important;
  }
  #tab-Event-producer .nav-tabs {
    height: 37px;
  }
}

@media (max-width: 1215px) {
  #tab-Event-producer .nav-tabs .active {
    font-size: 14px;
  }
  #tab-Event-producer .nav-tabs a {
    font-size: 14px;
  }
  #tab-Event-producer .nav-tabs {
    height: 35px;
  }
  #tab-Event-producer .first-tab {
    margin-left: 0 !important;
  }
  #tab-Event-producer .nav > a {
    margin: 0 15px !important;
  }
}

#tab-Event-producer .nav-tabs a {
  color: #bfbebe;
  font-size: 25px;
  font-weight: 400;
}

#tab-Event-producer .nav-tabs {
  border-bottom: unset;
  height: 50px;
  /* margin-top: 3%;*/
  margin-left: 23%;
  position: relative;
}

#tab-Event-producer .nav-tabs > li > a:hover,
#tab-Event-producer .nav-tabs > li > a:focus {
  text-decoration: none;
  background: white;
  border-color: white;
}

/* #tab-Event-producer .nav-tabs>li>a{
  margin: 0;
  padding-left: 3%;
  padding-right: 3%;
} */

#tab-Event-producer .btn-default {
  width: 33%;
  opacity: 1 !important;
}

#tab-Event-producer .btn-submit {
  object-fit: contain;
  font-size: 25;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #ffffff;
  height: 54px;
}

#tab-Event-producer .btn-submit .confirme {
  height: 54px;
  background-color: #7bd812 !important;
}

#tab-Event-producer .btn-submit .confirme .active {
  height: 54px;
  background-color: #77d609 !important;
}

#tab-events-container .active-tab {
  background-color: white !important;
  border: none !important;
  border-bottom: 2px solid #77d609 !important;
  object-fit: contain !important;
  font-size: 30px !important;
  line-height: 1.19 !important;
  text-align: left !important;
  color: #77d609 !important;
}

#tab-events-container nav > a {
  border-bottom: 1px solid #ddd;
  color: #bfbebe;
  font-size: 20px;
}

.nav > a {
  padding: 15px 0;
  margin: 0 15px;
  /* border-bottom: 1px solid #ddd; */
}

/* #tab-events-container nav>a {
  background-color: white !important;
  border: none !important;
  border-bottom: 2px solid #77d609 !important;
  object-fit: contain !important;
  font-size: 30px!important;
  line-height: 1.19!important;
  text-align: left!important;
  color: #77d609 !important;
} */

.order-group {
  display: flex;
  background: white;
  width: 90%;
  margin-top: 40px;
  position: relative;
  left: 81px;
}

.order-group-calendar {
  background: white;
  width: 100%;
}

.last-item {
  /* margin-left: auto;
  margin-right: 1px; */
}

.last-itemt {
  margin-left: auto;
  margin-right: 1px;
}

/* .Recipient {
  width: 91px;
  height: 21px;
  object-fit: contain;
  font-size: 23px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff9933;
  margin-top: 14px;
} */

.favName {
  color: rgba(98, 98, 98, 0.6);
  margin-left: 10px;
}

.trd {
  color: dimgray;
}

.rectangle {
  margin-left: 20px;
}

.icon-position {
  margin-left: 10px;
}

.icon-margin {
  margin-top: 2px;
}

.order-text {
  width: 50%;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: rgba(98, 98, 98, 0.6);
  position: relative;
  left: 11px;
  margin-top: 5px;
}

#tab-Event a {
  transition: all 0.1s ease !important;
}

#tab-Event .nav > a {
  margin: 0 0px;
  padding: 10px 20px;
}

#tab-Event-producer .nav > a {
  margin: 0 0px;
  padding: 10px 30px;
}

#tab-Event #tab-Event-pane-1 {
  background-color: white !important;
  color: black !important;
}

#tab-Event #tab-Event-pane-1 .stoks {
  background-color: white !important;
  color: black !important;
  /* height: 700px !important; */
  height: 100% !important;
  position: relative !important;
  border:1px solid red
}

.buy-sell.card-box .view-stock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#tab-Event #tab-Event-pane-2 {
  background-color: white !important;
  color: black !important;
}

#tab-Event #tab-Event-pane-3 {
  background-color: white !important;
  color: black !important;
  height: 90%;
}

.btn-white {
  object-fit: contain;
  border-radius: 10px;
  background-color: white !important;
}

.btn-buyer {
  color: #77d609 !important;
}

.btn-seller {
  color: #77d609 !important;
}

.btn-white > .btn {
  object-fit: contain;
  font-size: 19.5px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7bd812;
  border: none !important;
}

.btn-group-white-left {
  border-right-color: white !important;
}

.btn-group-white-right {
  border-left-color: white !important;
}

.btn-name {
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.info {
  background-color: transparent;
  box-sizing: border-box;
  object-fit: contain;
  opacity: 0.9;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  width: 100%;
}

.underline {
  background-color: #bdbdbd;
  display: inline-block;
  width: 100%;
  height: 2px;
  object-fit: contain;
  opacity: 0.75;
  position: relative;
  top: -18px;
}

#tab-Event .title {
  margin-bottom: 3px;
  height: 23px;
  object-fit: contain;
  opacity: 0.4;
  font-size: 23.5px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(112, 112, 112, 0.4);
}

.confirm {
  width: 85% !important;
}

.close-button {
  background-color: #cdcecc !important;
  margin-left: 5px;
}

.button-group {
  display: flex;
}

.order-calendar {
  display: flex;
  flex-direction: row;
  background: white;
  width: 100%;
  position: relative;
  left: 12px;
}

.order-calendar .info {
  width: 95%;
}

#tab-Event .order-title {
  object-fit: contain;
  opacity: 0.8;
  font-size: 23.5px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(112, 112, 112, 0.4);
  margin: 0;
}

.date-field {
  position: absolute;
  right: 0;
  top: 5px;
  width: 10%;
}

.calendar-image {
  width: 32px;
  height: 30px;
  margin-bottom: 9px !important;
  position: absolute;
  left: 8px;
}

.react-datepicker__input-container input {
  opacity: 0;
}

.react-datepicker-popper {
  transform: translate3d(-58px, 29px, -2px) !important;
}

@media (max-width: 1550px) {
  .react-datepicker-popper {
    transform: translate3d(-20rem, 29px, -2px) !important
  }
}

.close-button-center {
  background-color: #cdcecc !important;
  margin: auto;
}

#tab-events-container {
  height: 97.5%;
}

#tab-events-container .tab-event-top-item + #tab-Event {
  height: 87%;
}

#tab-events-container .style-border-t-info {
  width: 100%;
  margin: 2% 0 3% 0 !important;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.22),
    rgba(0, 0, 0, 0)
  );
  height: 1px;
  border: 0;
}

#tab-events-container .tourInfo-title.first.outer {
  margin-top: 0 !important;
}

#tab-events-container #tab-Event .nav-tabs .active a {
  /* background: linear-gradient(
      to left,
      #f9f6f7 0%,
      #b5ff61 20%,
      rgb(139, 224, 42) 65%,
      #f9f6f7 100%
    )
    left bottom #71ca0b no-repeat;
  background-size: 100% 2px;
  background-color: #ffffff !important;
  color: #7bd812 !important;
  border: 0px !important; */
  /* margin: 0; */
}
