
.tourInfo-title-b20 {
  width: 91%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.status-image-pointT {
  width: 70px !important;
}

/* prevent bar cursor from datepicker  */

.user-input-wrp.date-time .react-datepicker__input-container input{
  color: transparent !important;
  text-shadow: 0 0 0 #727272;
  border: 0;
}

#admin .view-drug .title {
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  color: #70d236;
  opacity: 1;
}

.content-center {
  width: 100%;
  text-align: center !important;
  box-sizing: border-box;
}

#admin .view-drug .borderBo {
  border-bottom: 2px solid rgba(123, 216, 18, 0.75) !important;
}

#admin .view-drug .space-top-bottom {
  margin-top: 0;
  margin-bottom: 15px;
}

