.draggable-item {
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 2px;
  margin-top: 2px;
}

.draggable-item-horizontal {
  height: 300px;
  padding: 10px;
  line-height: 100px;
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 4px;
}

.dragging {
  background-color: yellow;
}

.card-scene {
  padding: 0px;
}
.card-wrapper {
  padding: 0px;
}

.card-wrapperTwo {
  padding: 0px;
  min-height: 0px;
}
.card-container {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 7px;
  background-color: #f3f3f3;
}

.cardTwo {
  background-color: white;
  padding: 10px;
  margin-bottom: 5px;
  height: 54px;
}

.card-column-headerbal {
  display: flex !important;
  justify-content: space-between;
  /* padding: 20px 20px 0 !important;; */
  font-size: 20px !important;
  align-items: center !important;
  max-height: 40px !important;
  width: 100% !important;
}

.card-column-headerbaltwo {
  display: flex !important;
  /* justify-content: space-between; */
  /* padding: 20px 20px 0 !important;; */
  font-size: 20px !important;
  align-items: center !important;
  max-height: 40px !important;
  width: 100% !important;
}

.smooth-dnd-container {
  padding-top: 10px;
}

.column-drag-handle {
  cursor: move;
  padding: 5px;
}

.order-wrapper,
.card-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.order-icon,
.order-action,
.card-icon,
.card-details,
.card-action {
  flex: 1;
}
.order-details {
  flex: 2;
}

@media (max-width: 1500px) {
  .order-details {
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.card-column-header .edit-action {
  margin-left: 20px !important;
  margin-left: auto;
  font-size: 30px;
}

.card-column-header .delete-action {
  color: #9f9f9f;
  margin-left: auto;
  font-size: 30px;
}

.opacity-ghost {
  transition: all 0.18s ease;
  opacity: 0.8;
  cursor: ns-resize;
  background-color: #7bd812;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
  cursor: ns-resize;
}

.opacity-ghost-drop {
  opacity: 1;
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0);
}

.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px;
}

.card-column-header.loader-section .card-details {
  flex: none;
}

@media (max-width: 1500px) {
  .card-column-header.loader-section {
    padding: 20px 5px 0;
  }
  .card-column-header.loader-section .truck-capacity {
    font-size: 30px !important;
  }
  .card-column-header.loader-section .truck-full-capacity {
    font-size: 25px;
  }
}

.up {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border-color: #7bda19;
}

.modal-resell {
  height: 600px;

  width: 700px;
}

.modal-resellTwo {
  height: 750px;

  width: 700px;
}

.modal-resellopen {
  height: 740px;

  width: 700px;
}

.truckimdw {
  width: 75px;
  height: 65px;
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
  .truckimdw {
    width: 65px;
    height: 55px;
  }
}

.truckimdwrr {
  width: 35px;
  height: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
  .truckimdwrr {
    width: 35px;
    height: 35px;
    border: aqua 1px solid;
  }
}

.loader-prodstocks {
  display: flex;
  justify-content: center;
}
.loader-prodst {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #7bd811;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.sizeofdrag {
  height: 100% !important;
  overflow-y: scroll;
}
.sizeofdrag::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
}

/* /**/
.modalsizeTwop {
  width: auto;
}
.modalsizeTwop .deorder-ertagsp {
  height: 100% !important;
  height: 300px !important;
  min-width: 100px !important;
}
.modalsizeTwop .modal-content {
  width: 460px !important;
}

.modalsizeTwop .linestyle {
  height: 220px;
  overflow-y: scroll;
  margin-bottom: 20px;
  width: 90%;
}
.modalsizeTwop .flexbox {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
}
.modalsizeTwop .btn-filter {
  width: 40% !important;
  font-size: 17px !important;
  color: white !important;
  background-color: #7bd812 !important;
  border: #ddd;
  font-family: 'Titillium-Regular';
}

.modalsizeTwop .linestyle::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
}

.scrollBoxhemp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.scroll-boxitemText1 {
  font-size: 0.8vw;
}

.scroll-boxitemText2 {
  font-size: 0.8vw;
  margin-right: 5px;
}
.scroll-boxitemText3 {
  font-size: 0.8vw;
  margin-right: 2px;
  margin-left: 0px;
}

@media only screen and (min-width: 2000px) and (max-width: 5000px) {
  .scroll-boxitemText2 {
    font-size: 16px;
  }
  .scroll-boxitemText1 {
    font-size: 16px;
  }

  .scroll-boxitemText3 {
    font-size: 16px;
    margin-right: 30px;
  }

  .tour-item-twovaltourPart {
    width: auto !important;
  }
}

.scrollBoxhemp .scroll-boxitem {
  height: 30px;
  margin-left: 4px;
}

@media (max-width: 1500px) {
  .loader-section-image {
    margin-right: 0px !important;
  }
  .card-action .edit-action {
    margin: 0 !important;
  }
}

.scrollBoxhemp .scrollboxitems {
  width: 100% !important;
  display: inline-block;
}

.scroll-boxitem::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
}

.croll-boxitem:hover {
  overflow: visible;
  white-space: normal;
  height: auto; /* just added this line */
}

.menu-item-wrapper::active {
  background-color: firebrick;
}

.cattelgroupitem {
  min-height: 3% !important;
  max-height: 10% !important;

  overflow-y: scroll;
}

@media (max-width: 1500px) {
  .cattelgroupitem {
    height: auto !important;
  }
}

.cattelgroupitem::-webkit-scrollbar-thumb {
  background: #7bd812;
  border-radius: 10px;
}

.tour-item-twovaltour {
  font-family: 'Titillium-Regular';
  /* font-size: 1.2vh; */
  font-weight: 600;
  line-height: 1.7em;
  color: #868686;
}

.tour-item-twovaltourPart {
  /* width: 45%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.outer {
  width: 95%;
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .outer {
    width: 90%;
  }
}

.breedTextT {
  font-weight: bold;
  margin-left: 2px;
  text-align: center;
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .breedTextT {
    font-size: 12px;
  }
}

.breedTextNotBold {
  margin-left: 2px;
  text-align: center;
}

@media (min-width: 1024px) and (max-width: 1599px) {
  .breedTextNotBold {
    font-size: 12px;
  }
}
