.register-success-screen {
  padding: 4%;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  & .r-success-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
    & img {
      width: 60%;
    }
  }
  & .register-success-bottom {
    display: flex;
    justify-content: center;
    & a {
      background: #7bd811;
      border-radius: 5px;
      padding: 8px 38px;
      color: white;
      margin-bottom: 8%;
      font-weight: 400;
      font-size: 16px;
    }
  }
  & .first-message {
    font-size: 20px;
    color: #7c7c7c;
    text-align: center;
    font-weight: 500;
    padding-bottom: 3%;
  }
  & .first-message-sub {
    font-size: 16px;
    color: #7c7c7c;
    text-align: center;
    font-weight: 300;
    padding-bottom: 3%;
  }
  & .second-message {
    font-size: 17px;
    color: #7bd811;
    text-align: center;
    font-weight: 400;
  }
}
