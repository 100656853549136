@media screen and (max-width: 768px){
    .cookie__list-selections{
        height: 83px !important;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-top: 5%;
        width: 100%;
      }
      .cookie__btn-tab{
        width: 12%;
        margin-left:10%;
        margin-right:10%;
        display: flex;
        font-weight: 600;
        font-size: 14px;
        border-style: none none solid;
        justify-content: center;
        color: #767676;
        opacity: 0.5;
      }
      a.cookie__btn-tab:hover{
          width: 12%;
          margin-left:5%;
          margin-right:5%;
          display: flex;
          font-weight: 600;
          font-size: 14px;
          border-style: none none solid;
          justify-content: center;
          color: #767676;
          opacity: 0.5;
        }
      .cookie__activeTab{
        margin-left:10%;
        margin-right:10%;
        border-style: none none solid;
        border-bottom: 3px solid #77d609 !important;
        font-weight: bold;
        font-size: 16px;
        opacity: 1 !important;
        color: #77d609;
      }
      a.cookie__activeTab:hover{
          margin-left:5%;
          margin-right:5%;
          border-style: none none solid;
          border-bottom: 3px solid #77d609 !important;
          font-weight: bold;
          font-size: 16px;
          opacity: 1 !important;
          color: #77d609;
        }
  }
  @media screen and (min-width: 769px){
    .cookie__list-selections{
      height: 83px !important;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-top: 5%;
      width: 100%;
    }
    .cookie__btn-tab{
      width: 12%;
      margin-left:5%;
      margin-right:5%;
      display: flex;
      font-weight: 600;
      font-size: 18px;
      border-style: none none solid;
      justify-content: center;
      color: #767676;
      opacity: 0.5;
    }
    a.cookie__btn-tab:hover{
        width: 12%;
        margin-left:5%;
        margin-right:5%;
        display: flex;
        font-weight: 600;
        font-size: 18px;
        border-style: none none solid;
        justify-content: center;
        color: #767676;
        opacity: 0.5;
      }
    .cookie__activeTab{
      margin-left:5%;
      margin-right:5%;
      border-style: none none solid;
      border-bottom: 3px solid #77d609 !important;
      font-weight: bold;
      font-size: 26px;
      opacity: 1 !important;
      color: #77d609;
    }
    a.cookie__activeTab:hover{
        margin-left:5%;
        margin-right:5%;
        border-style: none none solid;
        border-bottom: 3px solid #77d609 !important;
        font-weight: bold;
        font-size: 26px;
        opacity: 1 !important;
        color: #77d609;
      }
  }
  .cookie-content{
      margin-top: 15%;
      margin-bottom: 5%;
      margin-left: 20% !important;
      z-index: 12;
  }

  @media (min-width: 768px) and (max-width: 1280px){
    .cookie-content{
        margin-top: 25% !important;
    }  
  }
  @media (min-width: 544px) and (max-width: 768px){
    .cookie-content{
        margin-top: 35% !important;
    }  
  }
  @media  (max-width: 544px){
    .cookie-content{
        margin-top: 50% !important;
    }  
  }
  .cookie-title{
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #767676;
  }
  .cookie-details{
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #767676;
  }

