@import '../../../css/global.scss';

.iconiz {
  padding: 5px;
  &-green {
    color: $green;
  }
  &-rounded {
    border-radius: 50%;

    &.solid {
      background-color: $green;
    }
    &.regular {
      background-color: white;
    }
    &.border {
      border: 1px solid;
      border-color: $green;
    }
  }
}
