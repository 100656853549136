#admin-home .contain {
  height: 750px;
}

@media (min-width: 1350px) and (max-width: 1470px) {
  #admin-home .contain>.container {
    padding-right: 125px;
  }
}

#admin-home .left-column {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0 !important;
}

#admin-home .top {
  margin-top: 100px;
}

#admin-home .btn-success {
  border: none !important;
  box-shadow: 0px 21px 24px 0 rgba(0, 0, 0, 0.08);
  background-color: #7bd812 !important;
}

#admin-home img {
  border: 0 !important;
}

#admin-home .glyphicon {
  color: #7bcd10;
}

#admin-home .share {
  color: #757575;
  font-size: 40px;
}

#admin-home .trial {
  color: #757575;
  font-size: 20px;
  cursor: pointer;
}

#admin-home .welcom {
  color: #757575;
  font-size: 75px;
}

#admin-home .text {
  color: #757575;
  font-size: 30px;
}

#admin-home .agrabiz {
  color: #7bcd10;
  font-size: 70px;
  font-weight: bold;
}

#admin-home .feedback {
  color: #7bcd10;
  font-size: 55px;
  font-weight: bold;
}

#admin-home .link {
  border: 0px !important;
  width: 240px;
  margin-bottom: 15%;
}

/* #admin-home .link:hover {
  border: 3px !important;
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  transform: scale(1.02);
  box-shadow: 1px 1px 1px 1px #7bd812 !important;
} */

#admin-home .link {
  position: relative;
  border-radius: 5px;
  box-shadow: 0;
  /* transition: all 0.3s ease-in-out; */
}

#admin-home .link::after {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

#admin-home .home-thumbnails:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.1, 1.1);
  box-shadow: -1px 2px 8px 0 #d6d6d63d, 2px 6px 20px 0 rgba(119, 98, 98, 0.19);
  border-radius: 7px;
}

#admin-home .home-thumbnails .link {
  margin: 0;
  width: 160px;
  height: 150px;
}

#admin-home .home-thumbnails {
  margin-bottom: 13%;
  padding: 10px 45px;
}

#admin-home .home-thumbnails p.text-center {
  padding: 10px 0;
}

@media only screen and (max-width: 1365px) {
  .container {
    width: 100% !important;
  }
}