@import '../../../css/global.scss';

button {
  font-family: 'Titillium-Regular';
}

.btn-normal {
  width: 55%;
  height: 38px;
  border-radius: 5px;
  font-size: 18px;
  color: #fff;
  line-height: 1.4;
  border: 1px solid transparent;
  padding: 6px 12px;

  &-green {
    background-color: $green !important;
    border-color: $green;
  }

  &-grey {
    background: #c1c1c1 !important;
    border: 1px solid #c1c1c1 !important;

  }
  &-block {
    width: 100%;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
    box-shadow: none;
  }
}
