.rowQuality {
  margin-left: -15px;
  margin-right: -225px;
  padding-right: 10%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.activeQuality {
  background-color: #7bd812;
  margin-bottom: 2%;
}

.itemQP {
  margin-bottom: 10px;
  background-color: #7bd812;
  margin-left: -15px;
  margin-right: 60px;
}

.okIcon::after {
  padding: 5%;
  content: url('../../../static/images/admin/calque-9.png');
}

.qpHeight {
  height: 100px !important;
  cursor: pointer;
}

.disabled-qp .box {
  background: #c1c1c1;
}

.disabled-qp .box:hover {
  background: #c1c1c1;
}

.qp-title {
  font-size: 20px !important;
  margin-bottom: 15px !important ;
  opacity: 0.5 !important;
}
.qp-text-color {
  color: #ffffff !important;
}

#quality-program-title {
  color: #c6c6c6;
  font-size: 14px;
  padding-left: 1%;
}

#quality-program-title .box .active {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#quality-program-title .box.active img {
  width: 25px !important;
}

#quality-program-title .box label {
  margin-top: 0 !important;
}

#quality-program-title .box {
  padding: 20px 0 20px !important;
}

#quality-program-title .quality-program-title-inner {
  padding-bottom: 2%;
}
